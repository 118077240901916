import * as React from "react";
import { Button, Modal, notification, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Spinner } from "../../Components/Spinner";
import { JSONModal } from "../../jsonModal";
import { formatTimestamp, request } from "../../utils";

type RouteParams = "org";
const OrgInfoRaw: React.FC = () => {
  const navigate = useNavigate();
  const { org: orgName } = useParams<RouteParams>();
  const [org, setOrg] = React.useState(null as any);
  const [linkedAccount, setLinkedAccount] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);

  React.useEffect(() => {
    fetchOrg();
    fetchLinkedAccount();
  }, []);

  async function fetchOrg() {
    try {
      const res = await request({ url: `/admin/org/${orgName}` });
      setOrg(res);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch org",
        description: errorMessage,
      });
      navigate("/admin/org");
    }
  }

  async function fetchLinkedAccount() {
    try {
      const res = await request({
        service: "billing-ng",
        url: `/org/${orgName}/account`,
      });
      setLinkedAccount(res);
    } catch (e) {
      if (e?.response?.status === 401) {
        return;
      }
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch linked account of org",
        description: errorMessage,
      });
    }
  }

  async function tryActivate() {
    Modal.confirm({
      title: `Activate "${org.name}" org?`,
      content: "This will activate the org.",
      onOk: async () => {
        try {
          setIsLoading(true);
          await request({
            url: `/admin/org/${org.name}`,
            method: "patch",
            body: {
              status: {
                active: true,
              },
            },
          });
          notification.success({
            message: "Success",
            description: `Activated the org: ${org.name}`,
          });
          await fetchOrg();
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to activate org",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
    });
  }

  async function tryDeactivate() {
    Modal.confirm({
      title: `Deactivate "${org.name}" org?`,
      content: "This will deactivate the org and make it read-only.",
      onOk: async () => {
        try {
          setIsLoading(true);
          await request({
            url: `/admin/org/${org.name}`,
            method: "patch",
            body: {
              status: {
                active: false,
              },
            },
          });
          notification.success({
            message: "Success",
            description: `Deactivated the org: ${org.name}`,
          });
          await fetchOrg();
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to deactivate org",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
    });
  }

  async function exportItem() {
    const file = new Blob([JSON.stringify(org, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${org.name}.json`);
    aElement.click();
  }

  if (org === null) {
    return <Spinner />;
  }

  return (
    <>
      <div className="px-8">
        <div className="flex items-center gap-2">
          <PageHeader title={orgName} subTitle={"Org Detail - Info"} />
          <Button
            disabled={isLoading}
            loading={isLoading}
            type={"primary"}
            danger={org.status.active === true}
            size={"small"}
            onClick={() => {
              if (org.status.active) {
                tryDeactivate();
              } else {
                tryActivate();
              }
            }}
          >
            {org.status.active ? "Deactivate" : "Activate"}
          </Button>
          <Button onClick={() => setIsViewing(true)}>View</Button>
          <Button onClick={() => exportItem()}>Export</Button>
        </div>
        <div className="px-6">
          <div className="text-base text-gray-700 font-semibold">Name</div>
          <div className="mb-4 mt-1">{org.name}</div>
          <div className="text-base text-gray-700 font-semibold">
            Description
          </div>
          <div className="mb-4 mt-1">{org.description}</div>
          <div className="text-base text-gray-700 font-semibold">
            Linked Account
          </div>
          {linkedAccount ? (
            <Link
              to={`/admin/account/${linkedAccount.id}`}
              className="inline-block text-blue-600 mb-4 mt-1"
            >
              {linkedAccount.accountName || linkedAccount.fullName}
            </Link>
          ) : (
            <div className="mb-4 mt-1">{"No Linked Account"}</div>
          )}
          <div className="text-base text-gray-700 font-semibold">Tags</div>
          <div className="flex gap-1 mb-4 mt-1">
            {org.tags ? (
              Object.entries(org.tags).length < 1 ? (
                <Tag>None</Tag>
              ) : null
            ) : null}
            {org.tags
              ? Object.entries(org.tags).map(([key, value]: [any, any]) => (
                  <Tag key={key + value} className={"truncate max-w-xs"}>
                    {key}={value}
                  </Tag>
                ))
              : null}
          </div>
          <div className="text-base text-gray-700 font-semibold">Created</div>
          <div className="mb-4 mt-1">{formatTimestamp(org.created)}</div>
          <div className="text-base text-gray-700 font-semibold">
            Last Modified
          </div>
          <div className="mb-4 mt-1">{formatTimestamp(org.lastModified)}</div>
        </div>
      </div>
      {isViewing ? (
        <JSONModal
          filename={org.name}
          onClose={() => setIsViewing(false)}
          title={`Org: ${org.name}`}
          visible={!!isViewing}
          object={org}
        />
      ) : null}
    </>
  );
};

export const OrgInfo = observer(OrgInfoRaw);
