export const IS_LOCAL_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === "local";
export const IS_TEST_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === "staging";
export const IS_PROD_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === "production";

export const CORE_API = process.env.REACT_APP_ADMIN_API;
export const DATA_API = process.env.REACT_APP_API;
export const EXTERNAL_SIGNUP_API = process.env.REACT_APP_EXTERNAL_SIGNUP_API;
export const VIDEO_API = process.env.REACT_APP_VIDEO_API; // for test, tesla-test under hakan account, workload named video-service
export const PRICING_SUBMISSIONS_API = process.env.REACT_APP_PRICING_SUBMISSIONS_API;
export const VIEW_SUBMISSION_ENDPOINT = process.env.REACT_APP_VIEW_SUBMISSION_ENDPOINT;

export const CONSOLE = process.env.REACT_APP_CONSOLE;
export const CONSOLE_LOCAL = process.env.REACT_APP_CONSOLE_LOCAL;
export const CONSOLE_STAGING = process.env.REACT_APP_CONSOLE_STAGING;

export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

export const MARKETPLACE_SERVICE = process.env.REACT_APP_MARKETPLACE_SERVICE;
export const MARKETPLACE_BRANCH = process.env.REACT_APP_MARKETPLACE_BRANCH;
