import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { AccountList } from "./AccountList";
import { AccountDetail } from "./AccountDetail";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const AccountIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Account - Admin Panel
        </title>
      </Helmet>
      <Routes>
        <Route path={`:uuid/*`} element={<AccountDetail />} />
        <Route index element={<AccountList />} />
      </Routes>
    </>
  );
};

export const AccountIndex = observer(AccountIndexRaw);
