import { Spin } from "antd";
import * as React from "react";

export const Spinner: React.FC = () => {
  return (
    <div className="w-full h-full flex items-center justify-center mt-16">
      <Spin spinning={true} />
    </div>
  );
};
