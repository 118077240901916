import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Routes,
  Link,
  Route,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import { Menu, Spin, notification } from "antd";
import { TrialDomainInfo } from "./DetailPages/Info";
import { ITrialDomain } from "../utilities/types";
import { FetchTrialDomainByName } from "../utilities/helper";
import { TrialDomainAccounts } from "./DetailPages/Accounts";
import { TrialDomainOrgs } from "./DetailPages/Orgs";
import { TrialDomainQuota } from "./DetailPages/Quota";

type RouteParams = "trialDomainName";

const TrialDomainsDetailRaw: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { trialDomainName: _trialDomainName } = useParams<RouteParams>();
  const trialDomainName = _trialDomainName!;

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    switch (true) {
      case pathname.includes("/info"):
        _menuActiveKey = "info";
        break;
      case pathname.includes("/quota"):
        _menuActiveKey = "quota";
        break;
      case pathname.includes("/accounts"):
        _menuActiveKey = "accounts";
        break;
      case pathname.includes("/orgs"):
        _menuActiveKey = "orgs";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  const [trialDomain, setTrialDomain] = React.useState<ITrialDomain | null>(
    null
  );

  React.useEffect(() => {
    fetchTrialDomain();
  }, []);

  if (!trialDomain) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  function updateTiralDomainRemainingAccounts(value: number): void {
    if (trialDomain == null) {
      return;
    }
    trialDomain.accountsRemaining += value;
  }

  async function fetchTrialDomain() {
    setTrialDomain(null);
    let errorMessage: string | null = null;
    const response = await FetchTrialDomainByName(trialDomainName);

    if (typeof response === "string") {
      errorMessage = response;
    }

    if (response === null) {
      errorMessage = "Trial domain not found";
    }

    if (errorMessage != null) {
      notification.warning({
        message: "Failed to fetch trial domain",
        description: errorMessage,
      });

      navigate("/admin/trial-domains");
    } else {
      setTrialDomain(response as ITrialDomain);
    }
  }

  return (
    <div className="flex p-8">
      <Menu theme={"light"} selectedKeys={[menuActiveKey]}>
        <Menu.Item key={"info"}>
          <NavLink to={`info`}>Info</NavLink>
        </Menu.Item>
        <Menu.Item key={"quota"}>
          <NavLink to={`quota`}>Quota</NavLink>
        </Menu.Item>
        <Menu.Item key={"accounts"}>
          <NavLink to={`accounts`}>Accounts</NavLink>
        </Menu.Item>
        <Menu.Item key={"orgs"}>
          <NavLink to={`orgs`}>Orgs</NavLink>
        </Menu.Item>
      </Menu>
      <Routes>
        <Route
          path={`info/*`}
          element={<TrialDomainInfo trialDomain={trialDomain} />}
        />
        <Route
          path={`quota/*`}
          element={
            <TrialDomainQuota
              trialDomain={trialDomain}
              updateTiralDomainRemainingAccounts={
                updateTiralDomainRemainingAccounts
              }
            />
          }
        />
        <Route
          path={`accounts/*`}
          element={<TrialDomainAccounts accounts={trialDomain.accounts} />}
        />
        <Route
          path={`orgs/*`}
          element={<TrialDomainOrgs orgs={trialDomain.orgs} />}
        />
        <Route index element={<Navigate to={`info`} replace />} />
      </Routes>
    </div>
  );
};

export const TrialDomainsDetail = observer(TrialDomainsDetailRaw);
