import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { PartnerList } from "./PartnerList";
import { PartnerDetail } from "./PartnerDetail";
import { UnattachedPartnerDetail } from "./UnattachedPartnerDetail";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const ExternalSignupIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}External Signup - Admin Panel</title>
      </Helmet>
      <Routes>
        <Route path={`none`} element={<UnattachedPartnerDetail />} />
        <Route path={`:partner/*`} element={<PartnerDetail />} />
        <Route index element={<PartnerList />} />
      </Routes>
    </>
  );
};

export const ExternalSignupIndex = observer(ExternalSignupIndexRaw);
