import { Partner } from "./types";

export const UnattachedPartner: Partner = {
  cookieReferrals: [],
  expiredCookieReferrals: [],
  disabled: false,
  email: "",
  hostnames: [],
  id: "none",
  name: "Unattached Cookie Referrals",
  referrals: [],
  secretKey: "",
};
