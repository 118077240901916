import * as React from "react";
import { Menu, notification, Spin } from "antd";
import { Routes, Route, Link, useLocation, Navigate, useNavigate, NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { UserInfo } from "./UserInfo";
import { UserOrg } from "./UserOrg";
import { request } from "../../utils";
import { UserAccount } from "./UserAccount";
import type { IUserAccount } from "./types";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

type RouteParams = "uid";
const UserDetailRaw: React.FC = () => {
  const { uid } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = React.useState<any>(null);
  const [orgs, setOrgs] = React.useState<any[]>([]);
  const [accounts, setAccounts] = React.useState<IUserAccount[]>([]);

  React.useEffect(() => {
    fetchUser();
    fetchOrgs();
  }, [uid]);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    fetchAccounts();
  }, [user]);

  async function fetchUser() {
    try {
      const userRes = await request({ url: `/admin/firebase/user/${uid}` });
      setUser(userRes);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch user",
        description: errorMessage,
      });
      navigate("/admin/org");
    }
  }

  async function fetchOrgs() {
    try {
      let nextLink = `/admin/firebase/user/${uid}/org`;
      let _orgs: any[] = [];
      while (nextLink) {
        const orgsRes = await request({ url: nextLink });
        _orgs = _orgs.concat(orgsRes.items);
        nextLink = orgsRes.links.find((link: any) => link.rel === "next")?.href;
      }
      setOrgs(_orgs);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch user's orgs",
        description: errorMessage,
      });
    }
  }

  async function fetchAccounts() {
    try {
      const accountsRes = await request({
        service: "billing-ng",
        url: `/account/user`,
        method: "post",
        body: { email: user.email },
      });
      setAccounts(accountsRes.items);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch user's accounts",
        description: errorMessage,
      });
    }
  }

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    switch (true) {
      case pathname.includes("/info"):
        _menuActiveKey = "info";
        break;
      case pathname.includes("/account"):
        _menuActiveKey = "account";
        break;
      case pathname.includes("/org"):
        _menuActiveKey = "org";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  if (!user) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}
          {user?.email} - User - Admin Panel
        </title>
      </Helmet>
      <div className="flex p-8">
        <Menu
          theme={"light"}
          selectedKeys={[menuActiveKey]}
          onClick={({ key }) => navigate(key)}
          items={[
            { key: "info", label: "Info" },
            { key: "account", label: "Accounts" },
            { key: "org", label: "Orgs" },
          ]}
        />
        <Routes>
          <Route path={`info/*`} element={<UserInfo user={user} fetchUser={fetchUser} />} />
          <Route path={`account/*`} element={<UserAccount user={user} accounts={accounts} />} />
          <Route path={`org/*`} element={<UserOrg user={user} orgs={orgs} />} />
          <Route index element={<Navigate to={`info`} replace />} />
        </Routes>
      </div>
    </>
  );
};

export const UserDetail = observer(UserDetailRaw);
