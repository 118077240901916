import * as React from "react";
import {
  Upload,
  Radio,
  Input,
  Modal,
  Button,
  notification,
  Divider,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { request } from "../../utils";
import { Thumbnail, Video } from "./types";
import { Link } from "react-router-dom";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadOutlined } from "@ant-design/icons";
import { ThumbnailList } from "./ThumbnailList";

const initialVideoState: Video = {
  description: "",
  id: "",
  order: "10" as any,
  thumbnailId: "",
  url: "",
  title: "",
};

interface Action {
  type:
    | "from"
    | "reset"
    | "url"
    | "description"
    | "thumbnailId"
    | "order"
    | "title";
  payload: any;
}

function videoReducer(state: Video, action: Action): Video {
  if (action.type === "reset") {
    return initialVideoState;
  }
  if (action.type === "from") {
    return { ...initialVideoState, ...action.payload };
  }
  return { ...state, [action.type]: action.payload };
}

const VideoListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [videos, setVideos] = React.useState<Video[]>([]);
  const [error, setError] = React.useState<string>("");
  const [isAddingVideo, setIsAddingVideo] = React.useState<boolean>(false);
  const [isEditingVideo, setIsEditingVideo] = React.useState<boolean>(false);
  const [state, dispatch] = React.useReducer(videoReducer, initialVideoState);
  const [isVideoValid, setIsVideoValid] = React.useState(false);

  // thumbnail related data
  const [thumbnailName, setThumbnailName] = React.useState<string>("");
  const [thumbnails, setThumbnails] = React.useState<Thumbnail[]>([]);
  const [isSelectingThumbnail, setIsSelectingThumbnail] = React.useState(false);
  const [selectedThumbnailId, setSelectedThumbnailId] = React.useState("");
  const [currentTab, setCurrentTab] = React.useState<"addEdit" | "thumbnails">(
    "addEdit"
  );
  const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [isUploadingThumbnail, setIsUploadingThumbnail] = React.useState(false);

  React.useEffect(() => {
    fetchVideos();
    fetchThumbnails();
  }, []);

  React.useEffect(() => {
    let res = true;
    if (!state.title) {
      res = false;
    }
    if (Number.isNaN(state.order)) {
      res = false;
    }
    if (!state.thumbnailId) {
      res = false;
    }
    if (!state.url) {
      res = false;
    }
    setIsVideoValid(res);
  }, [state]);

  async function fetchVideos() {
    try {
      setError("");
      setIsLoading(true);
      const res = await request({
        service: "video",
        url: `/video`,
      });
      setVideos(
        res?.items.sort((a: Video, b: Video) => {
          const _a = a.order;
          const _b = b.order;
          if (_a < _b) {
            return -1;
          }
          if (_a > _b) {
            return 1;
          }
          return 0;
        }) || []
      );
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      notification.warning({
        message: "Failed to fetch videos",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function fetchThumbnails() {
    try {
      setError("");
      setIsLoading(true);
      const res = await request({
        service: "video",
        url: `/thumbnail`,
      });
      setThumbnails(res?.items || []);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      notification.warning({
        message: "Failed to fetch thumbnails",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function tryAddVideo() {
    setIsAddingVideo(true);
    setCurrentTab("addEdit");
  }

  async function onConfirmAddVideo() {
    try {
      setIsLoading(true);
      const n = { ...state };
      // @ts-ignore
      delete n.id;
      n.order = Number(n.order);
      await request({
        service: "video",
        url: `/video`,
        method: "post",
        body: n,
      });
      notification.success({
        message: "Success",
        description: "Added video",
      });
      fetchVideos();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to add video",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
      setIsAddingVideo(false);
      dispatch({ type: "reset", payload: undefined });
    }
  }

  async function onConfirmEditVideo() {
    try {
      setIsLoading(true);
      const n = { ...state };
      n.order = Number(n.order);
      await request({
        service: "video",
        url: `/video/${n.id}`,
        method: "patch",
        body: n,
      });
      notification.success({
        message: "Success",
        description: "Edited video",
      });
      fetchVideos();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to edit video",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
      setIsEditingVideo(false);
      dispatch({ type: "reset", payload: undefined });
    }
  }

  async function onDeleteVideo(id: string) {
    try {
      setIsLoading(true);
      await request({
        service: "video",
        url: `/video/${id}`,
        method: "delete",
      });
      notification.success({
        message: "Success",
        description: "Deleted the video",
      });
      fetchVideos();
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete video",
        description: errorMessage,
      });
    } finally {
      setIsLoading(false);
      setIsAddingVideo(false);
      dispatch({ type: "reset", payload: undefined });
    }
  }

  async function onConfirmUploadThumbnails() {
    try {
      setIsUploadingThumbnail(true);
      let dataList: string[] = [];
      const file = fileList[0];
      if (!file) {
        setIsUploadingThumbnail(false);
        return;
      }

      const reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          dataList.push(reader.result as string);
        },
        false
      );
      reader.readAsDataURL(file.originFileObj as any);

      const name = thumbnailName;

      setTimeout(async () => {
        try {
          await request({
            service: "video",
            method: "post",
            url: "/thumbnail",
            body: { data: dataList[0], name },
          });
        } catch (e) {}

        setThumbnailName("");
        fetchThumbnails();
        setFileList([]);
        setIsUploadingThumbnail(false);
      }, 1000);
    } catch (e) {
      setIsUploadingThumbnail(false);
    }
  }

  return (
    <>
      {isAddingVideo || isEditingVideo ? (
        <Modal
          width={currentTab === "addEdit" ? undefined : 1200}
          open={isAddingVideo || isEditingVideo}
          onCancel={() => {
            setIsAddingVideo(false);
            setIsEditingVideo(false);
            dispatch({ type: "reset", payload: undefined });
          }}
          onOk={isAddingVideo ? onConfirmAddVideo : onConfirmEditVideo}
          okButtonProps={{
            loading: isLoading,
            disabled: isLoading || !isVideoValid,
          }}
          cancelButtonProps={{ disabled: isLoading }}
        >
          <Radio.Group
            className="mb-2"
            options={[
              {
                label: isAddingVideo ? "Add Video" : "Edit Video",
                value: "addEdit",
              },
              { label: "Thumbnails", value: "thumbnails" },
            ]}
            onChange={(e) => setCurrentTab(e.target.value)}
            value={currentTab}
            optionType={"button"}
          />
          {currentTab === "addEdit" ? (
            <div className="flex flex-col gap-4">
              <label>
                <span>Title</span>
                <Input
                  required
                  value={state.title}
                  onChange={(e) =>
                    dispatch({ type: "title", payload: e.target.value })
                  }
                />
              </label>
              <label>
                <span>Description</span>
                <Input
                  required
                  value={state.description}
                  onChange={(e) =>
                    dispatch({ type: "description", payload: e.target.value })
                  }
                />
              </label>
              <label>
                <span>URL</span>
                <Input
                  required
                  value={state.url}
                  onChange={(e) =>
                    dispatch({ type: "url", payload: e.target.value })
                  }
                />
              </label>
              <label>
                <span>Thumbnail</span>
                <Button
                  onClick={() => setIsSelectingThumbnail(true)}
                  className="ml-2"
                >
                  Select Thumbnail
                </Button>
              </label>
              <img
                style={{ width: 344, height: 193 }}
                src={thumbnails.find((t) => t.id === state.thumbnailId)?.data}
              />
              <label>
                <span>Order</span>
                <Input
                  required
                  value={state.order}
                  onChange={(e) =>
                    dispatch({ type: "order", payload: e.target.value })
                  }
                />
              </label>
            </div>
          ) : (
            <div className="flex flex-col">
              <Divider />
              <div>
                <div className="bg-red-100 text-red-700 px-2 py-1 text-sm mb-2">
                  Please make sure the uploaded images are of resolution 344px *
                  193px
                </div>
                <Upload
                  accept={"image/*"}
                  fileList={fileList}
                  multiple={false}
                  maxCount={1}
                  onChange={(info) => {
                    setFileList(info.fileList);
                    const file = info.fileList[0];
                    if (file) {
                      setThumbnailName(
                        file.name.split(".").slice(0, -1).join(".")
                      );
                    }
                  }}
                  beforeUpload={(_) => {
                    return false;
                  }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={isUploadingThumbnail}
                  >
                    Select Image to Upload
                  </Button>
                </Upload>
                {fileList.length > 0 ? (
                  <>
                    <Input
                      placeholder={"Type thumbnail name"}
                      value={thumbnailName}
                      onChange={(e) => setThumbnailName(e.target.value)}
                      required
                    />
                    <Button
                      onClick={() => {
                        onConfirmUploadThumbnails();
                      }}
                      disabled={
                        !thumbnailName ||
                        isUploadingThumbnail ||
                        fileList.length < 1
                      }
                      loading={isUploadingThumbnail}
                    >
                      Upload Selected Images
                    </Button>
                  </>
                ) : null}
              </div>
              <Divider />
              <ThumbnailList thumbnails={thumbnails} />
            </div>
          )}
        </Modal>
      ) : null}
      {isSelectingThumbnail ? (
        <Modal
          width={1200}
          open={isSelectingThumbnail}
          onCancel={() => {
            setIsSelectingThumbnail(false);
          }}
          onOk={() => {
            dispatch({ type: "thumbnailId", payload: selectedThumbnailId });
            setSelectedThumbnailId("");
            setIsSelectingThumbnail(false);
          }}
        >
          <ThumbnailList
            thumbnails={thumbnails}
            onSelect={(id) => setSelectedThumbnailId(id)}
          />
        </Modal>
      ) : null}
      <div className="flex items-center gap-4">
        <PageHeader title={"Videos"} backIcon={false} />
        <div className="flex items-center gap-2">
          <Button onClick={tryAddVideo} type={"primary"}>
            Add
          </Button>
          <Button
            onClick={() => {
              fetchVideos();
              fetchThumbnails();
            }}
          >
            Refresh
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className="p-4">Fetching Videos</div>
      ) : videos.length < 1 ? (
        <div className="p-4">
          <div>No Videos Found</div>
          <div>Message: {error}</div>
          <Button type={"primary"} onClick={fetchVideos}>
            Retry
          </Button>
        </div>
      ) : (
        <div className="p-4">
          {/* Labels */}
          <div className="px-2 py-1 font-semibold flex items-center gap-2 bg-gray-500 text-white border border-b-0">
            <div className="w-1/12">Order</div>
            <div className="w-1/12">Thumbnail</div>
            <div className="w-4/12">Title & Desc</div>
            <div className="w-4/12">URL</div>
            <div className="w-2/12">Actions</div>
          </div>
          {/* Items */}
          {videos.map((v) => (
            <div
              key={v.id}
              className="px-2 py-2 flex items-center gap-2 border"
            >
              <div className="w-1/12">{v.order}</div>
              <div className="w-1/12">
                <img
                  style={{ width: 344 / 3, height: 193 / 3 }}
                  src={thumbnails.find((t) => t.id === v.thumbnailId)?.data}
                />
              </div>
              <div className="w-4/12">
                <Link
                  className="text-blue-500 hover:text-blue-600 font-semibold"
                  to={`${v.id}`}
                >
                  {v.title}
                </Link>
                <div>{v.description}</div>
              </div>
              <div className="w-4/12">
                <a
                  href={v.url}
                  target={"_blank"}
                  className="text-blue-500 hover:text-blue-600 font-semibold"
                >
                  {v.url}
                </a>
              </div>
              <div className="w-2/12 flex items-center gap-4">
                <Button
                  type={"primary"}
                  onClick={() => {
                    setIsEditingVideo(true);
                    setCurrentTab("addEdit");
                    dispatch({ type: "from", payload: v });
                  }}
                >
                  Edit
                </Button>
                <Button danger onClick={() => onDeleteVideo(v.id)}>
                  Delete
                </Button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export const VideoList = observer(VideoListRaw);
