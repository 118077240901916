import { notification, Popconfirm, Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { request } from "../../utils";

type RouteParams = "org";
const OrgLocationRaw: React.FC = () => {
  const { org } = useParams<RouteParams>();
  const [availableLocations, setAvailableLocations] = React.useState([]); // admin/locations/all
  const [locations, setLocations] = React.useState<string[]>([]); // /admin/org/:orgName/location {names: []}
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSettingEnabled, setIsSettingEnabled] = React.useState("");
  const [isDeleting, setIsDeleting] = React.useState("");
  const [selections, setSelections] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchLocations();
  }, []);

  async function fetchLocations() {
    try {
      setIsLoading(true);
      let nextLink = `/admin/org/${org}/location`;
      let _locations: any[] = [];
      while (nextLink) {
        const orgLocationsRes = await request({
          url: nextLink,
        });
        _locations = _locations.concat(orgLocationsRes.items);
        nextLink = orgLocationsRes.links.find((link: any) => link.rel === "next")?.href;
      }
      setLocations(_locations);
      const allLocationsRes = await request({ url: `/admin/locations/all` });
      setAvailableLocations(allLocationsRes.items);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch locations",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function onEnable(name: string) {
    // /admin/org/:orgName/location {names: []}
    try {
      setIsLoading(true);
      setIsSettingEnabled(name);
      await request({
        url: `/admin/org/${org}/location`,
        body: { names: [name] },
        method: "post",
      });
      await fetchLocations();
      setSelections((s) => s.filter((_s) => _s !== name));
      setIsLoading(false);
      setIsSettingEnabled("");
      notification.success({
        message: `Enabled location: ${name}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to enable location",
        description: errorMessage,
      });
      setIsLoading(false);
      setIsSettingEnabled("");
    }
  }

  async function onDisable(name: string) {
    // /admin/org/:orgName/location {names: []}
    try {
      setIsLoading(true);
      setIsSettingEnabled(name);
      await request({
        url: `/admin/org/${org}/location`,
        body: { names: [name], disable: true },
        method: "post",
      });
      await fetchLocations();
      setSelections((s) => s.filter((_s) => _s !== name));
      setIsLoading(false);
      setIsSettingEnabled("");
      notification.success({
        message: `Disabled location: ${name}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to disable location",
        description: errorMessage,
      });
      setIsLoading(false);
      setIsSettingEnabled("");
    }
  }

  async function onEnableSelections() {
    // /admin/org/:orgName/location {names: []}
    try {
      setIsLoading(true);
      setIsSettingEnabled("multiple");
      await request({
        url: `/admin/org/${org}/location`,
        body: { names: selections },
        method: "post",
      });
      await fetchLocations();
      setSelections([]);
      setIsLoading(false);
      setIsSettingEnabled("");
      notification.success({
        message: `Enabled locations: ${selections.join()}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: `Failed to enable locations`,
        description: errorMessage,
      });
      setIsLoading(false);
      setIsSettingEnabled("");
    }
  }

  async function onDisableSelections() {
    // /admin/org/:orgName/location {names: []}
    try {
      setIsLoading(true);
      setIsSettingEnabled("multiple");
      await request({
        url: `/admin/org/${org}/location`,
        body: { names: selections, disable: true },
        method: "post",
      });
      await fetchLocations();
      setSelections([]);
      setIsLoading(false);
      setIsSettingEnabled("");
      notification.success({
        message: `Disabled locations: ${selections.join()}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: `Failed to disable locations`,
        description: errorMessage,
      });
      setIsLoading(false);
      setIsSettingEnabled("");
    }
  }

  async function onDelete(name: string) {
    // /admin/org/:orgName/location/:locationName
    try {
      setIsLoading(true);
      setIsDeleting(name);
      await request({
        url: `/admin/org/${org}/location/${name}`,
        method: "delete",
      });
      await fetchLocations();
      setSelections((s) => s.filter((_s) => _s !== name));
      setIsLoading(false);
      setIsDeleting("");
      notification.success({
        message: `Deleted location: ${name}`,
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: `Failed to delete location: ${name}`,
        description: errorMessage,
      });
      setIsLoading(false);
      setIsDeleting("");
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <span className="text-base truncate">{text}</span>
          <span className="text-sm truncate">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text: string, record: any) => {
        if (locations.map((loc: any) => loc.name).includes(record.name)) {
          if (record.origin === "custom") {
            return (
              <Popconfirm
                title="Are you sure you want to delete this location?"
                onConfirm={() => onDelete(record.name)}
                onCancel={() => {}}
                okText="Delete"
                cancelText="Cancel"
              >
                <Button loading={isDeleting === record.name} disabled={isLoading} type={"primary"} danger>
                  Delete
                </Button>
              </Popconfirm>
            );
          }

          return (
            <Popconfirm
              title="Are you sure you want to disable this location?"
              onConfirm={() => onDisable(record.name)}
              onCancel={() => {}}
              okText="Disable"
              cancelText="Cancel"
            >
              <Button loading={isSettingEnabled === record.name} disabled={isLoading} type={"default"}>
                Disable
              </Button>
            </Popconfirm>
          );
        }
        return (
          <Popconfirm
            title="Are you sure you want to enable this location?"
            onConfirm={() => onEnable(record.name)}
            onCancel={() => {}}
            okText="Enable"
            cancelText="Cancel"
          >
            <Button loading={isSettingEnabled === record.name} disabled={isLoading} type={"primary"}>
              Enable
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const orgLocNames = locations.map((loc: any) => loc.name);
  const dataSource = [...locations, ...availableLocations.filter((loc: any) => !orgLocNames.includes(loc.name))].map(
    (loc: any) => ({ ...loc, key: loc.name }),
  );

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelections(selectedRowKeys);
    },
    selectedRowKeys: selections,
    getCheckboxProps: (record: any) => ({
      disabled: record.origin === "custom",
      name: record.name,
    }),
  };

  return (
    <>
      <div className="px-8 w-full relative">
        <PageHeader title={org} subTitle={"Org Detail - Locations"} />
        <Table
          size={"small"}
          rowSelection={rowSelection}
          loading={isLoading}
          dataSource={dataSource}
          columns={columns}
          pagination={{ showSizeChanger: false, pageSize: 50 }}
        />
        {selections.length > 0 ? (
          <div className="fixed p-2 bg-white shadow-lg rounded" style={{ right: 50, bottom: 50 }}>
            <Popconfirm
              title="Are you sure you want to enable these locations?"
              onConfirm={() => onEnableSelections()}
              onCancel={() => {}}
              okText="Enable"
              cancelText="Cancel"
            >
              <Button type={"primary"} loading={isSettingEnabled === "multiple"} disabled={isLoading}>
                Enable {selections.length} Locations
              </Button>
            </Popconfirm>
          </div>
        ) : null}
        {selections.length > 0 ? (
          <div className="fixed p-2 bg-white shadow-lg rounded" style={{ right: 250, bottom: 50 }}>
            <Popconfirm
              title="Are you sure you want to disable these locations?"
              onConfirm={() => onDisableSelections()}
              onCancel={() => {}}
              okText="Disable"
              cancelText="Cancel"
            >
              <Button type={"default"} loading={isSettingEnabled === "multiple"} disabled={isLoading}>
                Disable {selections.length} Locations
              </Button>
            </Popconfirm>
          </div>
        ) : null}
      </div>
    </>
  );
};

export const OrgLocation = observer(OrgLocationRaw);
