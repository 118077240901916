import * as React from "react";
import { Thumbnail } from "./types";

interface Props {
  thumbnails: Thumbnail[];
  onSelect?: (id: string) => void;
}
export const ThumbnailList: React.FC<Props> = ({ thumbnails, onSelect }) => {
  const selectable = !!onSelect;
  const [selectedThumbnailId, setSelectedThumbnailId] =
    React.useState<string>("");

  React.useEffect(() => {
    if (!onSelect) {
      return;
    }
    onSelect(selectedThumbnailId);
  }, [selectedThumbnailId]);

  return (
    <>
      <div className="font-semibold mb-2">Current Thumbnails</div>
      <div className="flex flex-wrap gap-4">
        {thumbnails.filter.length === 0 ? "No thumbnails found" : null}
        {thumbnails.map((t) => (
          <div>
            <img
              style={{ width: 344, height: 193 }}
              className={`border rounded-sm ${
                selectable ? "cursor-pointer hover:ring-2" : ""
              } ${selectedThumbnailId === t.id ? "ring-2" : ""}`}
              src={t.data}
              onClick={
                selectable ? () => setSelectedThumbnailId(t.id) : undefined
              }
            />
            <span>{t.name}</span>
          </div>
        ))}
      </div>
    </>
  );
};
