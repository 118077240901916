import {
  Alert,
  Button,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Select,
  TableColumnsType,
} from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { copyOf, HTTPMethod, removeDecimalZeros, request } from "../../utils";
import {
  ChargeableItem,
  RatePlan,
  RatePlanItem,
  SUPPORTED_CURRENCIES,
} from "./types";
import { v4 as uuidv4 } from "uuid";
import { useLocation } from "react-router-dom";
import qs from "qs";

interface ModalDataType {
  data: Omit<Omit<RatePlan, "id">, "items"> & {
    ratePlanItems: FormRatePlanItem[];
  };
  isLoading: boolean;
  isOpen: boolean;
  currentItemId: string | null;
  preferredItemId: string | null;
}

const initialModalState: ModalDataType = {
  data: {
    name: "",
    description: "",
    currency: SUPPORTED_CURRENCIES[0],
    ratePlanItems: [],
  },
  isLoading: false,
  isOpen: false,
  currentItemId: null,
  preferredItemId: null,
};

interface Action {
  type:
    | "set"
    | "reset"
    | "loading"
    | "data"
    | "currentItemId"
    | "preferredItemId"
    | "name"
    | "description"
    | "currency"
    | "ratePlanItems";
  payload: any;
}

function modalReducer(state: ModalDataType, action: Action) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "reset":
      return initialModalState;
    case "loading":
      return { ...state, loading: action.payload };
    case "data":
      return { ...state, data: action.payload };
    case "currentItemId":
      return { ...state, currentItemId: action.payload };
    case "preferredItemId":
      return { ...state, preferredItemId: action.payload };
    default:
      return {
        ...state,
        data: { ...state.data, [action.type]: action.payload },
      };
  }
}

export const BillingRatePlans: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<RatePlan[]>([]);
  const [chargeableItems, setChargeableItems] = React.useState<
    ChargeableItem[]
  >([]);
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([]);

  const location = useLocation();
  const { selectedId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [modalData, dispatchModalAction] = React.useReducer(
    modalReducer,
    initialModalState
  );

  React.useEffect(() => {
    fetchItems();
    if (selectedId) {
      setExpandedRowKeys([selectedId.toString()]);
    }
  }, []);

  function getRowClassName(record: RatePlan) {
    if (record.id === selectedId) {
      return "bg-blue-50";
    }
    return "";
  }

  async function fetchItems() {
    try {
      setIsLoading(true);
      const items = await request({
        service: "billing-ng",
        url: "/rate_plan",
      });
      setItems(items);
      const chargeableItems = await request({
        service: "billing-ng",
        url: "/chargeable_items",
      });
      setChargeableItems(chargeableItems);
      setIsLoading(false);
    } catch (e: any) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to Fetch Data",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function confirm() {
    try {
      dispatchModalAction({ type: "loading", payload: true });
      let url = "/rate_plan";
      let method: HTTPMethod = "post";
      if (modalData.currentItemId) {
        url += `/${modalData.currentItemId}`;
        method = "put";
      }
      await request({
        service: "billing-ng",
        url: url,
        method: method,
        body: {
          name: modalData.data.name,
          description: modalData.data.description,
          currency: modalData.data.currency,
          items: modalData.data.ratePlanItems.map((r) => {
            // @ts-ignore
            delete r.id;

            // @ts-ignore
            r.chargeableItem = chargeableItems.find(
              (c) => c.id === r.chargeableItemId
            )!;

            // @ts-ignore
            delete r.chargeableItemId;

            return r;
          }),
        },
      });
      onClear();
      notification.success({
        message: "Success",
      });
      fetchItems();
    } catch (e: any) {
      dispatchModalAction({ type: "loading", payload: false });
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to add chargable item",
        description: errorMessage,
      });
    }
  }

  async function deleteConfirm(id: string) {
    try {
      setIsLoading(true);
      await request({
        service: "billing-ng",
        url: `/rate_plan/${id}`,
        method: "delete",
      });
      notification.success({
        message: "Success",
        description: "Deleted rate plan",
      });
      setIsLoading(false);
      fetchItems();
    } catch (e: any) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete rate plan",
        description: errorMessage,
      });
    }
  }

  function onClear() {
    dispatchModalAction({ type: "reset", payload: undefined });
  }

  const columns: ColumnsType<RatePlan> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: RatePlan) => {
        return (
          <div className={`flex items-center justify-end gap-4`}>
            <Button
              size={"small"}
              onClick={() =>
                dispatchModalAction({
                  type: "set",
                  payload: {
                    data: {
                      name: record.name,
                      description: record.description,
                      currency: record.currency,
                      ratePlanItems: record.items.map((i) => ({
                        id: i.id,
                        chargeRate: i.chargeRate,
                        chargeableItemId: i.chargeableItem.id,
                        meteringThreshold: i.meteringThreshold,
                      })),
                    },
                    isLoading: false,
                    isOpen: true,
                    currentItemId: record.id,
                    preferredItemId: null,
                  },
                })
              }
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure you want to delete this item?"}
              onConfirm={() => deleteConfirm(record.id)}
              disabled={isLoading}
            >
              <Button disabled={isLoading} size={"small"} danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onExpand = (expanded, record) => {
    const keys = expanded ? [record.id] : [];
    setExpandedRowKeys(keys);
  };

  const expandedRowRender = (record: RatePlan) => {
    const columns: TableColumnsType<RatePlanItem> = [
      {
        title: "Charge Rate",
        key: "chargeRate",
        render: (item: RatePlanItem) => (
          <span>
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: record.currency,
              maximumFractionDigits: 25,
            }).format(Number(item.chargeRate))}
            {item.chargeRateDenominator
              ? ` / ${removeDecimalZeros(
                  item.chargeRateDenominator.toString()
                )}`
              : null}
          </span>
        ),
      },
      {
        title: "Metering Threshold",
        key: "meteringThreshold",
        render: (item: RatePlanItem) => <span>{item.meteringThreshold}</span>,
      },
      {
        title: "Metering Group",
        key: "meteringGroup",
        defaultSortOrder: "descend",
        sorter: (a, b) => a.meteringGroup.localeCompare(b.meteringGroup),
        render: (item: RatePlanItem) => <span>{item.meteringGroup}</span>,
      },
      {
        title: "Chargable",
        key: "nameDescription",
        render: (item: RatePlanItem) => <span>{item.chargeableItem.name}</span>,
      },
      {
        title: "Chargable Unit",
        key: "chargableItem.unit",
        render: (item: RatePlanItem) => <span>{item.chargeableItem.unit}</span>,
      },
    ];

    let data = items.find((i) => i.id === record.id)?.items || [];
    for (let item of data) {
      if (item.id) continue;
      item.id = uuidv4();
    }
    return (
      <Table
        columns={columns}
        dataSource={data}
        rowKey={"id"}
        pagination={false}
        size={"small"}
      />
    );
  };

  return (
    <div className={`pr-8`}>
      <div className={`mb-4 flex items-center gap-4`}>
        <Button
          onClick={() => {
            dispatchModalAction({
              type: "set",
              payload: {
                data: {
                  name: "",
                  description: "",
                  currency: SUPPORTED_CURRENCIES[0],
                  ratePlanItems: [],
                },
                isLoading: false,
                isOpen: true,
                preferredItemId: "",
                currentItemId: null,
              },
            });
            // setRatePlanItems(
            //   chargeableItems.map((c) => ({
            //     chargeRate: 0,
            //     chargeableItemId: c.id,
            //     id: uuidv4(),
            //     meteringThreshold: 0,
            //   }))
            // );
          }}
          type={"primary"}
        >
          Add Rate Plan
        </Button>
        <Button onClick={fetchItems} type={"default"}>
          Refresh
        </Button>
        <Alert
          message={
            "Changes might take time to reflect in the UI, because of database replication"
          }
        />
      </div>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={items}
        expandable={{
          expandedRowRender,
          expandedRowKeys,
          onExpand,
        }}
        rowKey={"id"}
        pagination={false}
        rowClassName={getRowClassName}
      />
      <Modal
        width={1000}
        open={modalData.isOpen}
        onCancel={onClear}
        onOk={confirm}
        cancelButtonProps={{ disabled: isLoading }}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            {modalData.preferredItemId !== null ? (
              <>
                <Button
                  type="primary"
                  disabled={!modalData.preferredItemId}
                  onClick={() => {
                    const { preferredItemId, ...updatedModalData } = modalData;
                    const foundReferredItem = items.find(
                      (i) => i.id === preferredItemId
                    );
                    if (!foundReferredItem) {
                      notification.warning({
                        message: "Failed to find reference item",
                      });
                      dispatchModalAction({
                        type: "set",
                        payload: {
                          ...updatedModalData,
                          preferredItemId: null,
                        },
                      });
                    } else {
                      const { id, items, ...newData } = foundReferredItem;
                      dispatchModalAction({
                        type: "set",
                        payload: {
                          ...updatedModalData,
                          preferredItemId: null,
                          data: {
                            ...newData,
                            ratePlanItems: items.map((i) => ({
                              id: i.id,
                              chargeRate: i.chargeRate,
                              chargeableItemId: i.chargeableItem.id,
                              meteringThreshold: i.meteringThreshold,
                            })),
                          },
                        },
                      });
                    }
                  }}
                >
                  Create prefilled
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    dispatchModalAction({
                      type: "set",
                      payload: {
                        ...modalData,
                        preferredItemId: null,
                      },
                    })
                  }
                >
                  Create new
                </Button>
              </>
            ) : (
              <OkBtn />
            )}
          </>
        )}
      >
        {modalData.preferredItemId !== null ? (
          <div className={`mb-4`}>
            <span className={`mb-1`}>Select a Rate Plan to Prefill</span>
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={modalData.preferredItemId}
              onChange={(value: string) =>
                dispatchModalAction({
                  type: "preferredItemId",
                  payload: value,
                })
              }
              filterOption={(
                input: string,
                option?: { label: string; value: string }
              ) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={items.map((i) => ({ label: i.name, value: i.id }))}
            />
          </div>
        ) : (
          <>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Name</span>
              <Input
                value={modalData.data.name}
                onChange={(e) =>
                  dispatchModalAction({
                    type: "name",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Description</span>
              <Input
                value={modalData.data.description}
                onChange={(e) =>
                  dispatchModalAction({
                    type: "description",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1 mr-4`}>Currency</span>
              <Select
                value={modalData.data.currency}
                onChange={(value) =>
                  dispatchModalAction({
                    type: "currency",
                    payload: value,
                  })
                }
                options={SUPPORTED_CURRENCIES.map((c) => ({
                  label: c,
                  value: c,
                }))}
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1 mr-2`}>Items</span>
              <Button
                size={"small"}
                type={"primary"}
                onClick={() => {
                  const items = copyOf(modalData.data.ratePlanItems);
                  items.push({ id: uuidv4() });
                  dispatchModalAction({
                    type: "ratePlanItems",
                    payload: items,
                  });
                }}
              >
                + Add Item
              </Button>
              {modalData.data.ratePlanItems.length > 0 ? (
                <div className={`flex items-center`}>
                  <div className={`w-5/12`}>Chargeable Item</div>
                  <div className={`w-3/12`}>Charge Rate</div>
                  <div className={`w-3/12`}>Metering Threshold</div>
                  <div className={`w-1/12`} />
                </div>
              ) : null}
              {modalData.data.ratePlanItems.map((i) => {
                return (
                  <div className={`flex items-center gap-2 mb-2`}>
                    <Select
                      className={`w-5/12`}
                      value={i.chargeableItemId}
                      onChange={(value) => {
                        const items: FormRatePlanItem[] = copyOf(
                          modalData.data.ratePlanItems
                        );
                        const item = items.find((_item) => _item.id === i.id)!;
                        item.chargeableItemId = value;
                        dispatchModalAction({
                          type: "ratePlanItems",
                          payload: items,
                        });
                      }}
                      options={chargeableItems.map((c) => ({
                        label: c.name,
                        value: c.id,
                      }))}
                    />
                    <InputNumber
                      className={`w-3/12`}
                      value={i.chargeRate}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        const items: FormRatePlanItem[] = copyOf(
                          modalData.data.ratePlanItems
                        );
                        const item = items.find((_item) => _item.id === i.id)!;
                        item.chargeRate = value;
                        dispatchModalAction({
                          type: "ratePlanItems",
                          payload: items,
                        });
                      }}
                    />
                    <InputNumber
                      className={`w-3/12`}
                      value={i.meteringThreshold}
                      onChange={(value) => {
                        if (!value) {
                          return;
                        }
                        const items: FormRatePlanItem[] = copyOf(
                          modalData.data.ratePlanItems
                        );
                        const item = items.find((_item) => _item.id === i.id)!;
                        item.meteringThreshold = value;
                        dispatchModalAction({
                          type: "ratePlanItems",
                          payload: items,
                        });
                      }}
                    />
                    <Button
                      className={`w-1/12`}
                      onClick={() => {
                        let items: FormRatePlanItem[] = copyOf(
                          modalData.data.ratePlanItems
                        );
                        items = items.filter((_item) => _item.id !== i.id)!;
                        dispatchModalAction({
                          type: "ratePlanItems",
                          payload: items,
                        });
                      }}
                      danger
                      type={"primary"}
                    >
                      Remove
                    </Button>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

interface FormRatePlanItem {
  id: string;
  chargeRate: number;
  meteringThreshold: number;
  chargeableItemId: string;
}
