import * as React from "react";
import { observer } from "mobx-react-lite";
import { Clipboard, ExternalLink } from "react-feather";
import { Partner, Referral } from "./types";
import { Button, Input, Modal, notification, Popconfirm } from "antd";
import { JSONModal } from "../../jsonModal";
import * as axiosPackage from "axios";
import { EXTERNAL_SIGNUP_API } from "../../envVariables";
import { request } from "../../utils";
const axios = axiosPackage.default;

interface Props {
  partner: Partner;
  fetchPartner: any;
}
const ReferralListRaw: React.FC<Props> = ({ partner, fetchPartner }) => {
  const [viewingItem, setViewingItem] = React.useState<Referral | null>(null);

  const [isAddingReferral, setIsAddingReferral] = React.useState(false);
  const [newReferralMail, setNewReferralMail] = React.useState("");

  React.useEffect(() => {
    setNewReferralMail("");
  }, [isAddingReferral]);

  async function onAddReferral() {
    setIsAddingReferral(false);
    try {
      await axios.post(
        `${EXTERNAL_SIGNUP_API}/partner/${partner.id}/referral`,
        {
          email: newReferralMail,
        },
        { headers: { authorization: `Bearer ${partner.secretKey}` } }
      );
      notification.success({
        message: "Referral is added",
      });
      fetchPartner();
    } catch (e) {
      notification.warning({
        message: "Failed to add referral",
        description: e.message,
      });
    }
  }

  async function deleteReferral(referralId: string) {
    try {
      await request({
        method: "delete",
        service: "external-signup",
        url: `/referral/${referralId}`,
      });
      notification.success({
        message: "Success",
        description: "Deleted referral",
      });
      fetchPartner();
    } catch (e) {
      notification.warning({
        message: "Failed",
        description: e.message,
      });
    }
  }

  return (
    <>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Referral: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
      {isAddingReferral ? (
        <Modal
          title={"Add Referral"}
          okButtonProps={{ disabled: newReferralMail.length < 1 }}
          onOk={onAddReferral}
          onCancel={() => setIsAddingReferral(false)}
          open={isAddingReferral}
          maskClosable={false}
        >
          <div className="pr-8">
            <Input
              placeholder={"Email Address"}
              value={newReferralMail}
              onChange={(e) => setNewReferralMail(e.target.value)}
            />
          </div>
        </Modal>
      ) : null}
      <div className="flex flex-col w-full px-8">
        <div className="flex items-center gap-4 mb-4">
          <div className="text-base text-gray-700 font-semibold">
            Referrals ({partner.referrals.length})
          </div>
          <Button
            type={"primary"}
            size={"small"}
            onClick={() => setIsAddingReferral(true)}
          >
            Add Referral
          </Button>
        </div>
        <div className="w-full">
          <div className="flex items-center px-4 py-2 border border-b-0 font-semibold bg-gray-200">
            <div className="w-3/12">Email & Id</div>
            <div className="w-3/12">Account / Full Name</div>
            <div className="w-4/12">Redirect URI</div>
            <div className="w-1/12">Status</div>
            <div className="w-1/12">Actions</div>
          </div>
          <div className="flex flex-col mb-4 mt-1">
            {partner.referrals.length === 0 ? <div>No Referrals</div> : null}
            {partner.referrals.map((r, index) => (
              <div
                className={`flex items-center px-4 py-2 ${
                  index !== partner.referrals.length - 1 ? "border-b" : ""
                } ${index % 2 === 1 ? "bg-gray-50" : ""}`}
              >
                <div className="w-3/12 flex flex-col">
                  <div>{r.email}</div>
                  <div className="text-sm">{r.id}</div>
                </div>
                <div className="w-3/12">{r.accountName || r.fullName}</div>
                <div className="w-4/12 flex gap-1">
                  <span className="text-xs">
                    {r.redirectUri.split("cpln.io")[1]}
                  </span>
                  <span>
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(r.redirectUri);
                        notification.success({
                          message: "Copied to clipboard",
                        });
                      }}
                    >
                      <Clipboard className="w-4 h-4" />
                    </button>
                  </span>
                  <span>
                    <a target={"_blank"} href={r.redirectUri}>
                      <ExternalLink className="w-4 h-4" />
                    </a>
                  </span>
                </div>
                <div className="w-1/12">{(r as any).status || "-"}</div>
                <div className="w-1/12 flex flex-col gap-2">
                  <Button
                    size={"small"}
                    type={"default"}
                    onClick={() => setViewingItem(r)}
                  >
                    View
                  </Button>
                  <Popconfirm
                    title={"Are you sure?"}
                    onConfirm={() => deleteReferral(r.id)}
                  >
                    <Button size={"small"} danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const ReferralList = observer(ReferralListRaw);
