import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  endInfinityMoment,
  isoToMoment,
  startInfinityMoment,
} from "./AccountState/Timeline/utils";
import { AccountState } from "../Billing/types";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import qs from "qs";

const AccountStateCardRaw = (props: {
  label: string;
  account: any;
  state: AccountState | null;
  onEdit?: () => void;
}) => {
  const navigate = useNavigate();
  const startDate = isoToMoment(props.state?.startTime, "start");
  const endDate = isoToMoment(props.state?.endTime, "end");
  return (
    <div
      className="w-fit text-xs flex flex-col gap-2 border px-4 py-2 rounded shadow"
      style={{ whiteSpace: "nowrap" }}
    >
      <div className="flex items-center">
        <div className="font-bold">{props.label} Account State</div>
        <div className="flex-grow" />
        {props.label !== "Prev" ? (
          <Button
            onClick={() => {
              if (props.onEdit) {
                props.onEdit();
              } else {
                navigate({
                  pathname: `/admin/account/${props.account.id}/state`,
                  search: qs.stringify({
                    selectedStateId: props.state?.id,
                  }),
                });
              }
            }}
            size="small"
            icon={<EditOutlined />}
          >
            Edit
          </Button>
        ) : null}
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
        }}
        className="gap-2"
      >
        <div className="flex-col">
          <div className="font-semibold">Trust Level</div>
          <a
            onClick={() =>
              navigate({
                pathname: `/admin/billing`,
                search: qs.stringify({
                  selectedTab: "trustLevels",
                  selectedId: props.state?.trustLevel.id,
                }),
              })
            }
          >
            {props.state?.trustLevel.name}
          </a>
        </div>
        <div className="flex-col">
          <div className="font-semibold">Rate Plan</div>
          <a
            onClick={() =>
              navigate({
                pathname: `/admin/billing`,
                search: qs.stringify({
                  selectedTab: "ratePlans",
                  selectedId: props.state?.ratePlan.id,
                }),
              })
            }
          >
            {props.state?.ratePlan.name}
          </a>
        </div>
        <div className="flex-col">
          <div className="font-semibold">Payment Method</div>
          <div>{props.state?.paymentMethodType}</div>
        </div>
        <div className="flex-col">
          <div className="font-semibold">Type</div>
          <div>{props.state?.type}</div>
        </div>
        <div className="flex-col">
          <div className="font-semibold">Start Time</div>
          <div>
            {!startDate.isSame(startInfinityMoment) ? (
              startDate.format("DD MMM 'YY")
            ) : (
              <span className={`text-lg`}>- &infin;</span>
            )}
          </div>
        </div>
        <div className="flex-col">
          <div className="font-semibold">End Time</div>
          <div>
            {!endDate.isSame(endInfinityMoment) ? (
              endDate.format("DD MMM 'YY")
            ) : (
              <span className={`text-lg`}>+ &infin;</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AccountStateCard = observer(AccountStateCardRaw);
