import * as React from "react";
import { observer } from "mobx-react-lite";
import { MainStoreContext, useMain } from "./stores/main";
import { doPeriodicTokenExpirationCheck } from "./utils";

export const MainStoreProvider: React.FC<React.PropsWithChildren> = observer(
  ({ children }) => {
    const mainHook = useMain();
    React.useEffect(() => {
      if (!mainHook.isLoggedIn) {
        return;
      }

      let tokenCheckId = setInterval(async () => {
        await doPeriodicTokenExpirationCheck();
      }, 15 * 1000);

      return () => {
        if (tokenCheckId) {
          clearInterval(tokenCheckId);
        }
      };
    }, [mainHook.isLoggedIn]);

    return (
      <MainStoreContext.Provider value={mainHook}>
        {children}
      </MainStoreContext.Provider>
    );
  }
);
