import * as React from "react";
import { Button, Input, Modal, Pagination, Table, Upload, UploadFile, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import {
  FullMetadata,
  StorageReference,
  deleteObject,
  getDownloadURL,
  getMetadata,
  getStorage,
  list,
  ref,
  uploadBytes,
} from "firebase/storage";
import { ICustomLogo } from "./types";
import { main } from "../../stores/main";
import { UploadOutlined } from "@ant-design/icons";

const PAGE_SIZE = 10;

const CustomLogoListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [customLogos, setCustomLogos] = React.useState<ICustomLogo[]>([]);
  const [nextPageToken, setNextPageToken] = React.useState<string | undefined>(undefined);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [fetchedPage, setFetchedPage] = React.useState<number>(0);
  const [actingOn, setActingOn] = React.useState("");
  const [action, setAction] = React.useState<"delete" | "">("");

  const [isAdding, setIsAdding] = React.useState(false);
  const [isLoadingAdding, setIsLoadingAdding] = React.useState(false);
  const [newSlug, setNewSlug] = React.useState("");
  const [newFileList, setNewFileList] = React.useState<UploadFile[]>([]);

  React.useEffect(() => {
    fetchCustomLogos({ refresh: true });
  }, []);

  React.useEffect(() => {
    if (currentPage > fetchedPage) {
      fetchCustomLogos({ refresh: false });
    }
  }, [currentPage]);

  async function fetchCustomLogos({ refresh }: { refresh: boolean }) {
    try {
      setIsLoading(true);
      if (refresh) {
        setFetchedPage(0);
      }
      let _customLogos: ICustomLogo[] = refresh ? [] : [...customLogos];

      const storage = getStorage(main.firebaseApp);
      const listRef = ref(storage, "custom-logo");

      const listResponse = await list(listRef, {
        maxResults: PAGE_SIZE,
        pageToken: refresh ? undefined : nextPageToken,
      });
      const items = await getPopulatedItems(listResponse.items);

      setCustomLogos(_customLogos.concat(items));
      setNextPageToken(listResponse.nextPageToken);
      setFetchedPage(currentPage);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch custom logos",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function getPopulatedItems(items: StorageReference[]) {
    const populatedItems: ICustomLogo[] = [];
    const metadataPromises: Promise<FullMetadata>[] = [];
    const urlPromises: Promise<string>[] = [];
    for (const itemRef of items) {
      metadataPromises.push(getMetadata(itemRef));
      urlPromises.push(getDownloadURL(itemRef));
    }

    const [metadataResponses, urlResponses] = await Promise.all([
      Promise.all(metadataPromises),
      Promise.all(urlPromises),
    ]);

    for (const itemRefIndex in items) {
      const itemRef = items[itemRefIndex];
      const metadata = metadataResponses[itemRefIndex];
      const url = urlResponses[itemRefIndex];
      populatedItems.push({
        slug: metadata.customMetadata!.slug,
        fullPath: itemRef.fullPath,
        name: itemRef.name,
        url: url,
      });
    }
    return populatedItems;
  }

  async function onCustomLogoAction(customLogo: ICustomLogo, action: "delete") {
    try {
      setIsLoading(true);
      setActingOn(customLogo.fullPath);
      let description = "";

      if (action === "delete") {
        Modal.confirm({
          title: "Delete",
          onOk: async () => {
            setAction("delete");

            const storage = getStorage(main.firebaseApp);
            const imageRef = ref(storage, customLogo.fullPath);
            await deleteObject(imageRef);
            if (action === "delete") {
              description = `Deleted custom logo "${customLogo.slug}"`;
            }
            setCurrentPage(1);
            notification.success({
              message: "Success",
              description,
            });
          },
        });
      }

      setActingOn("");
      setAction("");
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to process custom logos",
        description: errorMessage,
      });
      setActingOn("");
      setAction("");
      setIsLoading(false);
    }
  }

  async function handleConfirmNewLogo() {
    if (customLogos.some((c) => c.slug === newSlug)) {
      notification.warning({
        message: "Slug is already taken",
        description: "Type a unique slug",
      });
      return;
    }
    try {
      setIsLoadingAdding(true);
      const storage = getStorage(main.firebaseApp);
      const file = newFileList[0];
      const storageRef = ref(storage, `custom-logo/${file.uid}`);

      await uploadBytes(storageRef, file as any, { customMetadata: { slug: newSlug } });
      setIsLoadingAdding(false);
      notification.success({
        message: "Created new custom logo",
      });
      setCurrentPage(1);
      setNewSlug("");
      setNewFileList([]);
      setIsAdding(false);
    } catch (e) {
      setIsLoadingAdding(false);
      notification.warning({
        message: "Failed to create new custom logo",
        description: e.message,
      });
    }
  }

  const columns = [
    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      render: (text: string, record: ICustomLogo) => (
        <div className="flex items-center gap-2 h-16">
          <span>{text}</span>
          <img src={`${record.url}`} style={{ width: "6rem", height: "3rem", objectFit: "contain" }} />
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "name",
      key: "nameForInspect",
      width: 400,
      render: (_: string, record: any) => (
        <div className="flex items-center gap-4">
          <Button
            danger
            type={"primary"}
            onClick={() => onCustomLogoAction(record, "delete")}
            size={"small"}
            loading={actingOn === record.uid && action === "delete"}
            disabled={isLoading}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center">
        <PageHeader title={"Custom Logos"} backIcon={false} />
        <Button onClick={() => setIsAdding(true)} type={"primary"}>
          Add New
        </Button>
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={customLogos.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)}
          columns={columns as any}
          rowKey={"slug"}
          pagination={false}
        />
        <Pagination
          total={nextPageToken ? customLogos.length + 1 : customLogos.length}
          pageSize={PAGE_SIZE}
          current={currentPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </div>
      {isAdding ? (
        <Modal
          open={isAdding}
          maskClosable={false}
          title={"Add New Custom Logo"}
          okButtonProps={{ disabled: !newSlug || newFileList.length !== 1 || isLoadingAdding }}
          cancelButtonProps={{ disabled: isLoadingAdding }}
          onOk={handleConfirmNewLogo}
          onCancel={() => {
            setNewSlug("");
            setNewFileList([]);
            setIsAdding(false);
          }}
        >
          <div className="flex flex-col gap-2">
            <Input value={newSlug} onChange={(e) => setNewSlug(e.target.value)} />
            <Upload
              onRemove={(file) => {
                const index = newFileList.indexOf(file);
                const _newFileList = newFileList.slice();
                _newFileList.splice(index, 1);
                setNewFileList(_newFileList);
              }}
              beforeUpload={(file) => {
                setNewFileList([...newFileList, file]);

                return false;
              }}
              fileList={newFileList}
            >
              <Button disabled={newFileList.length > 0} icon={<UploadOutlined />}>
                Click to Select Logo
              </Button>
            </Upload>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export const CustomLogoList = observer(CustomLogoListRaw);
