import * as React from "react";
import { Alert, Button, Menu, notification, Spin } from "antd";
import {
  Routes,
  Route,
  Link,
  Navigate,
  useParams,
  useNavigate,
  useLocation,
  NavLink,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { PartnerInfo } from "./PartnerInfo";
import { ReferralList } from "./ReferralList";
import { CookieReferralList } from "./CookieReferralList";
import { Partner } from "./types";
import { request } from "../../utils";
import { ExpiredCookieReferralList } from "./ExpiredCookieReferralList";

type RouteParams = "partner";
const PartnerDetailRaw: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { partner: partnerId } = useParams<RouteParams>();
  const [partner, setPartner] = React.useState<Partner>(null as any);
  const [error, setError] = React.useState("");
  const [isPageReady, setIsPageReady] = React.useState(false);

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    if (pathname.includes("expired")) {
      _menuActiveKey = "expiredcookiereferrals";
    } else if (pathname.includes("cookie")) {
      _menuActiveKey = "cookiereferrals";
    } else if (pathname.includes("referrals")) {
      _menuActiveKey = "referrals";
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  React.useEffect(() => {
    fetchPartner().finally(() => {
      setIsPageReady(true);
    });
  }, []);

  async function fetchPartner() {
    try {
      const res: Partner[] = await request({
        service: "external-signup",
        url: `/partner`,
      });
      const _partner: Partner = res.find(
        (i: Partner) => i.id === partnerId
      ) as Partner;
      if (!_partner) {
        setError(`Could not find partner with id ${partnerId}`);
        return;
      }
      const referralsRes = await request({
        service: "external-signup",
        url: `/partner/${_partner.id}/referral`,
      });
      _partner.referrals = referralsRes;
      const cookieReferralsRes = await request({
        service: "external-signup",
        url: `/partner/${_partner.id}/cookiereferral`,
      });
      _partner.cookieReferrals = cookieReferralsRes;
      const expiredCookieReferralsRes = await request({
        service: "external-signup",
        url: `/partner/${_partner.id}/expiredcookiereferral`,
      });
      _partner.expiredCookieReferrals = expiredCookieReferralsRes;
      setPartner(_partner);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      notification.warning({
        message: "Failed to fetch partner and its referrals",
        description: errorMessage,
      });
      navigate("/admin/external-signup");
    }
  }

  if (!isPageReady) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
        <Alert type={"error"} message={error} />
        <Button onClick={() => navigate(`/admin/external-signup`)}>
          Go Back
        </Button>
      </div>
    );
  }

  return (
    <div className="flex p-8">
      <Menu theme={"light"} selectedKeys={[menuActiveKey]}>
        <Menu.Item key={"info"}>
          <NavLink to={`info`}>Info</NavLink>
        </Menu.Item>
        <Menu.Item key={"referrals"}>
          <NavLink to={`referrals`}>Referrals</NavLink>
        </Menu.Item>
        <Menu.Item key={"cookiereferrals"}>
          <NavLink to={`cookiereferrals`}>Cookie Referrals</NavLink>
        </Menu.Item>
        <Menu.Item key={"expiredcookiereferrals"}>
          <NavLink to={`expiredcookiereferrals`}>
            Expired Cookie Referrals
          </NavLink>
        </Menu.Item>
      </Menu>
      <Routes>
        <Route path={`info/*`} element={<PartnerInfo partner={partner} />} />
        <Route
          path={`referrals/*`}
          element={
            <ReferralList partner={partner} fetchPartner={fetchPartner} />
          }
        />
        <Route
          path={`cookiereferrals/*`}
          element={
            <CookieReferralList partner={partner} fetchPartner={fetchPartner} />
          }
        />
        <Route
          path={`expiredcookiereferrals/*`}
          element={<ExpiredCookieReferralList partner={partner} />}
        />
        <Route index element={<Navigate to={`info`} replace />} />
      </Routes>
    </div>
  );
};

export const PartnerDetail = observer(PartnerDetailRaw);
