import { $getRoot, createEditor } from "lexical";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ExampleTheme } from "../../RichTextEditor/exampleTheme";
import {
  HasLinks,
  Incident,
  IncidentEditable,
  IncidentEvent,
  IncidentEventEditable,
} from "./types";
import moment from "moment-timezone";

export function getSelfLink(item: HasLinks) {
  return getLink(item, "self");
}

export function getNextLink(item: HasLinks) {
  return getLink(item, "next");
}

export function getLink(item: HasLinks, rel: string) {
  return item.links.find((l) => l.rel === rel)?.href || "";
}

export function getIncidentId(selfLink: string) {
  return selfLink.split("/")[3]!;
}

export function dataToIncident(data: any): Incident {
  const detectedTime = new Date(data.detectedTime);
  let resolvedTime = data.resolvedTime;
  if (resolvedTime) {
    resolvedTime = new Date(resolvedTime);
  }
  return { ...data, detectedTime, resolvedTime };
}

export function dataToEvent(data: any): IncidentEvent {
  const createdTime = new Date(data.createdTime);
  return { ...data, createdTime };
}

export function incidentToEditable(incident: Incident): IncidentEditable {
  // TODO fix type
  // @ts-ignore
  return Object.assign(incident, {
    detectedTime: moment(incident.detectedTime),
    resolvedTime: incident.resolvedTime
      ? moment(incident.resolvedTime)
      : undefined,
  });
}

export function editableToIncident(editable: IncidentEditable): Incident {
  return Object.assign(editable, {
    detectedTime: editable.detectedTime.toDate(),
    resolvedTime: editable.resolvedTime
      ? editable.resolvedTime.toDate()
      : undefined,
  });
}

export function eventToEditable(event: IncidentEvent): IncidentEventEditable {
  // TODO fix type
  // @ts-ignore
  return Object.assign(event, { createdTime: moment(event.createdTime) });
}

export function editableToEvent(
  editable: IncidentEventEditable
): IncidentEvent {
  return Object.assign(editable, {
    createdTime: editable.createdTime.toDate(),
  });
}

export function richToPlain(richText: string) {
  let result = "";

  try {
    const editor = createEditor(RichEditorConfig);
    const parsedEditorState = editor.parseEditorState(richText);

    result = parsedEditorState.read(() => $getRoot().getTextContent());
  } catch (e) {
    result = "Preview is not available";
  }

  return result;
}

export const RichEditorConfig = {
  // The editor theme
  theme: ExampleTheme,
  // Handling of errors during update
  namespace: "admin-panel",
  onError(error: Error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};
