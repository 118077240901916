import * as React from "react";
import { Button, Tag, Table, notification, Modal, Select, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { request } from "../../utils";
import { Link } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { Partner, PartnerCreate } from "./types";
import { UnattachedPartner } from "./utils";

const initialPartnerState: PartnerCreate = {
  email: "",
  hostnames: [],
  name: "",
};

interface Action {
  type: "reset" | "email" | "hostnames" | "name";
  payload: any;
}

function partnerReducer(state: PartnerCreate, action: Action): PartnerCreate {
  if (action.type === "reset") {
    return initialPartnerState;
  }
  return { ...state, [action.type]: action.payload };
}

const PartnerListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [isAddingPartner, setIsAddingPartner] = React.useState(false);
  const [isLoadingAddingPartner, setIsLoadingAddingPartner] =
    React.useState(false);
  const [createPartnerData, dispatchCreatePartner] = React.useReducer(
    partnerReducer,
    initialPartnerState
  );
  const [partners, setPartners] = React.useState<Partner[]>([]);
  const [viewingItem, setViewingItem] = React.useState<any>(null);

  React.useEffect(() => {
    fetchPartners();
  }, []);

  React.useEffect(() => {
    dispatchCreatePartner({ type: "reset", payload: "" });
  }, [isAddingPartner]);

  async function fetchPartners() {
    try {
      setIsLoading(true);
      const res = await request({
        service: "external-signup",
        url: "/partner",
      });
      setPartners(res);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch partners",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function onCreatePartner() {
    try {
      setIsLoadingAddingPartner(true);
      await request({
        service: "external-signup",
        url: "/partner",
        method: "post",
        body: createPartnerData,
      });
      await fetchPartners();
      notification.success({ message: "Added partner" });
      setIsAddingPartner(false);
      setIsLoadingAddingPartner(false);
    } catch (e) {
      notification.warning({
        message: "Failed to add partner",
        description: e.message,
      });
      setIsLoadingAddingPartner(false);
    }
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: Partner) => (
        <div className="flex items-center gap-4">
          <div className="flex flex-col">
            <Link
              className="text-base text-blue-600 truncate"
              to={`${record.id}`}
            >
              {text}
            </Link>
          </div>
          {record.disabled ? <Tag color={"red"}>Inactive</Tag> : null}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "name",
      key: "nameForInspect",
      render: (_name: string, record: Partner) => (
        <div className="flex items-center gap-4">
          <Button
            size={"small"}
            type={"default"}
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            onClick={() => exportItem(record)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center gap-2">
        <PageHeader title={"Partners"} backIcon={false} />
        {isLoading ? null : (
          <Button onClick={() => setIsAddingPartner(true)}>Add Partner</Button>
        )}
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={[UnattachedPartner, ...partners]}
          columns={columns as any}
          rowKey={"id"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.name}
          onClose={() => setViewingItem(null)}
          title={`Partner: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
      {isAddingPartner ? (
        <Modal
          title={"Add Partner"}
          maskClosable={false}
          onCancel={() => setIsAddingPartner(false)}
          open={isAddingPartner}
          onOk={() => onCreatePartner()}
          okText={"Create"}
          okButtonProps={{
            loading: isLoadingAddingPartner,
            disabled:
              !createPartnerData.email ||
              !createPartnerData.name ||
              isLoadingAddingPartner,
          }}
          cancelButtonProps={{ disabled: isLoadingAddingPartner }}
        >
          <div className="flex flex-col gap-2 w-full">
            <Input
              placeholder={"Name"}
              value={createPartnerData.name}
              onChange={(e) =>
                dispatchCreatePartner({ type: "name", payload: e.target.value })
              }
            />
            <Input
              placeholder={"Email"}
              value={createPartnerData.email}
              onChange={(e) =>
                dispatchCreatePartner({
                  type: "email",
                  payload: e.target.value,
                })
              }
            />
            <Select
              placeholder={"Hostnames"}
              value={createPartnerData.hostnames}
              onChange={(values) =>
                dispatchCreatePartner({ type: "hostnames", payload: values })
              }
              mode={"tags"}
              popupClassName={"hidden"}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export const PartnerList = observer(PartnerListRaw);
