import * as React from "react";
import { observer } from "mobx-react-lite";
import { ITrialDomainAccount } from "../../utilities/types";
import { useMain } from "../../../../stores/main";
import { Button, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { JSONModal } from "../../../../jsonModal";
import { Link } from "react-router-dom";
import {
  capitalizeFirstCharacterOfString,
  formatTimestamp,
  formatTimestampToDateFormat,
} from "../../../../utils";
import { ExportItem } from "../../utilities/helper";

interface IProps {
  accounts: ITrialDomainAccount[];
}

const TrialDomainAccountsRaw: React.FC<IProps> = ({ accounts }) => {
  // Uses //
  const main = useMain();
  const [viewingItem, setViewingItem] =
    React.useState<ITrialDomainAccount | null>();

  // Use Effects
  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem]);

  // Initialize //
  const columns = [
    {
      title: "Account ID",
      dataIndex: "id",
      key: "id",
      render: (text: string, record: ITrialDomainAccount) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/account/${record.id}`}
          >
            {record.accountName}
          </Link>
          <span className="text-xs">{text}</span>
        </div>
      ),
    },
    {
      title: "Enabled",
      dataIndex: "enabled",
      key: "enabled",
      render: (boolValue: boolean, _: ITrialDomainAccount) => (
        <span>{capitalizeFirstCharacterOfString(boolValue.toString())}</span>
      ),
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: ITrialDomainAccount) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
    },
    {
      title: "Trial Start",
      dataIndex: "trialStart",
      key: "trialStart",
      render: (text: string) => (
        <span>{formatTimestampToDateFormat(text)}</span>
      ),
    },
    {
      title: "Trial End",
      dataIndex: "trialEnd",
      key: "trialEnd",
      render: (text: string) => (
        <span>{formatTimestampToDateFormat(text)}</span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: string, record: ITrialDomainAccount) => (
        <div className="flex items-center">
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => ExportItem(record, record.accountName)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="w-full">
        <PageHeader
          title={"Accounts"}
          subTitle={"Accounts list of Trial Domain"}
        />
        <Table
          size={"small"}
          dataSource={accounts}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          className="px-6"
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.id}
          title={`Account: ${viewingItem.accountName}`}
          visible={!!viewingItem}
          object={viewingItem}
          onClose={() => setViewingItem(null)}
        />
      ) : null}
    </>
  );
};

export const TrialDomainAccounts = observer(TrialDomainAccountsRaw);
