import { observer } from "mobx-react-lite";
import jsYaml from "js-yaml";
import { Button, Checkbox, Modal, Table, Tag, Tooltip, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import * as React from "react";
import { IncidentStore } from "../../stores/incident";
import { fetchComponents, fetchIncidents } from "./http";
import { Incident, IncidentComponent } from "./types";
import { Link } from "react-router-dom";
import { IconsWithLabel } from "./icons";
import moment, { duration } from "moment-timezone";
import { DatePicker } from "../../Components/antd/DatePicker";

const ComponentListRaw: React.FC = () => {
  const [isExportingReport, setIsExportingReport] = React.useState(false);
  const [isLoadingReport, setIsLoadingReport] = React.useState(false);
  const [reportFrom, setReportFrom] = React.useState(moment().startOf("year"));
  const [reportTo, setReportTo] = React.useState(moment().endOf("year"));
  const [reportComponents, setReportComponents] = React.useState<string[]>([]);
  const [reportOnlyWithRca, setReportOnlyWithRca] = React.useState<boolean>(false);

  React.useEffect(() => {
    process();
    return () => {
      IncidentStore.setIncidents([]);
    };
  }, []);

  async function process() {
    try {
      const components = await fetchComponents();
      IncidentStore.setComponents(components);
      setReportComponents(components.map((c) => c.name));
      const incidents = await fetchIncidents({ daysBack: 7 });
      IncidentStore.setIncidents(incidents);
    } catch (e) {
      IncidentStore.setIncidents([]);
    }
  }

  async function handleReport() {
    try {
      setIsLoadingReport(true);
      // Get all incidents for all selected components in the given time range
      const diffMs = moment().diff(reportFrom);
      const daysBack = Math.ceil(duration(diffMs, "milliseconds").asDays());

      let incidents: Incident[] = [];
      let page = 1;

      // first page
      let incidentsRes = await fetchIncidents({ page: page, daysBack: daysBack });
      incidents = incidents.concat(incidentsRes);

      // fetch pages
      let hasNextPage = true;
      while (hasNextPage) {
        page += 1;
        incidentsRes = await fetchIncidents({ page: page, daysBack: daysBack });
        if (incidentsRes.length < 1) {
          hasNextPage = false;
        }
        incidents = incidents.concat(incidentsRes);
      }

      // filter by range
      incidents = incidents.filter((i) => {
        if (reportOnlyWithRca && !i.rca) {
          return false;
        }
        const detectedTime = moment(i.detectedTime);
        if (reportFrom.isSameOrBefore(detectedTime) && reportTo.isSameOrAfter(detectedTime)) {
          return true;
        }
        return false;
      });

      incidents = incidents.map((i) => {
        const descriptionObject = JSON.parse(i.description || '""');
        const rcaObject = JSON.parse(i.rca || '""');
        return {
          ...i,
          description: extractPlainText(descriptionObject),
          rootCauseAnalysis: extractPlainText(rcaObject),
          rca: undefined,
        };
      });

      const blobData = jsYaml.dump(incidents);
      autoDownloadWithElementA(blobData);

      setIsLoadingReport(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to generate report",
        description: errorMessage,
      });
      setIsLoadingReport(false);
    }
  }

  function autoDownloadWithElementA(blobData: any) {
    const file = new Blob([blobData], { type: `text/yaml` });
    const href = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.style.display = "none";
    a.classList.add("cpln-temp-a");
    a.download = `Root Cause Analysis Report from ${reportFrom.toISOString()} to ${reportTo.toISOString()}.yaml`;
    a.href = href;
    a.click();
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-center">
          <PageHeader title={"Components"} backIcon={false} />
          <Button type={"primary"} onClick={() => setIsExportingReport(true)}>
            Root Cause Analysis Report
          </Button>
        </div>
        <div className="px-8 pb-4">
          <Table
            rowKey={"name"}
            pagination={false}
            size={"small"}
            columns={[
              {
                key: "description",
                dataIndex: "description",
                title: "Component",
                render: (_: string, record: IncidentComponent) => {
                  return (
                    <Link className={"text-blue-500 hover:text-blue-600"} to={`component/${record.name}`}>
                      {record.description}
                    </Link>
                  );
                },
              },
              {
                key: "location",
                dataIndex: "locations",
                title: "Locations",
                render: (_: string, record: IncidentComponent) => {
                  if (!record.locations || record.locations.length < 1) {
                    return <div>Global</div>;
                  }

                  const remaining = record.locations.length - 3;

                  return (
                    <div className={"flex items-center gap-2"}>
                      {record.locations.slice(0, 3).map((l) => (
                        <Tag key={record.name + l}>{l}</Tag>
                      ))}
                      {remaining > 0 ? (
                        <Tooltip
                          className={"text-blue-500"}
                          title={
                            <div>
                              {record.locations.slice(3).map((l) => (
                                <div key={record.name + l} className={"my-1"}>
                                  <Tag>{l}</Tag>
                                </div>
                              ))}
                            </div>
                          }
                        >
                          +{remaining}
                        </Tooltip>
                      ) : null}
                    </div>
                  );
                },
              },
              {
                key: "status",
                dataIndex: "status",
                title: "Status",
                render: (_: string, record: IncidentComponent) => {
                  const IconComponent = IconsWithLabel[record.status];
                  return <IconComponent />;
                },
              },
            ]}
            dataSource={IncidentStore.components}
          />
        </div>
        <div className={"text-xl pl-8"}>{"Incidents from Last 7 Days"}</div>
        <div className={`flex flex-col pl-8 pb-8`}>
          <Table
            pagination={false}
            size={"small"}
            columns={[
              {
                key: "title",
                dataIndex: "title",
                title: "Title",
                render: (title: string, incident: Incident) => (
                  <Link
                    className={"text-blue-500 hover:text-blue-600"}
                    to={`component/${incident.component}/incident/${incident.id}`}
                  >
                    {title}
                  </Link>
                ),
              },
              {
                key: "component",
                dataIndex: "component",
                title: "Component",
                render: (component: string) => (
                  <div>{IncidentStore.components.find((c) => c.name === component)?.description}</div>
                ),
              },
              {
                key: "type",
                dataIndex: "type",
                title: "Type",
                render: (_: string, incident: Incident) => {
                  const IconComponent = IconsWithLabel[incident.type];
                  return <IconComponent />;
                },
              },
              {
                key: "detectedTime",
                dataIndex: "detectedTime",
                title: "Detected Time",
                render: (_: string, incident: Incident) => {
                  return <div>{incident.detectedTime.toISOString()}</div>;
                },
              },
              {
                key: "resolvedTime",
                dataIndex: "resolvedTime",
                title: "Resolved Time",
                render: (_: string, incident: Incident) => {
                  return <div>{incident.resolvedTime?.toISOString() || "Not Resolved"}</div>;
                },
              },
            ]}
            dataSource={IncidentStore.incidents}
          />
        </div>
      </div>
      {isExportingReport ? (
        <Modal
          open={isExportingReport}
          destroyOnClose
          closable={false}
          maskClosable={false}
          title="Root Cause Analysis Report"
          onOk={handleReport}
          onCancel={() => setIsExportingReport(false)}
          okText={"Create Report"}
          okButtonProps={{ loading: isLoadingReport, disabled: isLoadingReport }}
        >
          <div className="mb-4">
            <Checkbox checked={reportOnlyWithRca} onChange={(e) => setReportOnlyWithRca(e.target.checked)}>
              Include only the incidents with Root Cause Analysis content.
            </Checkbox>
          </div>
          <div>Date Range</div>
          <DatePicker.RangePicker
            value={[reportFrom, reportTo]}
            onChange={(dates) => {
              setReportFrom(dates![0]!);
              setReportTo(dates![1]!);
            }}
            allowEmpty={[false, false]}
          />
          <div className="mt-4">
            <div>Components</div>
            <div className="flex flex-col gap-1">
              {IncidentStore.components.map((c) => (
                <Checkbox
                  checked={reportComponents.includes(c.name)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const rc = [...reportComponents];
                      if (!rc.includes(c.name)) {
                        rc.push(c.name);
                      }
                      setReportComponents(rc);
                    } else {
                      const rc = [...reportComponents.filter((_c) => _c !== c.name)];
                      setReportComponents(rc);
                    }
                  }}
                >
                  {c.name}
                </Checkbox>
              ))}
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export const ComponentList = observer(ComponentListRaw);

function extractPlainText(node: any) {
  let plainText = "";

  if (node.root) {
    return extractPlainText(node.root);
  }

  if (!!node.children && Array.isArray(node.children)) {
    node.children.forEach((child) => {
      plainText += extractPlainText(child); // Recursively process children
    });
  }

  if (node.text) {
    plainText += node.text + "\n"; // Add the text and a newline for block separation
  }

  return plainText.trim();
}
