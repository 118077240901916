import { request } from "../../../utils";
import { ITrialDomain } from "./types";

// Functions //
export function ExportItem(data: any, fileName: string) {
  const file = new Blob([JSON.stringify(data, null, 2)], {
    type: "text/json",
  });
  const url = URL.createObjectURL(file);

  const aElement = document.createElement("a");
  aElement.setAttribute("href", url);
  aElement.setAttribute("download", `${fileName}.json`);
  aElement.click();
}

// Async Functions //
export async function FetchTrialDomains(): Promise<ITrialDomain[] | string> {
  const _domains: ITrialDomain[] = [];
  try {
    const response = await request({
      service: "billing-ng",
      url: "/domain/summary",
    });
    for (const domain of response.domains as ITrialDomain[]) {
      if (domain.domainName.length === 0) {
        continue;
      }
      _domains.push(domain);
    }
  } catch (e) {
    console.error(e);

    // @ts-ignore
    let errorMessage = e?.response?.data?.message;
    // @ts-ignore
    if (!errorMessage) errorMessage = e.message;

    return errorMessage;
  }

  return _domains;
}

export async function FetchTrialDomainByName(
  trialDomainName: string
): Promise<ITrialDomain | string | null> {
  const response = await FetchTrialDomains();

  if (typeof response === "string") {
    return response;
  }

  if (response.length === 0) {
    return null;
  }

  for (const domain of response) {
    if (domain.domainName === trialDomainName) {
      return domain;
    }
  }

  return null;
}
