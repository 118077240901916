import React from "react";
import { Observer, observer } from "mobx-react-lite";
import { useMain } from "./stores/main";
import {
  Link,
  Navigate,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Spin } from "antd";
import { SignIn } from "./SignIn";
import Error500 from "./Error500";
import ServiceDown from "./ServiceDown";
import { AdminRoute } from "./Admin";

const AppRaw: React.FC = () => {
  const main = useMain();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [pathname]);

  React.useEffect(() => {
    if (main.isServiceDown) {
      navigate("/system/down");
    } else if (main.isLoggedIn) {
      navigate(main.initialUrl);
    } else if (!main.isLoggedIn) {
      navigate("/system/login");
    }
  }, [main.isLoggedIn, main.isServiceDown]);

  if (
    !main.isServiceDown &&
    (main.isLoadingInitialData || !main.isAuthChecked || main.isAuthenticating)
  ) {
    return (
      <div className="w-screen h-screen flex items-center justify-center">
        <Spin spinning={true} size={"large"} />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Observer>
            {() => {
              return <Navigate to={"/admin"} replace />;
            }}
          </Observer>
        }
      />
      <Route
        path="/system"
        element={<Navigate to={"/system/login"} replace />}
      />
      <Route path="/system/login" element={<SignIn />} />
      <Route path="/system/500" element={<Error500 />} />
      <Route path="/system/down" element={<ServiceDown />} />
      {/* TODO show Admin Panel on page title */}
      <Route path="/admin/*" element={<AdminRoute />} />
      <Route
        path={"*"}
        element={
          <div>
            You are lost, <Link to={"/admin"}>go back</Link>
          </div>
        }
      />
    </Routes>
  );
};

export const App = observer(AppRaw);
