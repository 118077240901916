import { types, Instance } from "mobx-state-tree";

export const DiscoveryModel = types.model({
  firebase: types.model({
    apiKey: types.string,
    authDomain: types.string,
    projectId: types.string,
  }),
  endpoints: types.model({
    console: types.string,
    grafana: types.string,
    "billing-ng": types.string,
  }),
});
export interface DiscoveryMobx extends Instance<typeof DiscoveryModel> {}
export interface Discovery {
  firebase: {
    apiKey: string;
    authDomain: string;
    projectId: string;
  };
  endpoints: {
    console: string;
    grafana: string;
    "billing-ng": string;
  };
}
