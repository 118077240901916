import * as React from "react";
import {
  AccountState,
  AccountStatesResponse,
  ChargeableItem,
  Invoice,
  InvoicesResponse,
  RatePlan,
  TrustLevel,
} from "../../Billing/types";
import { formatCentsToCurrency, request } from "../../../utils";
import { Button, Radio, Select, notification } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "../../../Components/antd/DatePicker";
import { observer } from "mobx-react-lite";
import { ReloadOutlined, ZoomInOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { ColorMap, TimelineInteractionData } from "./Timeline/types";
import {
  EmptyTimelineInteractionData,
  availableColors,
  endInfinityMoment,
  isoToMoment,
  startInfinityMoment,
} from "./Timeline/utils";
import { Timeline } from "./Timeline/timeline";
import { AccountStateContext } from "./state/accountState.context.store";
import { EditStore } from "./state/edit.store";
import { ACCOUNT_STATE_TYPE_OPTIONS, PAYMENT_METHOD_OPTIONS } from "./utils";
import moment from "moment-timezone";
import { JSONModal } from "../../../jsonModal";
import qs from "qs";
import { AccountStateCard } from "../AccountStateCard";

const debug = false;

type RouteParams = "uuid";
const AccountAccStateRaw: React.FC = () => {
  const { uuid } = useParams<RouteParams>();
  const [isLoadingAccount, setIsLoadingAccount] = React.useState(false);
  const [account, setAccount] = React.useState(null as any);
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedStateId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const c = AccountStateContext;
  const e = EditStore;

  const [colorMap, setColorMap] = React.useState<ColorMap>({});
  const [viewAccountState, setViewAccountState] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    fetchAccount();

    c.reset();
    e.reset();
  }, []);

  function assignColor(colorMap: ColorMap, stateId?: string) {
    if (!stateId) {
      return null;
    }
    const usedColors = Object.values(colorMap);
    const value = colorMap[stateId];
    let result = "";
    if (!value) {
      for (const color of availableColors) {
        if (usedColors.includes(color)) {
          continue;
        }
        result = color;
        break;
      }
    } else {
      result = value;
    }
    return result;
  }

  function getColor(stateId?: string) {
    if (!stateId) {
      return "green";
    }

    return colorMap[stateId];
  }

  React.useEffect(() => {
    const _colorMap: ColorMap = JSON.parse(JSON.stringify(colorMap));
    for (const state of c.accountStates) {
      const id = state.id;
      const assignedColor = assignColor(_colorMap, id);
      if (assignedColor) {
        _colorMap[id] = assignedColor;
      }
    }
    for (const stateChange of e.stateChanges) {
      const id = stateChange.before.id;
      const assignedColor = assignColor(_colorMap, id);
      if (assignedColor) {
        _colorMap[id] = assignedColor;
      }
    }
    setColorMap(_colorMap);
  }, [
    e.stateChanges,
    e.stateChanges.length,
    c.accountStates,
    c.accountStates.length,
  ]);

  React.useEffect(() => {
    c.setAccountStates([]);
    c.setInvoices([]);
  }, [c.timestep]);

  const [clickData, setClickData] = React.useState<
    TimelineInteractionData<AccountState>
  >(EmptyTimelineInteractionData);

  function getAccountStateById(
    selectedStateId: string | null
  ): AccountState | null {
    if (!selectedStateId) {
      return null;
    }
    let accountState = AccountStateContext.accountStates.find(
      (accountState) => accountState.id === selectedStateId
    )!;
    accountState = JSON.parse(JSON.stringify(accountState));
    // @ts-ignore
    accountState.ratePlan = {
      id: accountState.ratePlan.id,
      name: accountState.ratePlan.name,
    };
    return accountState;
  }

  React.useEffect(() => {
    if (!e.selectedState) {
      return;
    }

    e.setPaymentMethod(e.selectedState.paymentMethodType);
    e.setRatePlanId(e.selectedState.ratePlan.id);
    e.setTrustLevelId(e.selectedState.trustLevel.id);
    e.setType(e.selectedState.type);
  }, [e.selectedState]);

  async function fetchAccount() {
    try {
      setIsLoadingAccount(true);
      const res = await request({
        service: "billing-ng",
        url: `/account/${uuid}`,
      });
      const selectedState = c.accountStates.find(
        (s) => s.id === selectedStateId
      );
      if (selectedStateId && !!selectedState) {
        c.setMode("edit");
        e.setSelectedStateId(selectedStateId.toString());
      }
      setAccount(res);
      setIsLoadingAccount(false);
    } catch (e) {
      setIsLoadingAccount(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch account",
        description: errorMessage,
      });
      navigate("/admin/account");
    }
  }

  function handlePlanClick(data: TimelineInteractionData<any>) {
    if (e.selectionStartMoment && e.selectionEndMoment) {
      return;
    }

    if (data.snappedTick === null) {
      return;
    }

    if (!e.selectionStartMoment) {
      e.setSelectionStartMoment(data.snappedTick);
      return;
    }

    if (data.snappedTick.isBefore(e.selectionStartMoment)) {
      e.setSelectionEndMoment(e.selectionStartMoment);
      e.setSelectionStartMoment(data.snappedTick);
      return;
    }

    e.setSelectionEndMoment(data.snappedTick);
  }

  React.useEffect(() => {
    e.setHasFetchedPreview(false);
    e.setStateChanges([]);
    e.setPreviewError("");
  }, [e.trustLevelId, e.ratePlanId, e.paymentMethod, e.type]);

  React.useEffect(() => {
    prepare();
  }, []);

  React.useEffect(() => {
    getStatesInvoices();
  }, [c.startISO, c.endISO]);

  async function prepare() {
    try {
      c.setPageDataError("");
      c.setPageDataState("fetching");

      const statesInvoicesPromise = getStatesInvoices();

      // get chargable items
      const chargableItemsPromise = request<ChargeableItem[]>({
        service: "billing-ng",
        url: `/chargeable_items`,
      });

      // get trust levels
      const trustLevelsPromise = request<TrustLevel[]>({
        service: "billing-ng",
        url: `/trust_levels`,
      });

      // get rate plans
      const ratePlansPromise = request<RatePlan[]>({
        service: "billing-ng",
        url: `/rate_plan`,
      });

      const trustLevelsResponse = await trustLevelsPromise;
      c.setTrustLevels(trustLevelsResponse);
      const ratePlansResponse = await ratePlansPromise;
      c.setRatePlans(ratePlansResponse);
      const chargableItemsResponse = await chargableItemsPromise;
      c.setChargables(chargableItemsResponse);
      await statesInvoicesPromise;

      c.setPageDataState("done");
    } catch (error) {
      c.setPageDataState("done");
      c.setPageDataError(error.message);
    }
  }

  async function getStatesInvoices(skipInvoices: boolean = false) {
    try {
      c.setPageDataError("");
      c.setIsLoadingStates(true);
      c.setIsLoadingInvoices(true);
      // get account states
      const accountStatesPromise = request<AccountStatesResponse>({
        service: "billing-ng",
        url: `/account/${uuid}/states?from=${c.startISOBuffered}&to=${c.endISOBuffered}`,
      });

      let invoicesPromise: Promise<InvoicesResponse> | null = null;
      if (!skipInvoices) {
        // get invoices
        invoicesPromise = request<InvoicesResponse>({
          service: "billing-ng",
          url: `/account/${uuid}/charges/invoices?start=${c.startMoment
            .clone()
            .startOf("hour")
            .toISOString()}&end=${c.endMoment
            .clone()
            .startOf("hour")
            .toISOString()}`,
        });
      }
      const accountStatesResponse = await accountStatesPromise;
      c.setAccountStates(accountStatesResponse.states || []);
      if (invoicesPromise) {
        const invoicesResponse = await invoicesPromise;
        c.setInvoices(invoicesResponse.invoices || []);
      }
      c.setIsLoadingStates(false);
      c.setIsLoadingInvoices(false);
    } catch (error) {
      c.setIsLoadingStates(false);
      c.setIsLoadingInvoices(false);
      c.setPageDataError(error.message);
    }
  }

  async function handleNewState({ preview = true }: { preview: boolean }) {
    e.setHasFetchedPreview(false);
    try {
      const body: any = {
        ...e.requestBody,
        dryRun: preview,
      };

      let invoiceOverlapsToday = false;
      const today = moment();

      for (let invoice of c.invoices) {
        if (!invoice?.endTime) {
          continue;
        }

        const endTime = isoToMoment(invoice.endTime, "end");

        if (endTime.isSame(today, "day")) {
          const startISOTime = isoToMoment(e.startISO, "start");
          if (endTime.isSame(startISOTime, "day")) {
            invoiceOverlapsToday = true;
            break;
          }
        }
      }

      if (e.startISO) {
        body.startTime = e.startISO;
      }
      if (e.endISO) {
        body.endTime = e.endISO;
      }
      if (invoiceOverlapsToday) {
        body.startTime = moment().add(1, "days").startOf("day").toISOString();
      }
      const res = await request({
        service: "billing-ng",
        url: `/account/${uuid}/states`,
        method: "put",
        body: body,
      });

      if (preview) {
        e.setPreviewError("");
        e.setStateChanges(res.changes);
      } else {
        c.setMode("edit");
        e.setSelectedStateId(null);
        await getStatesInvoices(true);
        e.setSelectionStartMoment(null);
        e.setSelectionEndMoment(null);
      }
    } catch (error) {
      let errorMessage = error.response?.data?.error;
      if (!errorMessage) {
        errorMessage = error.message;
      }
      if (preview) {
        e.setPreviewError(errorMessage);
      }
    }
    if (preview) {
      e.setHasFetchedPreview(true);
    }
  }

  if (c.pageDataState === "fetching") {
    return <div className="p-16">Loading...</div>;
  }

  if (c.pageDataState === "done" && !!c.pageDataError) {
    return (
      <div>
        <div>Error: {c.pageDataError}</div>
        <button onClick={prepare}>try again</button>
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="px-4 w-full">
        <div
          className={`flex items-center gap-4 text-sm mb-2 ${
            isLoadingAccount ? "invisible" : ""
          }`}
        >
          {!isLoadingAccount && !account?.accountName ? null : (
            <div>
              <div className="font-semibold">Account Name</div>
              <div>{account?.accountName || ""}</div>
            </div>
          )}
          {!isLoadingAccount && !account?.fullName ? null : (
            <div>
              <div className="font-semibold">Full Name</div>
              <div>{account?.fullName || ""}</div>
            </div>
          )}
          {!isLoadingAccount && !account?.extraInfo?.company ? null : (
            <div>
              <div className="font-semibold">Company Name</div>
              <div>{account?.extraInfo?.company || ""}</div>
            </div>
          )}
          {isLoadingAccount ? <div>Loading Account</div> : null}
        </div>
        <div className={`flex gap-4 pr-8 mb-4`}>
          <div>
            <div className={`text-sm`}>Mode</div>
            <Radio.Group
              options={[
                { label: "Edit", value: "edit" },
                { label: "Plan", value: "plan" },
              ]}
              onChange={(e) => {
                c.setMode(e.target.value);
              }}
              value={c.mode}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div>
            <div className={`text-sm`}>Timeline Start</div>
            <DatePicker
              value={c.startMoment}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                c.setStartMoment(value);
              }}
            />
          </div>
          <div>
            <div className={`text-sm`}>Timeline End</div>
            <DatePicker
              value={c.endMoment}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                c.setEndMoment(value);
              }}
            />
          </div>
          <div>
            <div className={`text-sm`}>Timestep</div>
            <Radio.Group
              options={[
                { label: "Day", value: "day" },
                { label: "Month", value: "month" },
              ]}
              onChange={(e) => c.setTimestep(e.target.value)}
              value={c.timestep}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
          <div>
            <div className={`text-sm invisible`}>Refresh</div>
            <Button
              icon={<ReloadOutlined />}
              className={`self-end`}
              onClick={() => getStatesInvoices()}
              loading={c.isLoadingInvoices || c.isLoadingStates}
              disabled={c.isLoadingInvoices || c.isLoadingStates}
            />
          </div>
          <div className="flex-grow" />
          {c.currentAccountState ? (
            <AccountStateCard
              label={"Current"}
              state={c.currentAccountState}
              account={account}
              onEdit={() => {
                c.setMode("edit");
                e.setSelectedStateId(c.currentAccountState?.id);
              }}
            />
          ) : null}
        </div>
        {/* Container for infinities and timeline */}
        <Timeline<AccountState>
          title={"Current Account States"}
          start={c.startMoment}
          end={c.endMoment}
          timestep={c.timestep}
          rangeGroups={[
            {
              name: "accountState",
              ranges: c.accountStates.map((state, idx) => ({
                start: isoToMoment(state.startTime, "start"),
                end: isoToMoment(state.endTime, "end"),
                item: state,
                color: getColor(state.id),
              })),
              span: 24,
              render: (rangeGroup, rangeGroupIndex, range, rangeIndex) => {
                const isBeingEdited =
                  c.mode === "edit" && e.selectedStateId === range.item.id;
                return (
                  <div
                    className={`flex flex-col text-xs ${
                      isBeingEdited ? "font-semibold" : ""
                    }`}
                  >
                    <div className="flex">
                      <div className="flex flex-col">
                        <span>Trust Level</span>
                        <span>Rate Plan</span>
                        <span>Payment</span>
                        <span>Type</span>
                      </div>
                      <div className="flex flex-col mr-2">
                        <span>:</span>
                        <span>:</span>
                        <span>:</span>
                        <span>:</span>
                      </div>
                      <div className="flex flex-col">
                        <span>{range.item.trustLevel.name || "-"}</span>
                        <span>{range.item.ratePlan.name || "-"}</span>
                        <span>{range.item.paymentMethodType || "-"}</span>
                        <span>{range.item.type || "-"}</span>
                      </div>
                    </div>
                  </div>
                );
              },
              getContainerClassName: (
                rangeGroup,
                rangeGroupIndex,
                range,
                rangeIndex,
                hoverData
              ) => {
                const isHovered =
                  hoverData.rangeGroup?.name === rangeGroup.name &&
                  hoverData.rangeIndex === rangeIndex;

                const endInfinite = range.end.isSame(endInfinityMoment);
                const startInfinite = range.start.isSame(startInfinityMoment);

                const isBeingEdited =
                  c.mode === "edit" && e.selectedStateId === range.item.id;

                let result = "";
                result += isBeingEdited
                  ? startInfinite
                    ? "border-4 "
                    : endInfinite
                    ? "border-4 "
                    : "border-4 "
                  : "";
                result +=
                  isBeingEdited || isHovered
                    ? `border-${range.color || "gray"}-400 `
                    : "border-transparent ";
                return result;
              },
              getContainerStartInfinityClassName: (
                rangeGroup,
                rangeGroupIndex,
                range,
                rangeIndex,
                hoverData
              ) => {
                const isHovered =
                  hoverData.rangeGroup?.name === rangeGroup.name &&
                  hoverData.rangeIndex === rangeIndex;
                const isBeingEdited =
                  c.mode === "edit" && e.selectedStateId === range.item.id;
                const startInfinite = range.start.isSame(startInfinityMoment);

                let result = "";
                result += isBeingEdited
                  ? startInfinite
                    ? "border-4 "
                    : "border-4 "
                  : "";
                result +=
                  isBeingEdited || isHovered
                    ? `border-${range.color || "gray"}-400 `
                    : "border-transparent ";
                return result;
              },
              getContainerEndInfinityClassName: (
                rangeGroup,
                rangeGroupIndex,
                range,
                rangeIndex,
                hoverData
              ) => {
                const isHovered =
                  hoverData.rangeGroup?.name === rangeGroup.name &&
                  hoverData.rangeIndex === rangeIndex;
                const isBeingEdited =
                  c.mode === "edit" && e.selectedStateId === range.item.id;
                const endInfinite = range.end.isSame(endInfinityMoment);

                let result = "";
                result += isBeingEdited
                  ? endInfinite
                    ? "border-4 "
                    : "border-4 "
                  : "";
                result +=
                  isBeingEdited || isHovered
                    ? `border-${range.color || "gray"}-400 `
                    : "border-transparent ";
                return result;
              },
            },
            {
              name: "invoice",
              ranges: c.invoices.map((i, idx) => ({
                start: isoToMoment(i.startTime, "start"),
                end: isoToMoment(i.endTime, "end"),
                item: i as any,
                color: "red",
              })),
              render: (rangeGroup, rangeGroupIndex, range) => {
                const item = range.item as unknown as Invoice;
                return (
                  <div className="flex flex-col text-xs">
                    <span>{formatCentsToCurrency(item.totalCharge)}</span>
                  </div>
                );
              },
              getContainerClassName: (
                rangeGroup,
                rangeGroupIndex,
                range,
                rangeIndex,
                hoverData
              ) => {
                const isHovered =
                  hoverData.rangeGroup?.name === rangeGroup.name &&
                  hoverData.rangeIndex === rangeIndex;
                if (isHovered) {
                  return `border-${range.color || "gray"}-400`;
                }
                return "border-transparent";
              },
            },
          ]}
          interactable
          onClick={(data) => {
            setClickData(data);
            e.setSelectedStateId(data.range?.item.id);
          }}
        />
        {c.mode === "plan" ? (
          <Timeline<any>
            title={"Plan New Account State"}
            start={c.startMoment}
            end={c.endMoment}
            timestep={c.timestep}
            span={16}
            rangeGroups={[
              {
                name: "selection",
                ranges:
                  e.selectionStartMoment && e.selectionEndMoment
                    ? [
                        {
                          start: e.selectionStartMoment,
                          end: e.selectionEndMoment,
                          item: {},
                          color: "emerald",
                        },
                      ]
                    : [],
                span: 16,
                render: (rangeGroup, rangeGroupIndex, range, rangeIndex) => {
                  return (
                    <div className={`flex flex-col text-xs`}>
                      <span>New State Time Range Selection</span>
                    </div>
                  );
                },
              },
            ]}
            customTimeticks={
              (e.selectionStartMoment && !e.selectionEndMoment) ||
              (e.selectionStartMoment && !e.selectionEndMoment)
                ? [{ tick: e.selectionStartMoment || e.selectionEndMoment }]
                : []
            }
            onClick={handlePlanClick}
          />
        ) : null}
        {c.mode === "edit" || c.mode === "plan" ? (
          <div>
            <div className="text-lg font-semibold">
              {c.mode === "edit" ? (
                e.selectedStateId ? (
                  <div className="flex items-center gap-2">
                    {e.selectedStateId === c.currentAccountState?.id
                      ? "Editing Current State"
                      : `Editing State with Id ${e.selectedStateId}`}
                    <Button
                      icon={<ZoomInOutlined />}
                      onClick={() => setViewAccountState((prev) => !prev)}
                      size="small"
                    />
                  </div>
                ) : (
                  "Edit Mode"
                )
              ) : (
                "Planning New State"
              )}
            </div>
            {c.mode === "edit" && !e.selectedStateId ? (
              <div>
                Select a state from timeline above to edit, or{" "}
                <Button
                  type={"primary"}
                  size={"small"}
                  onClick={() =>
                    e.setSelectedStateId(c.currentAccountState?.id)
                  }
                >
                  Click here
                </Button>{" "}
                to edit current state.
              </div>
            ) : null}
            {(c.mode === "edit" && e.selectedStateId) || c.mode === "plan" ? (
              <div className="mb-4 flex items-center gap-2">
                <div>
                  <div className="font-semibold">Trust Level</div>
                  <Select
                    style={{ width: 200 }}
                    value={e.trustLevelId}
                    onChange={(value) => e.setTrustLevelId(value)}
                    options={c.trustLevels.map((t) => ({
                      label:
                        t.name +
                        " ($" +
                        (t.accumulatedChargeTrigger / 100).toFixed() +
                        ")",
                      value: t.id,
                    }))}
                  />
                </div>
                <div>
                  <div className="font-semibold">Rate Plan</div>
                  <Select
                    style={{ width: 200 }}
                    value={e.ratePlanId}
                    onChange={(value) => e.setRatePlanId(value)}
                    options={c.ratePlans.map((r) => ({
                      label: r.name,
                      value: r.id,
                    }))}
                  />
                </div>
                <div>
                  <div className="font-semibold">Payment Method</div>
                  <Select
                    style={{ width: 200 }}
                    value={e.paymentMethod}
                    onChange={(value) => e.setPaymentMethod(value)}
                    options={PAYMENT_METHOD_OPTIONS}
                  />
                </div>
                <div>
                  <div className="font-semibold">Type</div>
                  <Select
                    style={{ width: 200 }}
                    value={e.type}
                    onChange={(value) => e.setType(value)}
                    options={ACCOUNT_STATE_TYPE_OPTIONS}
                  />
                </div>
                <div>
                  <div className="font-semibold">Start Time</div>
                  <div className="h-8 flex items-center text-xs">
                    <span>
                      {!e.startMoment.isSame(startInfinityMoment) ? (
                        e.startMoment.format("DD MMM 'YY")
                      ) : (
                        <span className={`text-lg`}>- &infin;</span>
                      )}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="font-semibold">End Time</div>
                  <div className="h-8 flex items-center text-xs">
                    {!e.endMoment.isSame(endInfinityMoment) ? (
                      e.endMoment.format("DD MMM 'YY")
                    ) : (
                      <span className={`text-lg`}>+ &infin;</span>
                    )}
                  </div>
                </div>
                <div>
                  <div className="font-semibold invisible">Cancel</div>
                  <Button
                    size={"small"}
                    onClick={() => {
                      e.reset();
                    }}
                    className="w-full"
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <div className="font-semibold invisible">
                    {c.mode === "edit" ||
                    (e.hasFetchedPreview && !e.previewError)
                      ? "Confirm"
                      : "Preview"}
                  </div>
                  <Button
                    size={"small"}
                    disabled={!e.canPreview}
                    onClick={() =>
                      handleNewState({
                        preview:
                          c.mode !== "edit" &&
                          (!e.hasFetchedPreview || !!e.previewError),
                      })
                    }
                    type={"primary"}
                    className="w-full"
                  >
                    {c.mode === "edit" ||
                    (e.hasFetchedPreview && !e.previewError)
                      ? "Confirm"
                      : "Preview"}
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {e.hasFetchedPreview ? (
          <>
            <Timeline<AccountState>
              hideShades
              title={"Preview Account State Changes"}
              start={c.startMoment}
              end={c.endMoment}
              timestep={c.timestep}
              rangeGroups={[
                {
                  // default update create
                  name: "accountState",
                  ranges: e.previewAccountStates
                    .filter((state) => (state as any).action !== "delete")
                    .map((state) => ({
                      start: isoToMoment(state.startTime, "start"),
                      end: isoToMoment(state.endTime, "end"),
                      item: state,
                      color: getColor(state.id),
                    })),
                  span: 24,
                  render: (rangeGroup, rangeGroupIndex, range, rangeIndex) => {
                    return (
                      <div>
                        <div>Action: {(range as any).action}</div>
                        <div className="flex text-xs">
                          <div className="flex flex-col">
                            <span>Rate Plan</span>
                            <span>Trust Level</span>
                            <span>Payment</span>
                            <span>Type</span>
                          </div>
                          <div className="flex flex-col mr-2">
                            <span>:</span>
                            <span>:</span>
                            <span>:</span>
                            <span>:</span>
                          </div>
                          <div className="flex flex-col">
                            <span>{range.item.ratePlan.name || "-"}</span>
                            <span>{range.item.trustLevel.name || "-"}</span>
                            <span>{range.item.paymentMethodType || "-"}</span>
                            <span>{range.item.type || "-"}</span>
                          </div>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  // deleted
                  name: "accountState",
                  ranges: e.previewAccountStates
                    .filter((state) => (state as any).action === "delete")
                    .map((state, idx) => ({
                      start: isoToMoment(state.startTime, "start"),
                      end: isoToMoment(state.endTime, "end"),
                      item: state,
                      color: getColor(state.id),
                    })),
                  render: (rangeGroup, rangeGroupIndex, range, rangeIndex) => {
                    return <div>Deleted</div>;
                  },
                },
              ]}
              error={e.previewError}
            />
            {e.previewError ? null : (
              <div>
                <div className="text-lg">Summary</div>
                <div className="">
                  {e.stateChanges.map((stateChange, index) => (
                    <div
                      key={stateChange.before.id || uuidv4()}
                      className={`flex items-center gap-1 ${
                        index !== 0 ? "border-t" : ""
                      }`}
                    >
                      <div
                        className={`bg-${getColor(
                          stateChange.before.id
                        )}-200 p-1 rounded`}
                      >
                        {stateChange.type}
                      </div>
                      <div>
                        {stateChange.accountState.startTime
                          ? moment(stateChange.accountState.startTime).format()
                          : "Infinity"}
                        {` - `}
                        {stateChange.accountState.endTime
                          ? moment(stateChange.accountState.endTime).format()
                          : "Infinity"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
      {viewAccountState ? (
        <JSONModal
          title="Account State"
          filename={`${
            getAccountStateById(e.selectedStateId)?.startTime || "Infinity"
          }-${getAccountStateById(e.selectedStateId)?.endTime || "Infinity"}`}
          visible={viewAccountState}
          onClose={() => setViewAccountState(false)}
          object={getAccountStateById(e.selectedStateId)}
        />
      ) : null}
      {debug ? (
        <div className="text-xs">
          <div>Debug</div>
          <pre>{JSON.stringify(colorMap, null, 2)}</pre>
          <div className="mb-1">
            <div>Selection Start</div>
            <div>
              {e.selectionStartMoment
                ? e.selectionStartMoment.toISOString()
                : "-"}
              <span> | </span>
              {e.selectionStartMoment
                ? e.selectionStartMoment.format("Z")
                : "-"}
            </div>
          </div>
          <div className="mb-1">
            <div>Selection End</div>
            <div>
              {e.selectionEndMoment ? e.selectionEndMoment.toISOString() : "-"}
              <span> | </span>
              {e.selectionEndMoment ? e.selectionEndMoment.format("Z") : "-"}
            </div>
          </div>
          <div className="mb-1">
            <div>Start</div>
            <div>
              {c.startMoment.toISOString()}
              <span> | </span>
              {c.startMoment.format("Z")}
            </div>
          </div>
          <div className="mb-1">
            <div>End</div>
            <div>
              {c.endMoment.toISOString()}
              <span> | </span>
              {c.endMoment.format("Z")}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export const AccountAccState = observer(AccountAccStateRaw);
