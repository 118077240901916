import * as React from "react";
import { request } from "../../utils";
import { observer } from "mobx-react-lite";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { isEqual } from "lodash";
import { Input, Button, Form, notification, Modal } from "antd";

interface Props {
  name: string;
  ipRanges: string[];
  onClose: () => void;
  onSubmit: () => void;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24, offset: 1 },
    sm: { span: 20, offset: 1 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 1 },
    sm: { span: 20, offset: 5 },
  },
};

const LocationEditRaw: React.FC<Props> = ({
  name,
  ipRanges,
  onClose,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [form] = Form.useForm();

  async function handleSubmit(_values) {
    try {
      setIsLoading(true);
      const state = getFormState();
      await request({
        url: `/admin/locations/cust/${name}`,
        method: "post",
        body: { data: { ipRanges: state.ipRanges } },
      });
      notification.success({ message: "Success" });
      onSubmit();
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to update",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function getFormState() {
    const formState = form.getFieldsValue(["ipRanges"]);
    if (formState) {
      formState.ipRanges = (formState.ipRanges || []).filter(Boolean);
    }
    return formState || {};
  }

  function handleCancel() {
    onClose();
    form.resetFields();
  }

  function calculateIsDirty() {
    setIsDirty(!isEqual(getFormState(), { ipRanges }));
  }

  return (
    <Modal
      title={`Edit location (${name})`}
      open={true}
      maskClosable={false}
      onOk={form.submit}
      onCancel={handleCancel}
      okText={"Confirm"}
      okButtonProps={{ disabled: !isDirty || isLoading, loading: isLoading }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form
        form={form}
        name="location-edit-form"
        style={{ marginTop: "24px" }}
        onValuesChange={() => calculateIsDirty()}
        initialValues={{ ipRanges }}
        {...formItemLayoutWithOutLabel}
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.List name="ipRanges">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...(index === 0
                    ? formItemLayout
                    : formItemLayoutWithOutLabel)}
                  label={index === 0 ? "IP Ranges" : ""}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "Please input ip range or delete this field.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="ip range"
                      style={{ width: "93%", marginRight: 10 }}
                    />
                  </Form.Item>
                  <MinusCircleOutlined
                    className="dynamic-delete-button"
                    onClick={() => remove(field.name)}
                  />
                </Form.Item>
              ))}
              <Form.Item
                label={fields.length === 0 ? "IP Ranges" : ""}
                {...(fields.length === 0
                  ? formItemLayout
                  : formItemLayoutWithOutLabel)}
              >
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%" }}
                  icon={<PlusOutlined />}
                >
                  Add IP Range
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export const LocationEdit = observer(LocationEditRaw);
