import * as React from "react";
import { Alert, Button, Menu, notification, Spin } from "antd";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { CookieReferralList } from "./CookieReferralList";
import { request } from "../../utils";
import { UnattachedPartner } from "./utils";

const UnattachedPartnerDetailRaw: React.FC = () => {
  const navigate = useNavigate();

  const [error, setError] = React.useState("");
  const [partner, setPartner] = React.useState(UnattachedPartner);
  const [isPageReady, setIsPageReady] = React.useState(false);

  React.useEffect(() => {
    fetchUnattachedCookieReferrals().finally(() => {
      setIsPageReady(true);
    });
  }, []);

  async function fetchUnattachedCookieReferrals() {
    try {
      const cookieReferralsRes = await request({
        service: "external-signup",
        url: `/cookiereferral`,
      });
      const _partner = JSON.parse(JSON.stringify(partner));
      _partner.cookieReferrals = cookieReferralsRes;
      setPartner(_partner);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      notification.warning({
        message: "Failed to fetch partner and its referrals",
        description: errorMessage,
      });
      navigate("/admin/external-signup");
    }
  }

  if (!isPageReady) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <Spin />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
        <Alert type={"error"} message={error} />
        <Button onClick={() => navigate(`/admin/external-signup`)}>
          Go Back
        </Button>
      </div>
    );
  }

  return (
    <div className="flex p-8">
      <Menu theme={"light"} selectedKeys={["cookiereferrals"]}>
        <Menu.Item key={"cookiereferrals"}>
          <NavLink to={`cookiereferrals`}>Cookie Refs</NavLink>
        </Menu.Item>
      </Menu>
      <Routes>
        <Route
          element={
            <CookieReferralList
              partner={partner}
              fetchPartner={fetchUnattachedCookieReferrals}
            />
          }
        />
        <Route index element={<Navigate to={`info`} replace />} />
      </Routes>
    </div>
  );
};

export const UnattachedPartnerDetail = observer(UnattachedPartnerDetailRaw);
