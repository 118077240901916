import * as React from "react";
import { Invoice, Timestep } from "../../../Billing/types";
import { Alert, Tooltip } from "antd";
import clsx from "clsx";
import {
  TimelineInteractionCallback,
  TimelineInteractionData,
  TimelineRange,
  TimelineRangeGroup,
} from "./types";
import {
  EmptyTimelineInteractionData,
  availableColors,
  colorToRgbMap,
  endInfinityMoment,
  formatByTimestep,
  mapRange,
  startInfinityMoment,
} from "./utils";
import { getBuffer } from "../utils";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import qs from "qs";

const MAX_TIMELINE_SPAN = 32;

interface Props<T> {
  title: string;
  start: moment.Moment;
  end: moment.Moment;
  timestep: Timestep;
  rangeGroups: TimelineRangeGroup<T>[];
  customTimeticks?: { tick: moment.Moment; label?: string }[];
  interactable?: boolean;
  onClick?: TimelineInteractionCallback<T>;
  onHover?: TimelineInteractionCallback<T>;
  error?: string;
  span?: 8 | 16 | 24 | 32;
  debug?: boolean;
  hideShades?: boolean;
}
export function Timeline<T>({
  title,
  start,
  end,
  timestep,
  rangeGroups,
  customTimeticks = [],
  interactable = false,
  onClick,
  onHover,
  error,
  span = 32,
  debug = false,
  hideShades = false,
}: Props<T>) {
  const [isValidated, setIsValidated] = React.useState(false);
  const [validationError, setValidationError] = React.useState(error);

  const { uuid } = useParams();
  const navigate = useNavigate();
  const buffer = getBuffer(timestep);
  const startBuffered = start.clone().subtract(buffer);
  const startBufferedMs = startBuffered.valueOf();
  const endBuffered = end.clone().add(buffer);
  const endBufferedMs = endBuffered.valueOf();

  const ref = React.useRef<HTMLDivElement>(null as any);
  const width = ref.current?.getClientRects()[0]?.width || 200;

  const [isHoveringStartInfinity, setIsHoveringStartInfinity] =
    React.useState(false);
  const [isHoveringEndInfinity, setIsHoveringEndInfinity] =
    React.useState(false);

  const timeticks = getTimeticks();
  function getTimeticks() {
    const timeticks: moment.Moment[] = [];
    const _start = start.clone().subtract(buffer);
    const _end = end.clone().add(buffer);
    let current = _start.clone().add(1, timestep).startOf(timestep);
    let isDone = false;
    while (!isDone) {
      if (current.isAfter(_end)) {
        isDone = true;
        break;
      }
      timeticks.push(current.clone());
      current.add(1, timestep);
    }
    return timeticks;
  }

  const [mousePosition, setMousePosition] = React.useState<{
    x: number;
    y: number;
  }>({ x: -1, y: -1 });
  function onTimelineMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    const target = e.target;
    const rect = (target as HTMLDivElement).getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setMousePosition({ x, y });
  }

  React.useEffect(() => {
    let _validationError = "";

    if (error) {
      _validationError = error;
    } else {
      let hTotal = 0;
      for (const rangeGroup of rangeGroups) {
        hTotal += rangeGroup.span || 8;
      }
      if (hTotal > 32) {
        _validationError = "Span total cannot exceed 32";
      }
    }
    setValidationError(_validationError);
    setIsValidated(true);
  }, [error]);

  function tickToPosition(tick: moment.Moment | null) {
    if (!tick) {
      return 0;
    }

    const _tick = tick.valueOf();
    return mapRange(_tick, startBufferedMs, endBufferedMs, 0, width);
  }

  function positionToTick(x: number): moment.Moment | null {
    if (x < 0) {
      return null;
    }
    const tick = mapRange(x, 0, width, startBufferedMs, endBufferedMs);
    return moment(new Date(tick));
  }

  function snapThreshold() {
    switch (timestep) {
      case "day":
        return 12;
      case "month":
        return 15;
    }
  }

  function getSnappedIndex() {
    for (const timetickIndexStr in timeticks) {
      const timetickIndex = Number(timetickIndexStr);
      const timetick = timeticks[timetickIndex];
      const isMousePositionSnapped =
        Math.abs(
          timetick.diff(
            positionToTick(mousePosition.x),
            timestep === "day" ? "hour" : "day"
          )
        ) < snapThreshold();
      if (isMousePositionSnapped) {
        return timetickIndex;
      }
    }
    return -1;
  }

  const snappedIndex = getSnappedIndex();
  const mouseTick = positionToTick(mousePosition.x);
  const mouseTimelinePosition = tickToPosition(mouseTick);

  const [hoverData, setHoverData] = React.useState<TimelineInteractionData<T>>(
    EmptyTimelineInteractionData
  );
  const [clickData, setClickData] = React.useState<TimelineInteractionData<T>>(
    EmptyTimelineInteractionData
  );

  React.useEffect(() => {
    const data = getTimelineData();
    setHoverData(data);

    if (onHover) {
      onHover(data);
    }
  }, [isHoveringStartInfinity, isHoveringEndInfinity, mousePosition]);

  function onTimelineMouseDown() {
    const data = getTimelineData();

    if (data.rangeGroup?.name === "invoice") {
      const invoiceId = (data.range?.item as Invoice | undefined)?.id;
      if (invoiceId) {
        navigate({
          pathname: `/admin/account/${uuid}/invoice`,
          search: qs.stringify({
            selectedId: invoiceId,
            start: start.toISOString(),
            end: end.toISOString(),
          }),
        });
      }
      return;
    }

    setClickData(data);
    if (onClick) {
      onClick(data);
    }
  }

  function getTimelineData(): TimelineInteractionData<T> {
    if (
      !isHoveringStartInfinity &&
      !isHoveringEndInfinity &&
      mousePosition.x < 0
    ) {
      return EmptyTimelineInteractionData;
    }

    let hoveredRangeGroupIndex: number | null = 0;
    let hoveredRangeGroup: TimelineRangeGroup<T> | null = null;

    let hoveredRangeIndex: number | null = null;
    let hoveredRange: TimelineRange<T> | null = null;

    if (isHoveringStartInfinity || isHoveringEndInfinity) {
      const snappedTick = isHoveringStartInfinity
        ? startInfinityMoment
        : endInfinityMoment;
      const trailingTick = snappedTick;

      if (
        rangeGroups[hoveredRangeGroupIndex].ranges.find(
          (range) =>
            range.start.isSame(snappedTick) || range.end.isSame(snappedTick)
        )
      ) {
        hoveredRangeGroup = rangeGroups[hoveredRangeGroupIndex];
      } else {
        hoveredRangeGroupIndex = null;
        hoveredRangeGroup = null;
      }

      if (hoveredRangeGroup) {
        for (const rangeIndexStr in hoveredRangeGroup.ranges) {
          const rangeIndex = Number(rangeIndexStr);
          const range = hoveredRangeGroup.ranges[rangeIndex];
          if (
            range.start.isSame(snappedTick) ||
            range.end.isSame(snappedTick)
          ) {
            hoveredRangeIndex = rangeIndex;
            hoveredRange = range;
            break;
          }
        }
      }

      return {
        rangeGroup: hoveredRangeGroup,
        rangeGroupIndex: hoveredRangeGroupIndex,
        range: hoveredRange,
        rangeIndex: hoveredRangeIndex,
        trailingTick,
        snappedTick,
      };
    }

    const snappedTick = snappedIndex < 0 ? null : timeticks[snappedIndex];
    const trailingTick = mouseTick;

    hoveredRangeGroupIndex = getHoveredRangeGroupIndex();
    if (hoveredRangeGroupIndex >= 0) {
      if (
        trailingTick &&
        rangeGroups[hoveredRangeGroupIndex].ranges.find((range) =>
          trailingTick.isBetween(range.start, range.end)
        )
      ) {
        hoveredRangeGroup = rangeGroups[hoveredRangeGroupIndex];
      } else {
        hoveredRangeGroupIndex = null;
        hoveredRangeGroup = null;
      }
    }

    if (hoveredRangeGroup) {
      for (const rangeIndexStr in hoveredRangeGroup.ranges) {
        const rangeIndex = Number(rangeIndexStr);
        const range = hoveredRangeGroup.ranges[rangeIndex];
        if (trailingTick) {
          if (trailingTick.isBetween(range.start, range.end)) {
            hoveredRangeIndex = rangeIndex;
            hoveredRange = range;
            break;
          }
        }
      }
    }

    return {
      rangeGroup: hoveredRangeGroup,
      rangeGroupIndex: hoveredRangeGroupIndex,
      range: hoveredRange,
      rangeIndex: hoveredRangeIndex,
      trailingTick,
      snappedTick,
    };
  }

  function getColorRgb(color: string | undefined) {
    if (color && availableColors.includes(color)) {
      return colorToRgbMap[color];
    }
    return colorToRgbMap[0];
  }

  function getHoveredRangeGroupIndex() {
    const verticalHoverSpanMiddle =
      Math.ceil(mousePosition.y / MAX_TIMELINE_SPAN) * 8;
    let hoveredRangeGroupIndex = 0;
    let previousRangeGroupSpan = 0;
    for (const rangeGroupIndexStr in rangeGroups) {
      const rangeGroupIndex = Number(rangeGroupIndexStr);
      const rangeGroup = rangeGroups[rangeGroupIndex];
      const spanStart = previousRangeGroupSpan;
      const spanEnd = spanStart + (rangeGroup.span || 8);
      if (
        spanStart < verticalHoverSpanMiddle &&
        spanEnd >= verticalHoverSpanMiddle
      ) {
        hoveredRangeGroupIndex = rangeGroupIndex;
        break;
      }
      previousRangeGroupSpan = spanEnd;
    }
    return hoveredRangeGroupIndex;
  }

  if (!isValidated) {
    return null;
  }

  if (validationError) {
    return (
      <div className={`h-${span + 16}`}>
        <div className={`px-4 py-2 h-${span} border rounded`}>
          <Alert
            type="error"
            message={error ? "Preview error" : "Props validation error"}
            description={validationError}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="text-lg font-semibold mb-2">{title}</div>
      <div className={`flex h-${span + 8}`}>
        {/* Start Infinity */}
        <div
          className={`h-${span} w-16 bg-blue-50 relative`}
          onMouseDown={onTimelineMouseDown}
          onMouseOver={() => setIsHoveringStartInfinity(true)}
          onMouseOut={() => setIsHoveringStartInfinity(false)}
        >
          <div
            className={`infinity-symbol pointer-events-none flex items-end justify-center h-full pb-1`}
          >
            <span style={{ transform: "translateY(-3px)" }}>-</span>
            <span className={`text-lg`}>&infin;</span>
          </div>

          <div className="timeticks pointer-events-none  absolute inset-0">
            {/* Mouse trailing timetick */}
            {interactable && isHoveringStartInfinity ? (
              <div
                className={`mouse-trailing-timetick absolute h-full w-px bg-gray-300 top-0 left-8`}
                style={{
                  zIndex: 5,
                }}
              />
            ) : null}
            {/* Custom ticks */}
            {customTimeticks.map((info, index) => {
              if (!info.tick.isSame(startInfinityMoment)) {
                return null;
              }

              return (
                <Tooltip
                  key={info.tick.toISOString()}
                  title={info.label}
                  open={info.label ? undefined : false}
                >
                  <div
                    key={info.tick.toISOString()}
                    className={`custom-timetick absolute h-full w-px bg-red-300 left-8`}
                    style={{
                      zIndex: 10,
                    }}
                  />
                </Tooltip>
              );
            })}
          </div>

          <div className="rangeGroups pointer-events-none absolute inset-0">
            {/* Ranges */}
            {rangeGroups.map((rangeGroup, rangeGroupIndex) => {
              return (
                <div
                  key={rangeGroup.name + rangeGroupIndex}
                  className={`rangeGroup ${rangeGroup.name} absolute inset-0`}
                >
                  {rangeGroup.ranges.map((range, rangeIndex) => {
                    if (!range.start.isSame(startInfinityMoment)) {
                      return null;
                    }

                    const getContainerClassName =
                      rangeGroup.getContainerStartInfinityClassName ||
                      (() => "");
                    const getContainerStyles =
                      rangeGroup.getContainerStartInfinityStyles || (() => {});

                    const containerClassName = getContainerClassName(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );
                    const containerStyles = getContainerStyles(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );

                    return (
                      <div
                        key={rangeIndex}
                        className={clsx(
                          `range ${containerClassName} absolute flex items-center justify-center text-sm bottom-8 left-0 right-0 bg-opacity-50 border-2 pointer-events-none`,
                          `bg-${range.color || "gray"}-200`,
                          `h-${rangeGroup.span}`
                        )}
                        style={{
                          ...containerStyles,
                          zIndex: 20,
                          top: 0,
                        }}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {/* Timeline */}
        <div
          id={"account-state-timeline"}
          ref={ref}
          onMouseMove={onTimelineMouseMove}
          onMouseOut={() => setMousePosition({ x: -1, y: -1 })}
          onMouseDown={() => onTimelineMouseDown()}
          className={`flex-grow h-${span} mx-2 relative bg-blue-50 `}
          // cursor-add
        >
          <div className="timeticks pointer-events-none absolute inset-0">
            {/* Now timetick */}
            {moment().isBetween(start, end) ? (
              <div
                className={`now-timetick absolute top-0 bottom-0 w-px pointer-events-none flex flex-col items-center gap-1`}
                style={{
                  left: tickToPosition(moment()),
                  zIndex: 20,
                }}
              >
                {" "}
                <span className={`h-1/3 w-full bg-indigo-400`} />
                <span
                  className={`h-1/2 text-indigo-400 text-xs`}
                  style={{
                    textAlign: "center",
                    textOrientation: "upright",
                    writingMode: "vertical-lr",
                  }}
                >
                  NOW
                </span>
                <span className={`h-1/3 w-full bg-indigo-400`} />
              </div>
            ) : null}
            {/* Timeticks */}
            {timeticks.map((timetick, index) => {
              return (
                <Tooltip
                  key={timetick.toISOString()}
                  title={timetick.format(formatByTimestep(timestep))}
                  open={index === snappedIndex}
                >
                  <div
                    key={timetick.toISOString()}
                    className={`timeticks absolute h-full w-px pointer-events-none ${
                      index === snappedIndex ? "bg-blue-600" : "bg-blue-300"
                    }`}
                    style={{
                      left: tickToPosition(timetick),
                      zIndex: 10,
                    }}
                  />
                </Tooltip>
              );
            })}
            {/* Mouse trailing timetick */}
            {interactable ? (
              <div
                className={`mouse-trailing-timetick absolute h-full w-px pointer-events-none bg-gray-300`}
                style={{
                  left: mouseTimelinePosition,
                  zIndex: 5,
                }}
              />
            ) : null}
            {/* Custom ticks */}
            {customTimeticks.map((info, index) => {
              return (
                <Tooltip
                  key={info.tick.toISOString()}
                  title={info.label}
                  open={info.label ? undefined : false}
                >
                  <div
                    key={info.tick.toISOString()}
                    className={`custom-timetick absolute h-full w-px pointer-events-none bg-red-300`}
                    style={{
                      left: tickToPosition(info.tick),
                      zIndex: 10,
                    }}
                  />
                </Tooltip>
              );
            })}
          </div>
          {/* Timetick labels */}
          {timeticks.map((timetick, index) => {
            let show = index === 0 || index === timeticks.length - 1;
            if (!show) {
              show = index % 4 === 0;
            }
            if (!show) {
              return null;
            }
            return (
              <div
                key={timetick.toISOString()}
                className={`absolute pointer-events-none top-${span} h-8 whitespace-nowrap`}
                style={{
                  left: tickToPosition(timetick),
                  transform: "translateX(-50%)",
                }}
              >
                {timetick.format(formatByTimestep(timestep))}
              </div>
            );
          })}
          <div className="rangeGroups pointer-events-none absolute inset-0">
            {/* Ranges */}
            {rangeGroups.map((rangeGroup, rangeGroupIndex) => {
              return (
                <div
                  key={rangeGroup.name + rangeGroupIndex}
                  className={`rangeGroup ${rangeGroup.name}`}
                >
                  {rangeGroup.ranges.map((range, rangeIndex) => {
                    const rangeStart = range.start
                      ? moment(range.start)
                      : moment().subtract(100, "year");
                    const rangeEnd = range.end
                      ? moment(range.end)
                      : moment().add(100, "year");

                    const left = rangeStart.isBefore(startBuffered)
                      ? 0
                      : tickToPosition(rangeStart);
                    const right = rangeEnd.isAfter(endBuffered)
                      ? width
                      : tickToPosition(rangeEnd);

                    // top to bottom
                    let previousSpans = 0;
                    for (const _rangeGroupIndexStr in rangeGroups) {
                      const _rangeGroupIndex = Number(_rangeGroupIndexStr);
                      const _rangeGroup = rangeGroups[_rangeGroupIndex];
                      if (_rangeGroupIndex >= rangeGroupIndex) {
                        continue;
                      }
                      previousSpans += _rangeGroup.span || 8;
                    }
                    const topClass = `top-${
                      rangeGroup.topOffset || previousSpans
                    }`;
                    const heightClass = `h-${rangeGroup.span || 8}`;

                    const rangeGroupRender =
                      rangeGroup.render ||
                      (() => (
                        <span>
                          {rangeGroup.name} - {rangeIndex}
                        </span>
                      ));

                    const getContainerClassName =
                      rangeGroup.getContainerClassName || (() => "");
                    const getContainerStyles =
                      rangeGroup.getContainerStyles || (() => {});

                    const containerClassName = getContainerClassName(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );
                    const containerStyles = getContainerStyles(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );

                    const hasBefore =
                      rangeStart.isBefore(startBuffered) &&
                      !rangeStart.isSame(startInfinityMoment);
                    const hasAfter =
                      rangeEnd.isAfter(endBuffered) &&
                      !rangeEnd.isSame(endInfinityMoment);

                    return (
                      <div
                        key={range.start?.toISOString() || "infinity"}
                        className="range"
                      >
                        {hasBefore && !hideShades ? (
                          <div
                            className={`absolute bottom-8 h-8`}
                            style={{
                              zIndex: 33,
                              height: 96,
                              width: 50,
                              top: 0,
                              background: `linear-gradient(to left, rgba(${getColorRgb(
                                range.color
                              )}, 0.1), rgba(${getColorRgb(range.color)}, 1))`,
                            }}
                          />
                        ) : null}
                        <div
                          className={clsx(
                            `absolute flex items-center justify-center text-sm bg-opacity-50 border-2`,
                            `bg-${range.color || "gray"}-200`,
                            topClass,
                            heightClass,
                            containerClassName
                          )}
                          style={{
                            ...containerStyles,
                            left: left,
                            right: width - right,
                            zIndex: 20,
                          }}
                        >
                          {/* ${color.text} */}
                          <span className={` truncate px-2`}>
                            {rangeGroupRender(
                              rangeGroup,
                              rangeGroupIndex,
                              range,
                              rangeIndex,
                              hoverData,
                              clickData
                            )}
                          </span>
                        </div>
                        {hasAfter && !hideShades? (
                          <div
                            className={`absolute bottom-8 h-8`}
                            style={{
                              zIndex: 33,
                              height: 96,
                              width: 50,
                              top: 0,
                              right: 0,
                              background: `linear-gradient(to right, rgba(${getColorRgb(
                                range.color
                              )}, 0.1), rgba(${getColorRgb(range.color)}, 1))`,
                            }}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        {/* End Infinity */}
        <div
          className={`h-${span} w-16 bg-blue-50 relative`}
          onMouseDown={onTimelineMouseDown}
          onMouseOver={() => setIsHoveringEndInfinity(true)}
          onMouseOut={() => setIsHoveringEndInfinity(false)}
        >
          <div
            className={`infinity-symbol flex items-end justify-center h-full pb-1`}
          >
            <span style={{ transform: "translateY(-3px)" }}>+</span>
            <span className={`text-lg`}>&infin;</span>
          </div>
          <div className="timeticks pointer-events-none  absolute inset-0">
            {/* Mouse trailing timetick */}
            {interactable && isHoveringEndInfinity ? (
              <div
                className={`mouse-trailing-timetick absolute h-full w-px bg-gray-300 top-0 left-8`}
                style={{
                  zIndex: 5,
                }}
              />
            ) : null}
            {/* Custom ticks */}
            {customTimeticks.map((info, index) => {
              if (!info.tick.isSame(endInfinityMoment)) {
                return null;
              }

              return (
                <Tooltip
                  key={info.tick.toISOString()}
                  title={info.label}
                  open={info.label ? undefined : false}
                >
                  <div
                    key={info.tick.toISOString()}
                    className={`custom-timetick absolute h-full w-px pointer-events-none bg-red-300 left-8`}
                    style={{
                      zIndex: 10,
                    }}
                  />
                </Tooltip>
              );
            })}
          </div>
          <div className="rangeGroups absolute pointer-events-none inset-0">
            {/* Ranges */}
            {rangeGroups.map((rangeGroup, rangeGroupIndex) => {
              return (
                <div
                  key={rangeGroup.name + rangeGroupIndex}
                  className={`rangeGroup ${rangeGroup.name} absolute inset-0`}
                >
                  {rangeGroup.ranges.map((range, rangeIndex) => {
                    if (!range.end.isSame(endInfinityMoment)) {
                      return null;
                    }

                    const getContainerClassName =
                      rangeGroup.getContainerEndInfinityClassName || (() => "");
                    const getContainerStyles =
                      rangeGroup.getContainerEndInfinityStyles || (() => {});

                    const containerClassName = getContainerClassName(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );
                    const containerStyles = getContainerStyles(
                      rangeGroup,
                      rangeGroupIndex,
                      range,
                      rangeIndex,
                      hoverData,
                      clickData
                    );

                    return (
                      <div
                        key={rangeIndex}
                        className={clsx(
                          `range ${containerClassName} absolute flex items-center justify-center text-sm bottom-8 left-0 right-0 bg-opacity-50 border-2`,
                          `bg-${range.color || "gray"}-200`,
                          `h-${rangeGroup.span}`
                        )}
                        style={{
                          ...containerStyles,
                          zIndex: 20,
                          top: 0,
                        }}
                      />
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {debug ? (
        <div className="text-xs">
          <div>isHoveringStartInfinity: {String(isHoveringStartInfinity)}</div>
          <div>isHoveringEndInfinity: {String(isHoveringEndInfinity)}</div>
          <div>hoverData:</div>
          <pre>
            {JSON.stringify(
              {
                rangeGroupIndex: hoverData.rangeGroupIndex,
                rangeIndex: hoverData.rangeIndex,
                trailingTick: hoverData.trailingTick,
                snappedTick: hoverData.snappedTick,
              },
              null,
              2
            )}
          </pre>
          <div>clickData:</div>
          <pre>
            {JSON.stringify(
              {
                rangeGroupIndex: clickData.rangeGroupIndex,
                rangeIndex: clickData.rangeIndex,
                trailingTick: clickData.trailingTick,
                snappedTick: clickData.snappedTick,
              },
              null,
              2
            )}
          </pre>
          <div>RangeGroups:</div>
          <pre>
            {JSON.stringify(
              rangeGroups
                .filter((rg) => rg.name === "accountState")
                .map((rg) => ({
                  ...rg,
                  ranges: rg.ranges.map((r) => {
                    const { item, ...rest } = r;
                    const { ratePlan, trustLevel, accountType, ...restOfItem } =
                      item as any;
                    return { ...rest, item: restOfItem };
                  }),
                })),
              null,
              2
            )}
          </pre>
        </div>
      ) : null}
    </>
  );
}
