import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Partner } from "./types";

interface Props {
  partner: Partner;
}
const PartnerInfoRaw: React.FC<Props> = ({ partner }) => {
  return (
    <div className="px-8 w-full">
      <PageHeader title={partner.name} subTitle={"Partner Detail - Info"} />
      <div className="px-6">
        <div className="flex">
          <div className="w-1/3">
            <div className="text-base text-gray-700 font-semibold">Name</div>
            <div className="mb-4 mt-1">{partner.name}</div>
          </div>
          <div className="w-1/3">
            <div className="text-base text-gray-700 font-semibold">Id</div>
            <div className="mb-4 mt-1">{partner.id}</div>
          </div>
          <div className="w-1/3">
            <div className="text-base text-gray-700 font-semibold">Email</div>
            <div className="mb-4 mt-1">{partner.email || "No email"}</div>
          </div>
        </div>
        <div className={`flex`}>
          <div className={`w-1/3`}>
            <div className="text-base text-gray-700 font-semibold">
              Hostnames
            </div>
            <div className="mb-4 mt-1 flex flex-col gap-1">
              {!partner.hostnames ||
                !Array.isArray(partner.hostnames) ||
                (partner.hostnames.length < 1 && <span>No Hostnames</span>)}
              {partner.hostnames &&
                Array.isArray(partner.hostnames) &&
                partner.hostnames.map((h: any) => <span>{h}</span>)}
            </div>
          </div>
          <div className={`w-1/3`}>
            <div className="text-base text-gray-700 font-semibold">
              Redirect Path
            </div>
            <div className="mb-4 mt-1">
              {partner.redirectPath || "No redirects"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PartnerInfo = observer(PartnerInfoRaw);
