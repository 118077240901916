export type GlobalAction =
  | "root"
  | "manageAcls"
  | "manageUsers"
  | "manageIncidents";

export interface AdminProfile {
  email: string;
  actions: AdminProfileActionsProperty;
}

export type AdminProfileActionsProperty = {
  [key in GlobalAction]?: boolean;
};

export const getDefaultAdminProfile: () => AdminProfile = () => ({
  email: "",
  actions: {
    root: false,
    manageAcls: false,
    manageUsers: false,
    manageIncidents: false,
  },
});

export type OrgAction =
  | "view"
  | "manage"
  | "impersonate"
  | "inspect"
  | "manageQuota"
  | "manageLocations";

export type OrgAclActionsProperty = {
  [key in OrgAction]?: boolean;
};

export interface OrgAcl {
  org: string;
  actions: OrgAclActionsProperty;
}

export const getDefaultOrgAcl: () => OrgAcl = () => ({
  org: "",
  actions: {
    view: false,
    manage: false,
    impersonate: false,
    inspect: false,
    manageQuota: false,
    manageLocations: false,
  },
});

export interface AdminEditFormData {
  email: string;
  globalActions: GlobalAction[];
  orgAclList: {
    org: string;
    actions: OrgAction[];
  }[];
}

export function toAdminEditFormData(
  adminProfile: AdminProfile,
  orgAclList: OrgAcl[]
): AdminEditFormData {
  return {
    email: adminProfile.email,
    globalActions: (
      Object.entries(adminProfile.actions) as [GlobalAction, boolean][]
    )
      .filter(([_key, value]) => value === true)
      .map(([key, _value]) => key),
    orgAclList: orgAclList.map((orgAcl) => ({
      org: orgAcl.org,
      actions: (Object.entries(orgAcl.actions) as [OrgAction, boolean][])
        .filter(([_key, value]) => value === true)
        .map(([key, _value]) => key),
    })),
  };
}

export function toAdminProfile(formData: AdminEditFormData): AdminProfile {
  const actions = getDefaultAdminProfile().actions;
  for (let action of formData.globalActions) {
    actions[action] = true;
  }

  return {
    email: formData.email,
    actions,
  };
}

export function toOrgAclList(formData: AdminEditFormData): OrgAcl[] {
  const orgAclList: OrgAcl[] = [];
  for (let formOrgAcl of formData.orgAclList) {
    const actions: OrgAclActionsProperty = {};
    for (let action of formOrgAcl.actions) {
      actions[action] = true;
    }

    orgAclList.push({
      org: formOrgAcl.org,
      actions,
    });
  }
  return orgAclList;
}
