import * as React from "react";

import { formatCentsToCurrency, getLocalToken, request } from "../../utils";
import { getAccountStateStartEndTimes } from "./AccountState/utils";
import { observer } from "mobx-react-lite";
import { useLocation, useParams } from "react-router-dom";
import { useMain } from "../../stores/main";
import moment, { Moment } from "moment";

import { DatePicker } from "../../Components/antd/DatePicker";
import { Invoice } from "../Billing/types";
import { JSONModal } from "../../jsonModal";
import { notification, Table, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import qs from "qs";
import { sortBy } from "lodash";

type RouteParams = "uuid";
const AccountInvoiceRaw: React.FC = () => {
  const main = useMain();
  const { uuid } = useParams<RouteParams>();
  const location = useLocation();
  const { selectedId, start, end } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const [invoices, setInvoices] = React.useState<any[]>([]);
  const [viewingItem, setViewingItem] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [startMoment, setStartMoment] = React.useState<Moment>(
    start ? moment(start.toString()) : getAccountStateStartEndTimes("month")[0]
  );
  const [endMoment, setEndMoment] = React.useState<Moment>(
    end ? moment(end.toString()) : getAccountStateStartEndTimes("month")[1]
  );

  const [localToken, setLocalToken] = React.useState("");

  React.useEffect(() => {
    getLocalToken().then((r) => setLocalToken(r.accessToken));
  }, []);

  React.useEffect(() => {
    fetchInvoices();
  }, [startMoment, endMoment]);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem]);

  function getRowClassName(record: Invoice) {
    if (record.id === selectedId) {
      return "bg-blue-50";
    }
    return "";
  }

  async function fetchInvoices() {
    try {
      setIsLoading(true);
      const res = await request({
        service: "billing-ng",
        url: `/account/${uuid}/charges/invoices?start=${startMoment?.toISOString()}&end=${endMoment?.toISOString()}`,
      });
      setInvoices(sortBy(res.invoices, (i) => i.startTime).reverse());
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch invoices of this account",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function downloadPdf(invoiceId: string) {
    try {
      setIsLoading(true);

      const headers = new Headers();
      headers.append("Content-Type", "application/pdf");
      headers.append("Authorization", `Bearer ${localToken}`);

      const requestOptions: any = {
        method: "GET",
        headers,
      };

      const response = await fetch(
        `${main.discovery.endpoints["billing-ng"]}/account/${uuid}/charges/invoices/${invoiceId}`,
        requestOptions
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const newTab = window.open();
      if (newTab) {
        newTab.location.href = url;
      } else {
        notification.warning({
          message: "Failed to open the PDF",
          description: "Please allow popups for this website",
        });
      }

      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to download invoice pdf",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const columns = [
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (value: string) => (
        <div className="text-sm truncate">
          {moment(value).toDate().toDateString()}
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (value: string) => (
        <div className="text-sm truncate">
          {moment(value).toDate().toDateString()}
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "Total Charge",
      dataIndex: "totalCharge",
      key: "totalCharge",
      render: (value: string) => (
        <div className="text-sm truncate">
          {formatCentsToCurrency(Number(value))}
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "url",
      key: "url",
      render: (_value: string, record: any) => (
        <div className="flex items-center">
          <Button
            size={"small"}
            type={"primary"}
            onClick={() => downloadPdf(record.id)}
          >
            View Invoice
          </Button>
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View Item
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <div className="flex items-center gap-4">
          <PageHeader
            title={"Invoices"}
            subTitle={"Account Detail - Invoices"}
          />
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <label>Start</label>
              <DatePicker
                value={startMoment}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  setStartMoment(value);
                }}
              />
            </div>
            <div className="flex items-center gap-2">
              <label>End</label>
              <DatePicker
                value={endMoment}
                onChange={(value) => {
                  if (!value) {
                    return;
                  }
                  setEndMoment(value);
                }}
              />
            </div>
          </div>
        </div>
        <Table
          loading={isLoading}
          dataSource={invoices}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"id"}
          rowClassName={getRowClassName}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.id}
          onClose={() => setViewingItem(null)}
          title={`Invoice (ID: ${viewingItem.id})`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const AccountInvoice = observer(AccountInvoiceRaw);
