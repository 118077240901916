import {
  Modal,
  Table,
  Button,
  Tag,
  notification,
  MenuProps,
  Dropdown,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import {
  InspectImpersonateType,
  formatTimestamp,
  onImpersonateUser,
  request,
} from "../../utils";
import { CONSOLE_STAGING } from "../../envVariables";

interface UserImpersonateRes {
  name: string;
  expires: number;
  impersonatedUserEmail: string;
  key: string;
}

interface Props {
  user: any;
  orgs: any[];
}
const UserRawOrg: React.FC<Props> = ({ user, orgs }) => {
  const [viewingItem, setViewingItem] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  async function onImpersonate(org: any, type: InspectImpersonateType) {
    Modal.confirm({
      title: "Are you sure?",
      content: `Impersonating a user has write permissions depending on user's role. Inspect the org instead if you want a read-only view.`,
      async onOk() {
        try {
          setIsLoading(true);
          await onImpersonateUser(org.name, user.email, type);
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to fetch inspect org token",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
    });
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const onImpersonateMenuClick: (user: any) => MenuProps["onClick"] =
    (user) => (e) => {
      onImpersonate(user, e.key as any);
    };

  const impersonateMenuItems = [
    {
      key: "local",
      label: "Local",
    },
  ];
  if (CONSOLE_STAGING) {
    impersonateMenuItems.unshift({ key: "staging", label: "Staging" });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/org/${record.name}`}
          >
            {text}
          </Link>
          <span className="text-sm truncate">{record.description}</span>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: any) => (
        <>
          {Object.entries(tags || {}).map(([key, value]: [any, any]) => {
            return (
              <Tag key={key}>
                {key}={value}
              </Tag>
            );
          })}
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: any) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
    },
    {
      title: "Impersonate",
      dataIndex: "impersonate",
      key: "impersonate",
      render: (_text: string, record: any) => (
        <div className="flex items-center">
          <div>
            <Dropdown.Button
              type={"primary"}
              size={"small"}
              onClick={() => onImpersonate(record, "default")}
              menu={{
                items: impersonateMenuItems,
                onClick: onImpersonateMenuClick(record),
              }}
            >
              Impersonate
            </Dropdown.Button>
          </div>
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => exportItem(record)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <PageHeader title={"Orgs"} subTitle={"Org List of User"} />
        <Table
          size={"small"}
          loading={isLoading}
          dataSource={orgs}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"id"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Org: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const UserOrg = observer(UserRawOrg);
