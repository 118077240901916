import * as React from "react";
import styles from "../styles/List.module.css";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Button, Input, Modal, Table, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { ISubmission } from "../utilities/types";
import { formatTimestampToDateFormat } from "../../../utils";
import { ColumnsType } from "antd/lib/table";
import {
  GenericFetchPricingCalculator,
  ViewPriceComparison,
} from "../utilities/helper";

const PricingCalculatorSubmissionsListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [modalTitle, setModalTitle] = React.useState<string>("");
  const [selectedSubmission, setSelectedSubmission] = React.useState<
    ISubmission | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [searchContent, setSearchContent] = React.useState("");
  const [submissions, setSubmissions] = React.useState<ISubmission[]>([]);

  React.useEffect(() => {
    fetchSubmissions();
  }, []);

  const dataSource = submissions.filter((submission) => {
    const lowerCaseSearch: string = searchContent.toLowerCase();
    return (
      submission.userName.toLowerCase().includes(lowerCaseSearch) ||
      submission.userEmail.toLowerCase().includes(lowerCaseSearch)
    );
  });

  const columns: ColumnsType<ISubmission> = [
    {
      title: "Name",
      dataIndex: "userName",
      key: "userName",
      render: (text: string, record: ISubmission) => (
        <Link className="text-base text-blue-600 truncate" to={`${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "userEmail",
    },
    {
      title: "IP Address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Cluster Name",
      dataIndex: "clusterName",
      key: "clusterName",
    },
    {
      title: "Kubernetes Version",
      dataIndex: "kubernetesVersion",
      key: "kubernetesVersion",
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      render: (created: Date, _: ISubmission) => (
        <span>{formatTimestampToDateFormat(created.toString())}</span>
      ),
      sorter: (a: any, b: any) =>
        new Date(a.created.toString()).getTime() -
        new Date(b.created.toString()).getTime(),
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Status",
      dataIndex: "failReason",
      key: "failReason",
      render: (failReason: string, submission: ISubmission) => (
        <div>
          {failReason ? (
            <Button
              className={styles.statusBtn}
              type="primary"
              danger
              onClick={() => viewFailReason(submission)}
            >
              Failed
            </Button>
          ) : (
            <Button className={styles.successStatusBtn} type="primary">
              Success
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (id: string, submission: ISubmission) => (
        <Button
          className={styles.viewBtn}
          type={"primary"}
          disabled={submission.failReason != undefined}
          onClick={() => ViewPriceComparison(id)}
        >
          View
        </Button>
      ),
    },
  ];

  // Functions //
  async function fetchSubmissions(): Promise<void> {
    setIsLoading(true);
    const response: ISubmission[] | string =
      await GenericFetchPricingCalculator<ISubmission[]>();
    if (typeof response === "string") {
      notification.warning({
        message: "Failed to fetch pricing calculator submissions",
        description: response,
      });
    } else {
      setSubmissions(response);
    }
    setIsLoading(false);
  }

  function viewFailReason(submission: ISubmission): void {
    setModalTitle(submission.userName + "'s Submission - Fail Reason");
    setSelectedSubmission(submission);
    setIsModalOpen(true);
  }

  function handleModalOk(): void {
    window.location.href = `mailto:${selectedSubmission?.userEmail}`;
  }

  function handleModalCancel(): void {
    setIsModalOpen(false);
  }

  return (
    <>
      <div className="flex items-center">
        <PageHeader title={"Pricing Calculator Submissions"} />
        <Input
          style={{ maxWidth: 450 }}
          value={searchContent}
          placeholder={"Filter by name or email"}
          onChange={(e) => setSearchContent(e.target.value)}
        />
      </div>
      <div className="px-6 pb-4">
        <Table
          key={"id"}
          rowKey={"id"}
          columns={columns as ColumnsType<ISubmission>}
          dataSource={dataSource}
          loading={isLoading}
          size={"small"}
          pagination={{ showSizeChanger: false }}
          bordered
        />
      </div>
      <Modal
        open={isModalOpen}
        title={modalTitle}
        okText="Email Customer"
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {selectedSubmission?.failReason}
      </Modal>
    </>
  );
};

export const PricingCalculatorSubmissionsList = observer(
  PricingCalculatorSubmissionsListRaw
);
