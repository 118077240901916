import { Button, Form, notification, Select, Table, Tag, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IncidentStore } from "../../stores/incident";
import { fetchComponents, fetchIncidents, updateComponentStatus } from "./http";
import { Incident, IncidentComponent } from "./types";
import { PlusCircleFilled } from "@ant-design/icons";
import Modal from "antd/lib/modal/Modal";
import FormItem from "antd/lib/form/FormItem";
import { IconsWithLabel } from "./icons";
import { Spinner } from "../../Components/Spinner";

type RouteParams = "component";
const ComponentDetailRaw: React.FC = () => {
  const { component: _componentName } = useParams<RouteParams>();
  const componentName = _componentName!;
  const navigate = useNavigate();

  const [component, setComponent] = React.useState<IncidentComponent>(
    null as any
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [isEditingStatus, setIsEditingStatus] = React.useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = React.useState(false);
  const [incidentsPage, setIncidentsPage] = React.useState(1);
  const [isPaginationDone, setIsPaginationDone] = React.useState(false);

  React.useEffect(() => {
    process();
    return () => {
      IncidentStore.setIncidents([]);
    };
  }, []);

  async function process() {
    try {
      setIsLoading(true);

      const components = await fetchComponents();
      IncidentStore.setComponents(components);

      const _component = IncidentStore.components.find(
        (c) => c.name === componentName
      );
      if (!_component) {
        throw new Error(`No component with name "${componentName}".`);
      }
      setComponent(_component);

      await fetchIncidentPage();

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  }

  async function fetchIncidentPage() {
    const oldIncidents =
      incidentsPage === 1 ? [] : [...IncidentStore.incidents];
    const incidents = await fetchIncidents({
      component: componentName,
      page: incidentsPage,
    });
    IncidentStore.setIncidents([...oldIncidents, ...incidents]);
    setIncidentsPage((x) => x + 1);
    if (incidents.length < 1) {
      setIsPaginationDone(true);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className={`flex flex-col gap-2 pl-8`}>
        <div>{error}</div>
        <Link to={`/admin/incidents`}>Go Back</Link>
      </div>
    );
  }

  async function onUpdateComponentStatus(values: any) {
    const status: string = values.status;
    if (status === component.status) {
      notification.info({
        message: "Not updated",
        description: "Component already has the same status",
      });
      setIsEditingStatus(false);
      return;
    }

    try {
      setIsLoadingStatus(true);
      await updateComponentStatus(componentName, status as any);
      notification.success({
        message: "Success",
        description: "Updated Component Status",
      });
      const _component = JSON.parse(JSON.stringify(component));
      _component.status = status;
      setComponent(_component);
      setIsLoadingStatus(false);
    } catch (e) {
      setIsLoadingStatus(false);
      notification.warning({ message: "Failed", description: e.message });
    }
    setIsEditingStatus(false);
  }

  const locations =
    !component.locations || component.locations.length < 1
      ? ["Global"]
      : component.locations;

  const ComponentStatusIconComponent = IconsWithLabel[component.status];

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className={"px-8 my-8"}>
          <div className={"text-xl mb-2"}>Component Info</div>
          <div className={"border-b mb-4 pb-4 flex"}>
            <div className="w-2/12">
              <div className={"text-sm uppercase font-bold mb-2"}>Name</div>
              <div>{component.description}</div>
            </div>
            <div className="w-2/12">
              <div className={"flex items-center gap-2 mb-2"}>
                <div className={"text-sm uppercase font-bold"}>Status</div>
                <Button
                  type={"primary"}
                  size={"small"}
                  onClick={() => setIsEditingStatus(true)}
                >
                  Edit
                </Button>
              </div>
              <div>
                <ComponentStatusIconComponent />
              </div>
            </div>
            <div className="w-8/12 pr-8">
              <div className={"text-sm uppercase font-bold mb-2"}>
                Locations
              </div>
              <div>
                {locations.map((l) => (
                  <Tag key={l} className={"my-1"}>
                    {l}
                  </Tag>
                ))}
              </div>
            </div>
          </div>
          <div className={"text-xl mb-2"}>
            Incidents{" "}
            <Button
              className={"ml-2"}
              size={"small"}
              type={"primary"}
              icon={<PlusCircleFilled />}
              onClick={() => {
                const url = `/admin/incidents/component/${componentName}/incident/new/edit`;
                navigate(url);
              }}
            >
              Add New Incident
            </Button>
          </div>
          <Table
            rowKey={"id"}
            pagination={false}
            size={"small"}
            columns={[
              {
                key: "title",
                dataIndex: "title",
                title: "Title",
                render: (title: string, incident: Incident) => (
                  <Link
                    key={incident.id + "-title"}
                    className={"text-blue-500 hover:text-blue-600"}
                    to={`/admin/incidents/component/${incident.component}/incident/${incident.id}`}
                  >
                    {title}
                  </Link>
                ),
              },
              {
                key: "component",
                dataIndex: "component",
                title: "Component",
                render: (component: string) => (
                  <div key={component + "-component"}>
                    {
                      IncidentStore.components.find((c) => c.name === component)
                        ?.description
                    }
                  </div>
                ),
              },
              {
                key: "location",
                dataIndex: "locations",
                title: "Locations",
                render: (_: string, incident: Incident) => {
                  if (!incident.locations || incident.locations.length < 1) {
                    return <div key={incident.id + "-location"}>Global</div>;
                  }

                  const remaining = incident.locations.length - 2;

                  return (
                    <div
                      key={incident.id + "-location"}
                      className={"flex items-center gap-2"}
                    >
                      {incident.locations.slice(0, 2).map((l) => (
                        <Tag key={l}>{l}</Tag>
                      ))}
                      {remaining > 0 ? (
                        <Tooltip
                          className={"text-blue-500"}
                          title={
                            <div>
                              {incident.locations.slice(2).map((l) => (
                                <div key={l} className={"my-1"}>
                                  <Tag>{l}</Tag>
                                </div>
                              ))}
                            </div>
                          }
                        >
                          +{remaining}
                        </Tooltip>
                      ) : null}
                    </div>
                  );
                },
              },
              {
                key: "type",
                dataIndex: "type",
                title: "Type",
                render: (_: string, incident: Incident) => {
                  const IncidentIconTypeComponent =
                    IconsWithLabel[incident.type];
                  return (
                    <span key={incident.id + "-type"}>
                      <IncidentIconTypeComponent />
                    </span>
                  );
                },
              },
              {
                key: "detectedTime",
                dataIndex: "detectedTime",
                title: "Detected Time",
                render: (_: string, incident: Incident) => {
                  return (
                    <div key={incident.id + "-detectedTime"}>
                      {incident.detectedTime.toISOString()}
                    </div>
                  );
                },
              },
              {
                key: "resolvedTime",
                dataIndex: "resolvedTime",
                title: "Resolved Time",
                render: (_: string, incident: Incident) => (
                  <div key={incident.id + "-resolvedTime"}>
                    {incident.resolvedTime?.toISOString() || "Not Resolved"}
                  </div>
                ),
              },
            ]}
            dataSource={IncidentStore.incidents}
          />
          <div className={`flex justify-end mt-4 mr-4`}>
            <Button disabled={isPaginationDone} onClick={fetchIncidentPage}>
              {isPaginationDone
                ? "All Incidents Are Loaded"
                : "Load More Incidents"}
            </Button>
          </div>
        </div>
      </div>
      {isEditingStatus ? (
        <Modal
          open={isEditingStatus}
          footer={null}
          closable={false}
          closeIcon={null}
          maskClosable={false}
        >
          <Form
            name="componentStatus"
            initialValues={{ status: component.status }}
            onFinish={onUpdateComponentStatus}
            autoComplete="off"
          >
            <FormItem
              label="Status"
              name="status"
              rules={[{ required: true, message: "Status is required" }]}
            >
              {/* TODO make this a radio button */}
              <Select
                options={[
                  { value: "available" },
                  { value: "disruption" },
                  { value: "outage" },
                  { value: "maintenance" },
                ]}
              />
            </FormItem>
            <div className={"flex items-center gap-4 justify-end"}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={isLoadingStatus}
                  loading={isLoadingStatus}
                >
                  Update Status
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  danger
                  disabled={isLoadingStatus}
                  loading={isLoadingStatus}
                  onClick={() => setIsEditingStatus(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};

export const ComponentDetail = observer(ComponentDetailRaw);
