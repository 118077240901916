import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { VideoDetail } from "./VideoDetail";
import { VideoList } from "./VideoList";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const VideoIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Educational Videos - Admin Panel
        </title>
      </Helmet>
      <Routes>
        <Route path={`:uid/*`} element={<VideoDetail />} />
        <Route index element={<VideoList />} />
      </Routes>
    </>
  );
};

export const EducationalVideosIndex = observer(VideoIndexRaw);
