import { Button, Switch, Form, notification, Popconfirm, Alert } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  createEvent,
  deleteEvent,
  fetchComponents,
  fetchEvent,
  fetchIncident,
  replaceEvent,
  replaceIncident,
  updateComponentStatus,
} from "./http";
import {
  Incident,
  IncidentComponentStatus,
  IncidentEditable,
  IncidentEventEditable,
} from "./types";
import { isEqual } from "lodash";
import { RichTextEditor } from "../../RichTextEditor/richTextEditor";
import { Spinner } from "../../Components/Spinner";
import { IncidentStore } from "../../stores/incident";
import { IconsWithLabel } from "./icons";
import { incidentToEditable } from "./utils";
import { DatePicker } from "../../Components/antd/DatePicker";
import moment from "moment-timezone";

type RouteParams = "component" | "incidentId" | "eventId";
const EventEditRaw: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const {
    component: _componentName,
    incidentId: _incidentId,
    eventId: _eventId,
  } = useParams<RouteParams>();

  const componentName = _componentName!;
  const incidentId = _incidentId!;
  const eventId = _eventId!;

  const isNew = pathname.endsWith("/new");

  const [incident, setIncident] = React.useState<Incident | null>(null);
  const [componentStatus, setComponentStatus] =
    React.useState<IncidentComponentStatus | null>(null);

  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState("");

  const [isDirty, setIsDirty] = React.useState(false);

  const [currentFormState, setCurrentFormState] =
    React.useState<IncidentEventEditable>(null as any);

  const [initialFormData, setInitialFormData] = React.useState<
    IncidentEventEditable | null | undefined
  >(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    process();
  }, []);

  async function process() {
    try {
      setIsLoading(true);

      const components = await fetchComponents();
      IncidentStore.setComponents(components);
      const component = IncidentStore.components.find(
        (c) => c.name === componentName
      );
      if (component) {
        setComponentStatus(component.status);
      }

      const incident = await fetchIncident(incidentId);
      setIncident(incident);

      let incidentEventEditable: IncidentEventEditable = {} as any;
      if (isNew) {
        incidentEventEditable = {
          description: "",
          createdTime: moment(),
          links: [],
          id: "",
          resolveIncident: false,
          resolvedTime: null,
          resolveComponent: false,
        };
      } else {
        const incidentEventItem = await fetchEvent(incidentId, eventId);
        incidentEventEditable = Object.assign(incidentEventItem, {
          createdTime: moment(incidentEventItem.createdTime),
          resolveIncident: false,
          resolvedTime: incident.resolvedTime
            ? moment(incident.resolvedTime)
            : null,
          resolveComponent: false,
        }) as IncidentEventEditable;
      }

      setInitialFormData(incidentEventEditable);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setError(e.message);
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className={`flex flex-col gap-2 pl-8`}>
        <div>{error}</div>
        <Link
          to={`/admin/incidents/component/${componentName}/incident/${incidentId}`}
        >
          Go Back
        </Link>
      </div>
    );
  }

  async function onFinish() {
    try {
      setIsLoading(true);
      const formData = getFormState();

      if (isNew) {
        await createEvent(incidentId, formData);
      } else {
        await replaceEvent(incidentId, formData);
      }

      if (formData.resolveIncident && formData.resolvedTime) {
        const incidentEditable: IncidentEditable = incidentToEditable(
          incident!
        );
        incidentEditable.resolvedTime = formData.resolvedTime;
        await replaceIncident(incidentEditable);
      }

      if (
        formData.resolveIncident &&
        formData.resolvedTime &&
        formData.resolveComponent
      ) {
        await updateComponentStatus(componentName, "available");
      }

      notification.success({ message: "Success" });
      setIsLoading(false);
      navigate(
        `/admin/incidents/component/${componentName}/incident/${incidentId}`
      );
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to save",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function getFormState(): IncidentEventEditable {
    const formState = form.getFieldsValue([
      "description",
      "createdTime",
      "resolveIncident",
      "resolvedTime",
      "resolveComponent",
    ]);
    return Object.assign(formState, { id: eventId || "new" });
  }

  function calculateIsDirty() {
    setIsDirty(!isEqual(getFormState(), initialFormData));
  }

  let ComponentStatus: React.ElementType = () => <span>Not Available</span>;
  if (componentStatus) {
    ComponentStatus = IconsWithLabel[componentStatus];
  }

  let IncidentType: React.ElementType = () => <span>Not Available</span>;
  if (incident) {
    IncidentType = IconsWithLabel[incident.type];
  }

  return (
    <div className="flex flex-col gap-4 pt-16">
      <div className={"ml-48 text-xl"}>
        {isNew ? "Create" : "Edit"} Incident Event
      </div>
      <div className={"flex items-center ml-48"}>
        <div className={"flex flex-col mr-16"}>
          <div className={"uppercase text-xs font-bold text-gray-600"}>
            Component Status
          </div>
          <div>
            <ComponentStatus />
          </div>
        </div>
        <div className={"flex flex-col"}>
          <div className={"uppercase text-xs font-bold text-gray-600"}>
            Incident Type
          </div>
          <div>
            <IncidentType />
          </div>
        </div>
      </div>

      <div className={"pl-8 pt-8"}>
        <Form
          form={form}
          onValuesChange={() => {
            calculateIsDirty();
            setCurrentFormState(getFormState());
          }}
          name="incidentEvent"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={initialFormData as any}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Description"
            name="description"
            rules={[{ required: true, message: "Description is required" }]}
            valuePropName={"formValue"}
            trigger={"formOnChange"}
          >
            <RichTextEditor />
          </Form.Item>
          <Form.Item
            name="createdTime"
            label="Created Time"
            rules={[{ required: true, message: "Created Time is required" }]}
          >
            <DatePicker showTime />
          </Form.Item>
          {incident?.resolvedTime ? null : (
            <>
              <Form.Item name="resolveIncident" label="Resolve Incident">
                <Switch />
              </Form.Item>
              <Form.Item
                name="resolvedTime"
                label="Resolved Time for Incident"
                extra={
                  currentFormState?.resolveIncident === true &&
                  !currentFormState?.resolvedTime ? (
                    <Alert
                      type={"warning"}
                      message={`Incident won't be resolved if "Resolved Time" is not selected.`}
                    />
                  ) : currentFormState?.resolveIncident === false &&
                    !!currentFormState?.resolvedTime ? (
                    <Alert
                      type={"warning"}
                      message={`Incident won't be resolved if "Resolve Incident" is not checked.`}
                    />
                  ) : null
                }
              >
                <DatePicker
                  showTime
                  disabled={currentFormState?.resolveIncident === false}
                />
              </Form.Item>
              {componentStatus === "available" ? null : (
                <Form.Item
                  name="resolveComponent"
                  label="Resolve Component"
                  extra={
                    currentFormState?.resolveComponent === true &&
                    currentFormState?.resolveIncident === true &&
                    !currentFormState?.resolvedTime ? (
                      <Alert
                        type={"warning"}
                        message={`Component won't be resolved if Resolved Time is not selected.`}
                      />
                    ) : currentFormState?.resolveComponent === true &&
                      currentFormState?.resolveIncident === false ? (
                      <Alert
                        type={"warning"}
                        message={`Component won't be resolved if Resolve Incident is not checked.`}
                      />
                    ) : null
                  }
                >
                  <Switch
                    disabled={
                      currentFormState?.resolveIncident === false ||
                      !currentFormState?.resolvedTime
                    }
                  />
                </Form.Item>
              )}
            </>
          )}
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <div className={"flex items-center gap-4"}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isDirty || isLoading}
                loading={isLoading}
              >
                {incidentId === "new" ? "Add Incident" : "Save Changes"}
              </Button>
              <Button
                type="default"
                danger
                disabled={isLoading}
                loading={isLoading}
                onClick={() =>
                  navigate(
                    `/admin/incidents/component/${componentName}/incident/${incidentId}`
                  )
                }
              >
                Cancel
              </Button>
              <Popconfirm
                title={"Are you sure you want to delete this incident event?"}
                onConfirm={async () => {
                  await deleteEvent(incidentId, eventId);
                  navigate(
                    `/admin/incidents/component/${componentName}/incident/${incidentId}`
                  );
                }}
              >
                <Button
                  type="primary"
                  danger
                  disabled={isLoading}
                  loading={isLoading}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const EventEdit = observer(EventEditRaw);
