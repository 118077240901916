import * as React from "react";
import { Button, notification, Radio } from "antd";
import { request } from "../../utils";
import { HighlightedText } from "../../highlightedText";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { DatePicker } from "../../Components/antd/DatePicker";
import moment from "moment-timezone";

export const BillingReports: React.FC = () => {
  const [timestep, setTimestep] = React.useState<"monthly" | "weekly">(
    "weekly"
  );
  const [format, setFormat] = React.useState<"json" | "tsv">("json");
  const [requestedFormat, setRequestedFormat] = React.useState<"json" | "tsv">(
    "json"
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const [weeklyEndTime, setWeeklyEndTime] = React.useState<moment.Moment>(
    moment().endOf("isoWeek").startOf("day").subtract(1, "week")
  );
  const [monthlyStartTime, setMonthlyStartTime] = React.useState<moment.Moment>(
    moment().endOf("isoWeek").startOf("month")
  );
  const [report, setReport] = React.useState();

  async function onQuery() {
    try {
      setReport(undefined);
      setIsLoading(true);
      setRequestedFormat(format);

      let url = `/reports/${timestep}_billing`;
      switch (timestep) {
        case "weekly":
          url += `?endTime=${weeklyEndTime
            .clone()
            .add(1, "day")
            .toISOString()}`;
          break;
        case "monthly":
          url += `?startTime=${monthlyStartTime.clone().toISOString()}`;
          break;
      }

      const data = await request({
        service: "billing-ng",
        url: url,
        headers: {
          accept:
            format === "tsv" ? "text/tab-separated-values" : "application/json",
        },
      });

      setReport(data);
      setIsLoading(false);
    } catch (e) {
      notification.warning({
        message: "Failed to view report",
        description: e.message,
      });
      setIsLoading(false);
    }
  }

  async function copyToClipboard() {
    navigator.clipboard.writeText(
      requestedFormat === "json" ? JSON.stringify(report, null, 2) : report!
    );
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function getHref() {
    const file = new Blob(
      [requestedFormat === "json" ? JSON.stringify(report, null, 2) : report!],
      {
        type:
          requestedFormat === "json"
            ? "text/json"
            : "text/tab-separated-values",
      }
    );
    return URL.createObjectURL(file);
  }

  function getDownloadFilename() {
    switch (timestep) {
      case "weekly":
        return `${timestep}-report-endTime-${weeklyEndTime
          .clone()
          .add(1, "day")
          .toISOString()}.${requestedFormat}`;
      case "monthly":
        return `${timestep}-report-startTime-${monthlyStartTime
          .clone()
          .toISOString()}.${requestedFormat}`;
    }
  }

  return (
    <>
      <div className={`flex items-center gap-4 mb-4`}>
        <div>
          <div className={`text-sm`}>Timestep</div>
          <Radio.Group
            options={[
              { label: "Weekly", value: "weekly" },
              { label: "Monthly", value: "monthly" },
            ]}
            onChange={(e) => setTimestep(e.target.value)}
            value={timestep}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        <div>
          <div className={`text-sm`}>Format</div>
          <Radio.Group
            options={[
              { label: "JSON", value: "json" },
              { label: "TSV (Tab Separated Values)", value: "tsv" },
            ]}
            onChange={(e) => setFormat(e.target.value)}
            value={format}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
        {timestep === "weekly" ? (
          <div>
            <div className={`text-sm`}>End Time</div>
            <DatePicker
              value={weeklyEndTime}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                setWeeklyEndTime(value);
              }}
            />
          </div>
        ) : null}
        {timestep === "monthly" ? (
          <div>
            <div className={`text-sm`}>Start Time</div>
            <DatePicker
              value={monthlyStartTime}
              onChange={(value) => {
                if (!value) {
                  return;
                }
                setMonthlyStartTime(value);
              }}
            />
          </div>
        ) : null}
        <div>
          <div className={`text-sm invisible`}>Create Report</div>
          <Button
            className={`self-end`}
            type={"primary"}
            onClick={onQuery}
            loading={isLoading}
            disabled={isLoading}
          >
            Create Report
          </Button>
        </div>
        {report ? (
          <>
            <div className={`ml-8`}>
              <div className={`text-sm invisible`}>Copy</div>
              <div
                onClick={copyToClipboard}
                className="text-blue-500 mr-4 text-sm cursor-pointer"
              >
                <span className="mr-2">Copy</span>
                <CopyOutlined />
              </div>
            </div>
            <div>
              <div className={`text-sm invisible`}>Download</div>
              <a
                href={getHref()}
                download={getDownloadFilename()}
                className="text-blue-500 text-sm"
              >
                <span className="mr-2">Download</span>
                <DownloadOutlined />
              </a>
            </div>
          </>
        ) : null}
      </div>
      {report ? (
        <>
          <HighlightedText
            data={report}
            formatter={
              requestedFormat === "json"
                ? (val) => JSON.stringify(val, null, 2)
                : undefined
            }
            language={requestedFormat === "json" ? "json" : "markup"}
          />
        </>
      ) : null}
    </>
  );
};
