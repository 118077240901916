import * as React from "react";
import Highlight, { defaultProps } from "prism-react-renderer";
import github from "prism-react-renderer/themes/github";

interface Props {
  data: any;
  language: "json" | "markup";
  formatter?: (data: any) => any;
}
export const HighlightedText: React.FC<Props> = ({
  data,
  language,
  formatter = (v) => v,
}) => {
  return (
    <Highlight
      {...defaultProps}
      code={formatter(data)}
      language={language}
      theme={github}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{
            ...style,
            width: "100%",
            padding: "10px 20px",
          }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => {
                const props = {
                  ...getTokenProps({ token, key }),
                  className: `${
                    getTokenProps({ token, key }).className || ""
                  } input-mono`,
                };
                return <span {...props} />;
              })}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
