import { notification, Table, Button, MenuProps, Dropdown } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { useMain } from "../../stores/main";
import { InspectImpersonateType, onInspectOrg, request } from "../../utils";
import { CONSOLE_STAGING } from "../../envVariables";

type RouteParamProps = "uuid";
const AccountOrgRaw: React.FC = () => {
  const main = useMain();
  const { uuid } = useParams<RouteParamProps>();
  const [viewingItem, setViewingItem] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [actingOn, setActingOn] = React.useState("");
  const [orgs, setOrgs] = React.useState<any[]>([]);

  React.useEffect(() => {
    fetchOrgs();
  }, []);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem, actingOn]);

  async function fetchOrgs() {
    try {
      setIsLoading(true);
      let nextLink = `/account/${uuid}/org`;
      let _orgs: any[] = [];
      while (nextLink) {
        const res = await request({ service: "billing-ng", url: nextLink });
        _orgs = _orgs.concat(res.items);
        nextLink = res.links.find((l: any) => l.rel === "next")?.href;
      }
      setOrgs(_orgs);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch orgs of this account",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function exportItem(record: any) {
    main.updateLastActivityTimestamp();
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  async function onInspect(org: string, type: InspectImpersonateType) {
    try {
      setIsLoading(true);
      setActingOn(org);
      await onInspectOrg(org, type);
      setActingOn("");
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch inspect org token",
        description: errorMessage,
      });
      setActingOn("");
      setIsLoading(false);
    }
  }

  const onInspectMenuClick: (org: string) => MenuProps["onClick"] =
    (org) => (e) => {
      onInspect(org, e.key as any);
    };

  const inspectMenuItems = [
    {
      key: "local",
      label: "Local",
    },
  ];
  if (CONSOLE_STAGING) {
    inspectMenuItems.unshift({ key: "staging", label: "Staging" });
  }

  const columns = [
    {
      width: 1000,
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/org/${record.name}`}
          >
            {text}
          </Link>
          <span className="text-sm truncate">{record.description}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text: string, record: any) => (
        <div className="flex items-center">
          <div>
            <Dropdown.Button
              disabled={isLoading}
              loading={actingOn === record.name}
              type={"primary"}
              size={"small"}
              onClick={() => onInspect(record.name, "default")}
              menu={{
                items: inspectMenuItems,
                onClick: onInspectMenuClick(record.name),
              }}
            >
              Inspect
            </Dropdown.Button>
          </div>
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => exportItem(record)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <PageHeader title={"Orgs"} subTitle={"Org List of Account"} />
        <Table
          size={"small"}
          loading={isLoading}
          dataSource={orgs}
          columns={columns}
          pagination={{ showSizeChanger: false }}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Org: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const AccountOrg = observer(AccountOrgRaw);
