import { TimelineInteractionData } from "./types";
import { Timestep } from "../../../Billing/types";
import moment from "moment-timezone";

export const EmptyTimelineInteractionData: TimelineInteractionData<any> = {
  rangeGroup: null,
  rangeGroupIndex: null,
  range: null,
  rangeIndex: null,
  trailingTick: null,
  snappedTick: null,
};

export const startInfinityISO = "1900-01-01T00:00:00.000Z";
export const endInfinityISO = "2100-01-01T00:00:00.000Z";

export const startInfinityMoment = moment(startInfinityISO);
export const endInfinityMoment = moment(endInfinityISO);

export function momentToIso(moment: moment.Moment): string | undefined {
  if (moment.isSame(startInfinityMoment) || moment.isSame(endInfinityMoment)) {
    return undefined;
  }
  return moment.toISOString();
}

export function isoToMoment(
  iso: string | null | undefined,
  type: "start" | "end"
): moment.Moment {
  if ((iso == null && iso == undefined) || iso.length < 1) {
    return type === "start" ? startInfinityMoment : endInfinityMoment;
  }
  return moment(iso);
}

export function formatByTimestep(timestep: Timestep) {
  switch (timestep) {
    case "day":
      return `DD MMM`;
    case "month":
      return `MMM 'YY`;
  }
}

export function mapRange(
  input: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
) {
  return ((input - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
}

export const availableColors = [
  "orange",
  "yellow",
  "cyan",
  "blue",
  "violet",
  "fuchsia",
  "rose",
  "red",
  "amber",
  "lime",
  "teal",
  "sky",
  "indigo",
  "purple",
  "pink",
];

export const colorToRgbMap = {
  orange: "254, 215, 170",
  yellow: "254, 240, 138",
  cyan: "165, 243, 252",
  blue: "191, 219, 254",
  violet: "233, 213, 255",
  fuchsia: "250, 232, 255",
  rose: "254, 205, 211",
  red: "254, 202, 202",
  amber: "254, 243, 199",
  lime: "217, 249, 157",
  teal: "153, 246, 228",
  sky: "186, 230, 253",
  indigo: "199, 210, 254",
  purple: "221, 214, 254",
  pink: "252, 231, 243",
};
