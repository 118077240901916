import { notification, Modal, Button, Spin, Tag, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { request } from "../../utils";
import { merge } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminProfile, getDefaultAdminProfile, OrgAcl } from "./types";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const AclDetailRaw: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // TODO param is not captured here

  const email = React.useMemo(() => pathname.split("/").pop()?.split("/").shift()!, [pathname]);
  const [adminProfile, setAdminProfile] = React.useState<AdminProfile>(getDefaultAdminProfile());
  const [orgAclList, setOrgAclList] = React.useState<OrgAcl[]>([]);
  const [isAdminProfileFetched, setIsAdminProfileFetched] = React.useState(false);
  const [isOrgAclListFetched, setIsOrgAclListFetched] = React.useState(false);

  React.useEffect(() => {
    fetch();
  }, []);

  async function fetch() {
    await Promise.all([fetchAdminProfile(), fetchOrgAcl()]);
  }

  async function fetchAdminProfile() {
    try {
      const res = await request({ url: `/admin/acl/${email}` });
      setAdminProfile(merge(getDefaultAdminProfile(), res));
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch admin profile",
        description: errorMessage,
      });
    } finally {
      setIsAdminProfileFetched(true);
    }
  }

  async function fetchOrgAcl() {
    try {
      const orgs = await request({ url: `/admin/acl/${email}/org` });
      const promises: Promise<OrgAcl>[] = [];
      for (let org of orgs) {
        promises.push(request({ url: `/admin/acl/${email}/org/${org}` }));
      }
      if (promises.length > 0) {
        const res = await Promise.all(promises);
        setOrgAclList(res);
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch org acl list",
        description: errorMessage,
      });
    } finally {
      setIsOrgAclListFetched(true);
    }
  }

  async function onDelete() {
    Modal.confirm({
      title: "Delete Admin User",
      content: "Are you sure?",
      okText: "Delete",
      okButtonProps: { danger: true, type: "primary" },
      cancelButtonProps: { danger: false, type: "default" },
      async onOk() {
        try {
          await request({ url: `/admin/acl/${email}`, method: "delete" });
          notification.success({
            message: "Success",
            description: `Deleted admin user - ${email}`,
          });
          navigate(`/admin/acl`);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to delete admin user",
            description: errorMessage,
          });
        }
      },
      onCancel() {},
    });
  }

  if (!isAdminProfileFetched || !isOrgAclListFetched) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Spin spinning size={"large"} />
      </div>
    );
  }

  function renderScope(_text: string, record: any) {
    let text = ``;
    if (record.type !== "global") {
      text += record.type.toUpperCase() + " | ";
    }
    text += record.scope;
    return text;
  }

  const columns = [
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      ellipsis: true,
      render: renderScope,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text: string) =>
        text.split(",").map((action: string) => (
          <Tag key={action} color={"blue"}>
            {action}
          </Tag>
        )),
    },
  ];

  const dataSource: { type: string; scope: string; actions: string }[] = [];

  dataSource.push({
    type: "global",
    scope: "Global Actions",
    actions:
      Object.entries(adminProfile.actions).filter(([_key, value]) => value === true).length < 1
        ? "None"
        : Object.entries(adminProfile.actions)
            .filter(([_key, value]) => value === true)
            .map(([key, _value]) => key)
            .sort()
            .join(","),
  });

  for (let orgAcl of orgAclList) {
    dataSource.push({
      type: "org",
      scope: orgAcl.org,
      actions: Object.entries(orgAcl.actions)
        .map(([key, value]) => {
          if (value === false) {
            return null;
          }
          return key;
        })
        .sort()
        .join(","),
    });
  }

  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}
          {email} - ACL - Admin Panel
        </title>
      </Helmet>
      <div className="flex items-center">
        <PageHeader title={`Admin Details - ${email}`} />
        <Button type={"primary"} onClick={() => navigate(`-edit`)} className="ml-4">
          Edit
        </Button>
        <Button danger onClick={onDelete} className="ml-4">
          Delete
        </Button>
      </div>
      <div className="mb-4 ml-6">
        <Table columns={columns} dataSource={dataSource} rowKey={"type"} />
      </div>
    </>
  );
};

export const AclDetail = observer(AclDetailRaw);
