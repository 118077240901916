import {
  Input,
  Button,
  Form,
  notification,
  Spin,
  Checkbox,
  Row,
  Col,
  Divider,
  AutoComplete,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { request } from "../../utils";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { isEqual } from "lodash";
import {
  AdminEditFormData,
  AdminProfile,
  getDefaultAdminProfile,
  OrgAcl,
  toAdminEditFormData,
  toAdminProfile,
  toOrgAclList,
} from "./types";
import { useLocation, useNavigate } from "react-router-dom";
import { useMain } from "../../stores/main";

interface Props {
  isNew?: boolean;
}
const AclDetailEditRaw: React.FC<Props> = ({ isNew = false }) => {
  const navigate = useNavigate();
  const main = useMain();
  const { pathname } = useLocation();
  // TODO email param is not captured here
  const email = React.useMemo(() => {
    const splitted = pathname.split("/");
    const email = splitted[splitted.length - 2];
    return email;
  }, [pathname]);
  const [adminProfile, setAdminProfile] = React.useState<AdminProfile>(
    getDefaultAdminProfile()
  );
  const [orgList, setOrgList] = React.useState<string[]>([]);
  const [orgAclList, setOrgAclList] = React.useState<OrgAcl[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDirty, setIsDirty] = React.useState(false);
  const [isAdminProfileFetched, setIsAdminProfileFetched] =
    React.useState(false);
  const [isOrgAclListFetched, setIsOrgAclListFetched] = React.useState(false);
  const [initialFormData, setInitialFormData] = React.useState<
    AdminEditFormData | null | undefined
  >(null);
  const [form] = Form.useForm();

  React.useEffect(() => {
    fetchOrgList();
    if (isNew) {
      setIsAdminProfileFetched(true);
      setIsOrgAclListFetched(true);
      setInitialFormData(undefined);
      return;
    }
    fetch();
  }, []);

  React.useEffect(() => {
    if (isNew) {
      return;
    }
    if (!isAdminProfileFetched) {
      return;
    }
    if (!isOrgAclListFetched) {
      return;
    }
    setInitialFormData(toAdminEditFormData(adminProfile, orgAclList));
  }, [isAdminProfileFetched, isOrgAclListFetched]);

  async function fetch() {
    if (isNew) {
      return;
    }
    await Promise.all([fetchAdminProfile(), fetchOrgAclList()]);
  }

  async function fetchAdminProfile() {
    if (isNew) {
      return;
    }
    try {
      const res = await request({ url: `/admin/acl/${email}` });
      setAdminProfile(res);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch admin profile",
        description: errorMessage,
      });
    } finally {
      setIsAdminProfileFetched(true);
    }
  }

  async function fetchOrgAclList() {
    if (isNew) {
      return;
    }
    try {
      const orgs = await request({ url: `/admin/acl/${email}/org` });
      const promises: Promise<OrgAcl>[] = [];
      for (let org of orgs) {
        promises.push(request({ url: `/admin/acl/${email}/org/${org}` }));
      }
      if (promises.length > 0) {
        const res = await Promise.all(promises);
        setOrgAclList(res);
      }
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch admins",
        description: errorMessage,
      });
    } finally {
      setIsOrgAclListFetched(true);
    }
  }

  async function fetchOrgList() {
    try {
      let nextLink = "/admin/org";
      let orgs: any[] = [];
      while (nextLink) {
        const _orgs = await request({
          service: "admin-api",
          url: nextLink,
        });
        orgs = orgs.concat(_orgs.items);
        nextLink = _orgs.links?.find((l: any) => l.rel === "next")?.href;
      }
      setOrgList(orgs.map((t: any) => t.name));
    } catch (e) {}
  }

  async function onFinish() {
    try {
      setIsLoading(true);
      const formData: AdminEditFormData = getFormState();
      await request({
        method: "post",
        url: `/admin/acl`,
        body: toAdminProfile(formData),
      });

      const orgAclList = toOrgAclList(formData);

      for (let orgAcl of orgAclList) {
        await request({
          method: "post",
          url: `/admin/acl/${formData.email}/org`,
          body: orgAcl,
        });
      }

      if (!isNew) {
        const deletedOrgs: string[] = [];
        const oldOrgs =
          initialFormData?.orgAclList.map((orgAcl) => orgAcl.org) || [];
        const currentOrgs = orgAclList.map((orgAcl) => orgAcl.org);
        for (let oldOrg of oldOrgs) {
          if (!currentOrgs.includes(oldOrg)) {
            deletedOrgs.push(oldOrg);
          }
        }
        for (let org of deletedOrgs) {
          await request({
            method: "delete",
            url: `/admin/acl/${formData.email}/org/${org}`,
          });
        }
      }

      notification.success({ message: "Success" });
      setIsLoading(false);
      navigate(`/admin/acl/${formData.email}`);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to save",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function getFormState() {
    const formState = form.getFieldsValue([
      "email",
      "globalActions",
      "orgAclList",
    ]);
    if (formState) {
      formState.globalActions = (formState.globalActions || []).filter(Boolean);
      formState.orgAclList = (formState.orgAclList || []).filter(Boolean);
    }
    return formState || {};
  }

  function calculateIsDirty() {
    setIsDirty(!isEqual(getFormState(), initialFormData));
  }

  if (
    initialFormData === null ||
    !isAdminProfileFetched ||
    !isOrgAclListFetched
  ) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Spin spinning size={"large"} />
      </div>
    );
  }

  return (
    <>
      <PageHeader title={isNew ? "Add Admin" : `Admin Details - ${email}`} />
      <Form
        form={form}
        onValuesChange={() => calculateIsDirty()}
        name="admin"
        labelCol={{ span: 2, offset: 2 }}
        wrapperCol={{ span: 10 }}
        initialValues={initialFormData}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: "email", required: true, message: "Email is required" },
          ]}
        >
          <Input
            onInput={(e) =>
              ((e.target as any).value = (e.target as any).value.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item name="globalActions" label="Global Actions">
          <Checkbox.Group>
            <Row>
              {main.globalActions.map((action) => (
                <Col key={action}>
                  <Checkbox value={action} style={{ lineHeight: "32px" }}>
                    {action}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.List name="orgAclList">
          {(fields, { add, remove }) => (
            <div>
              <Row className={"mt-12"}>
                <Col span={4}>
                  <div className={`flex justify-end mb-4 pr-2`}>
                    <div className={"font-bold uppercase"}>Org ACL List</div>
                  </div>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    onClick={() => add()}
                    size={"small"}
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div key={key}>
                  <Form.Item
                    {...restField}
                    label={"Org"}
                    name={[name, "org"]}
                    fieldKey={[fieldKey as any, "org"]}
                    rules={[{ required: true, message: "Org cannot be empty" }]}
                  >
                    <AutoComplete
                      options={orgList.map((o: any) => ({ value: o }))}
                      filterOption={(inputValue: string, option: any) =>
                        option.value.includes(inputValue)
                      }
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "actions"]}
                    fieldKey={[fieldKey as any, "actions"]}
                    rules={[
                      {
                        required: true,
                        message: "At least one action is required",
                      },
                    ]}
                    label="Actions"
                  >
                    <Checkbox.Group>
                      <Row>
                        {main.orgActions.map((action) => (
                          <Col key={action}>
                            <Checkbox
                              value={action}
                              style={{ lineHeight: "32px" }}
                            >
                              {action}
                            </Checkbox>
                          </Col>
                        ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                  <Row>
                    <Col span={2} offset={12}>
                      <Button
                        type="primary"
                        danger
                        block
                        onClick={() => remove(name)}
                        icon={<MinusCircleOutlined />}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={10} offset={4}>
                      <Divider />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          )}
        </Form.List>

        <Row className={"mb-12"}>
          <Col span={4} offset={4}>
            <Button
              className={"w-full"}
              type="default"
              htmlType="button"
              disabled={isLoading}
              onClick={() => navigate(`/admin/acl${isNew ? "" : `/${email}`}`)}
            >
              Cancel
            </Button>
          </Col>
          <Col span={4} offset={1}>
            <Button
              className={"w-full"}
              type="primary"
              htmlType="submit"
              disabled={!isDirty || isLoading}
              loading={isLoading}
            >
              {isNew ? "Add Admin" : "Save Changes"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export const AclDetailEdit = observer(AclDetailEditRaw);
