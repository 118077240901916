import { Button, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { formatTimestamp, request } from "../../utils";
import { JSONModal } from "../../jsonModal";

interface Props {
  user: any;
  fetchUser: any;
}
const UserInfoRaw: React.FC<Props> = ({ user, fetchUser }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [isViewing, setIsViewing] = React.useState(false);
  const [action, setAction] = React.useState<"logOut" | "enableDisable" | "">(
    ""
  );

  async function onUserAction(action: "logout" | "enable" | "disable") {
    try {
      setIsLoading(true);
      setAction(action === "logout" ? "logOut" : "enableDisable");
      let url = "";
      let method: any = "";
      let body: any = undefined;
      if (action === "logout") {
        url = `/admin/firebase/user/${user.uid}/-logout`;
        method = "post";
      } else {
        url = `/admin/firebase/user/${user.uid}`;
        method = "patch";
        body = { disabled: action === "enable" ? false : true };
      }
      await request({
        url,
        method,
        body,
      });
      let description = "";
      if (action === "logout") {
        description = `Logged out user "${user.email}" from all devices`;
      } else if (action === "enable") {
        description = `Enabled user "${user.email}"`;
      } else if (action === "disable") {
        description = `Disabled user "${user.email}"`;
      }
      fetchUser();
      notification.success({
        message: "Success",
        description,
      });
      setAction("");
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch users",
        description: errorMessage,
      });
      setAction("");
      setIsLoading(false);
    }
  }

  async function exportItem() {
    const file = new Blob([JSON.stringify(user, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${user.email}.json`);
    aElement.click();
  }

  return (
    <>
      <div className="px-8">
        <PageHeader title={user.email} subTitle={"User Detail - Info"} />
        <div className="px-6">
          <div className="mb-4">
            <div className="text-base text-gray-700 font-semibold">Actions</div>
            <div className="flex items-center gap-2">
              {user.disabled ? (
                <Button
                  onClick={() => onUserAction("enable")}
                  type={"primary"}
                  disabled={isLoading}
                  loading={action === "enableDisable"}
                >
                  Enable
                </Button>
              ) : (
                <Button
                  onClick={() => onUserAction("disable")}
                  type={"primary"}
                  danger
                  disabled={isLoading}
                  loading={action === "enableDisable"}
                >
                  Disable
                </Button>
              )}
              <Button
                onClick={() => onUserAction("logout")}
                type={"primary"}
                danger
                disabled={isLoading}
                loading={action === "logOut"}
              >
                Log Out
              </Button>
              <Button onClick={() => setIsViewing(true)}>View</Button>
              <Button onClick={() => exportItem()}>Export</Button>
            </div>
          </div>
          <div className="text-base text-gray-700 font-semibold">Email</div>
          <div className="mb-4 mt-1">{user.email}</div>
          <div className="text-base text-gray-700 font-semibold">
            Display Name
          </div>
          <div className="mb-4 mt-1">{user.displayName || "None"}</div>
          <div className="text-base text-gray-700 font-semibold">
            Disabled Status
          </div>
          <div className="mb-4 mt-1">
            {user.disabled === true ? "Disabled" : "Enabled"}
          </div>
          <div className="text-base text-gray-700 font-semibold">
            Custom Claims
          </div>
          <div className="mb-4 mt-1">
            {JSON.stringify(user.customClaims || {}, null, 2)}
          </div>
          <div className="text-base text-gray-700 font-semibold">
            Last Sign In
          </div>
          <div className="mb-4 mt-1">
            {formatTimestamp(user.metadata.lastSignInTime)}
          </div>
        </div>
      </div>
      {isViewing ? (
        <JSONModal
          filename={user.email}
          onClose={() => setIsViewing(false)}
          title={`User: ${user.email}`}
          visible={!!isViewing}
          object={user}
        />
      ) : null}
    </>
  );
};

export const UserInfo = observer(UserInfoRaw);
