import { ExclamationCircleOutlined } from "@ant-design/icons";
import { notification, Button, Tag, Table, Modal, Select, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { useMain } from "../../stores/main";
import { formatTimestamp, request } from "../../utils";

// Users are with email here, but the api works with both uid and email

type RouteParams = "uuid";
const AccountUserRaw: React.FC = () => {
  const main = useMain();
  const { uuid } = useParams<RouteParams>();
  const [users, setUsers] = React.useState<any[]>([]);
  const [viewingItem, setViewingItem] = React.useState<any>(null);
  const [editedUser, setEditedUser] = React.useState<any>(null);
  const [editedUserRoles, setEditedUserRoles] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem]);

  async function fetchUsers() {
    try {
      setIsLoading(true);
      let nextLink = `/account/${uuid}/user`;
      let _users: any[] = [];
      while (nextLink) {
        const res = await request({ service: "billing-ng", url: nextLink });
        _users = _users.concat(res.items);
        nextLink = res.links.find((link: any) => link.rel === "next")?.href;
      }
      setUsers(_users);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch users of this account",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function onEditUserRoles(record: any) {
    setEditedUser(record);
    setEditedUserRoles(record.roles);
  }

  async function onConfirmEditUserRoles() {
    try {
      setIsLoading(true);
      await request({
        service: "billing-ng",
        method: "put",
        url: `/account/${uuid}/user/email/${editedUser.email}`,
        body: { user: { email: editedUser.email, roles: editedUserRoles } },
      });
      notification.success({
        message: "Success",
        description: "Updated roles of the user",
      });
      setIsLoading(false);
      onCancelEditUserRoles();
      fetchUsers();
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to updated user roles",
        description: errorMessage,
      });
    }
  }

  function onCancelEditUserRoles() {
    setEditedUser(null);
    setEditedUserRoles([]);
  }

  function onRemoveUser(record: any) {
    Modal.confirm({
      title: "Are you sure you want to delete this user?",
      icon: <ExclamationCircleOutlined />,
      content:
        "This user will lose all access to this account, org access will not be affected.",
      async onOk() {
        try {
          setIsLoading(true);
          await request({
            service: "billing-ng",
            method: "delete",
            url: `/account/${uuid}/user/email/${record.email}`,
          });
          notification.success({
            message: "Success",
            description: "Deleted the user",
          });
          setIsLoading(false);
          fetchUsers();
        } catch (e) {
          setIsLoading(false);
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to delete the user",
            description: errorMessage,
          });
        }
      },
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  function onAddUser() {
    Modal.confirm({
      title: "Add User",
      content: (
        <>
          <p>
            User will be added with "billing_viewer" role, you can edit it
            later.
          </p>
          <Input
            id={"addUserInput"}
            placeholder={"Email"}
            onInput={(e) =>
              ((e.target as any).value = (e.target as any).value.toLowerCase())
            }
          />
        </>
      ),
      async onOk() {
        try {
          setIsLoading(true);
          const inputElement = document.getElementById("addUserInput");
          const email = (inputElement as any).value;
          await request({
            service: "billing-ng",
            method: "post",
            url: `/account/${uuid}/user`,
            body: { user: { email, roles: ["billing_viewer"] } },
          });
          notification.success({
            message: "Success",
            description: "Added new user",
          });
          setIsLoading(false);
          fetchUsers();
        } catch (e) {
          setIsLoading(false);
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to add new user",
            description: errorMessage,
          });
        }
      },
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/user/${record.email}`}
          >
            {text}
          </Link>
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: any) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (_: string, record: any) => (
        <div className="flex flex-wrap">
          {record.roles.map((role: string) => (
            <Tag key={role}>{role}</Tag>
          ))}
          <Button
            onClick={() => onEditUserRoles(record)}
            type={"primary"}
            size={"small"}
          >
            Edit
          </Button>
        </div>
      ),
    },
    {
      title: "Remove User",
      dataIndex: "removeUser",
      key: "removeUser",
      render: (_: string, record: any) => (
        <Button
          onClick={() => onRemoveUser(record)}
          type={"default"}
          danger
          size={"small"}
        >
          Remove
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <div className="flex items-center">
          <PageHeader title={"Users"} subTitle={"Account Detail - Users"} />
          <Button type={"primary"} size={"small"} onClick={() => onAddUser()}>
            Add User
          </Button>
        </div>
        <Table
          loading={isLoading}
          dataSource={users}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"email"}
        />
      </div>
      <Modal
        title={"Edit Quota"}
        open={editedUser !== null}
        onOk={onConfirmEditUserRoles}
        onCancel={onCancelEditUserRoles}
        okText={"Save"}
        okButtonProps={{
          disabled: isLoading || editedUserRoles.length < 0,
          loading: isLoading,
        }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <div className="font-semibold text-lg mb-2">{editedUser?.email}</div>
        <Select
          className={"w-full"}
          mode={"multiple"}
          onChange={(values: any) => setEditedUserRoles(values)}
          value={editedUserRoles}
          options={[
            { value: "billing_admin" },
            { value: "billing_viewer" },
            { value: "org_creator" },
          ]}
        />
      </Modal>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`User: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const AccountUser = observer(AccountUserRaw);
