import * as React from "react";
import { observer } from "mobx-react-lite";
import { Clipboard } from "react-feather";
import { CookieReferral, Partner } from "./types";
import { Button, notification } from "antd";
import { JSONModal } from "../../jsonModal";

interface Props {
  partner: Partner;
}
const ExpiredCookieReferralListRaw: React.FC<Props> = ({ partner }) => {
  const [viewingItem, setViewingItem] = React.useState<CookieReferral | null>(
    null
  );

  return (
    <>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Expired Cookie Referral: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
      <div className="flex flex-col w-full px-8">
        <div className="text-base text-gray-700 font-semibold mb-4">
          Expired Cookie Referrals ({partner.expiredCookieReferrals.length})
        </div>
        <div className="w-full">
          <div className="flex items-center px-4 py-2 border border-b-0 font-semibold bg-gray-200">
            <div className="w-6/12">Email & Id</div>
            <div className="w-4/12">Account Id & Token</div>
            <div className="w-2/12">Actions</div>
          </div>
          <div className="flex flex-col mb-4 mt-1">
            {partner.expiredCookieReferrals.length === 0 ? (
              <div>No Cookie Referrals</div>
            ) : null}
            {partner.expiredCookieReferrals.map((r, index) => (
              <div
                className={`flex items-center px-4 py-2 ${
                  index !== partner.expiredCookieReferrals.length - 1
                    ? "border-b"
                    : ""
                } ${index % 2 === 1 ? "bg-gray-50" : ""}`}
              >
                <div className="w-6/12 flex flex-col">
                  <div>{r.email}</div>
                  <div className="text-sm">{r.id}</div>
                </div>
                <div className="w-4/12 flex flex-col">
                  <div className="flex gap-1">
                    <span className="text-xs">{r.accountId}</span>
                    <span>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(r.accountId);
                          notification.success({
                            message: "Copied to clipboard",
                          });
                        }}
                      >
                        <Clipboard className="w-4 h-4" />
                      </button>
                    </span>
                  </div>
                  <div>{r.cookie}</div>
                </div>
                <div className="w-2/12 flex gap-1">
                  <Button
                    size={"small"}
                    type={"default"}
                    onClick={() => setViewingItem(r)}
                  >
                    View
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const ExpiredCookieReferralList = observer(ExpiredCookieReferralListRaw);
