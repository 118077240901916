import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { PricingCalculatorSubmissionDetail } from "./Pages/Detail";
import { PricingCalculatorSubmissionsList } from "./Pages/List";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const PricingCalculatorSubmissionsIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Pricing Calculator - Admin Panel
        </title>
      </Helmet>
      <Routes>
        <Route
          path={`:submissionID/*`}
          element={<PricingCalculatorSubmissionDetail />}
        />
        <Route index element={<PricingCalculatorSubmissionsList />} />
      </Routes>
    </>
  );
};

export const PricingCalculatorSubmissionsIndex = observer(
  PricingCalculatorSubmissionsIndexRaw
);
