import { Timestep } from "../../Billing/types";
import moment from "moment-timezone";

export function getAccountStateStartEndTimes(timestep: Timestep) {
  switch (timestep) {
    case "day":
      return [
        moment().startOf("month").subtract(1, "month"),
        moment().startOf("month").add(1, "month"),
      ];

    case "month":
      return [
        moment().startOf("month").subtract(6, "month"),
        moment().startOf("month").add(6, "month"),
      ];
  }
}

export function getBuffer(timestep: Timestep) {
  switch (timestep) {
    case "day":
      return moment.duration(12, "hour");
    case "month":
      return moment.duration(15, "day");
  }
}

export const PAYMENT_METHOD_OPTIONS = [
  { value: "invoice", label: "Invoice" },
  { value: "credit-card", label: "Credit Card" },
];

export const ACCOUNT_STATE_TYPE_OPTIONS = [
  { value: "paying", name: "Paying" },
  { value: "trial", name: "Trial" },
];
