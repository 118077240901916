import * as React from "react";
import { Table, notification, Input, Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { request } from "../../utils";
import { LocationEdit } from "./LocationEdit";
import { useDebounce } from "../../useDebounce";

const PAGE_SIZE = 10;

const LocationListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [locations, setLocations] = React.useState<any[]>([]);
  const [search, setSearch] = React.useState("");
  const [debouncedSearch, setDebouncedSearch] = useDebounce("", 150);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [editLocationModal, setEditLocationModal] = React.useState<
    | {
        name: string;
        ipRanges: string[];
      }
    | undefined
  >(undefined);

  const dataSource = locations.filter((loc) => {
    const searchLower = debouncedSearch.toLowerCase();
    const nameLower = loc.name.toLowerCase();
    const descriptionLower = loc.description.toLowerCase();
    const providerLower = loc.provider.toLowerCase();
    const originLower = loc.origin.toLowerCase();
    const regionLower = loc.region.toLowerCase();

    // Check if any attribute starts with the search value
    if (
      nameLower.startsWith(searchLower) ||
      descriptionLower.startsWith(searchLower) ||
      providerLower.startsWith(searchLower) ||
      originLower.startsWith(searchLower) ||
      regionLower.startsWith(searchLower)
    ) {
      return true;
    }

    // Check if any attribute contains the search value
    if (
      nameLower.includes(searchLower) ||
      descriptionLower.includes(searchLower) ||
      providerLower.includes(searchLower) ||
      originLower.includes(searchLower) ||
      regionLower.includes(searchLower)
    ) {
      return true;
    }

    return false;
  });

  React.useEffect(() => {
    fetchLocationCustomData(currentPage || 1, dataSource);
  }, [currentPage, dataSource.length]);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch]);

  React.useEffect(() => {
    fetchLocations();
  }, []);

  React.useEffect(() => {
    setDebouncedSearch(search);
  }, [search]);

  async function fetchLocations() {
    try {
      setIsLoading(true);
      const res = await request({ url: "/admin/locations/all" });
      setLocations(res.items);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch locations",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const fetchLocationCustomData = async (
    currentPage: number,
    localLocations?: any[]
  ) => {
    try {
      let locationsInPage: any[] = localLocations || locations;
      if (currentPage) {
        const startIndex = (currentPage - 1) * PAGE_SIZE;
        const endIndex = startIndex + PAGE_SIZE;
        locationsInPage = locationsInPage.slice(startIndex, endIndex);
      }

      const promises: Promise<any>[] = [];
      for (let location of locationsInPage) {
        if (location.status.ipRanges) {
          continue;
        }
        promises.push(
          request({
            url: `/admin/locations/cust/${location.name}`,
          }).then((custLocation) => {
            if (custLocation.data.ipRanges) {
              location.status.ipRanges = custLocation.data.ipRanges;
            }
          })
        );
      }
      await Promise.all(promises);

      const _locations = locations.map((location) => {
        for (let _location of locationsInPage) {
          if (location.name === _location.name) {
            return _location;
          }
        }
        return location;
      });

      setLocations(_locations);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch ipRanges",
        description: errorMessage,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => (
        <div className="flex flex-col">
          <span className="text-base truncate">{text}</span>
        </div>
      ),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      ellipsis: true,
    },
    {
      title: "Ip Range",
      dataIndex: "ipRanges",
      key: "ipRanges",
      ellipsis: true,
      render: (_text: string, record: any) => (
        <div className="flex flex-col text-xs truncate">
          {record.status.ipRanges?.length > 0
            ? record.status.ipRanges.map((ipRange) => (
                <span key={ipRange}>{ipRange}</span>
              ))
            : "-"}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text: string, _record: any) => (
        <Button
          disabled={isLoading}
          onClick={() => {
            setEditLocationModal({
              name: _record.name,
              ipRanges: _record.status.ipRanges || [],
            });
          }}
          type={"primary"}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center gap-4">
        <PageHeader title={"Locations"} backIcon={false} />
        <Input
          style={{ width: 450 }}
          value={search}
          placeholder={"Filter"}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={dataSource.map((loc: any) => ({
            ...loc,
            key: loc.name,
          }))}
          columns={columns as any}
          pagination={{
            current: currentPage,
            showSizeChanger: false,
            total: dataSource.length,
          }}
          onChange={(pagination, _filters, _sorter) =>
            setCurrentPage(pagination.current || 1)
          }
          rowKey={"name"}
        />
      </div>
      {!!editLocationModal ? (
        <LocationEdit
          {...editLocationModal}
          onSubmit={async () => {
            let _locations: any[] = locations;
            for (let location of _locations) {
              if (editLocationModal.name !== location.name) {
                continue;
              }
              const custLocation = await request({
                url: `/admin/locations/cust/${location.name}`,
              });
              if (custLocation.data.ipRanges) {
                location.status.ipRanges = custLocation.data.ipRanges;
              }
            }
            setLocations(_locations);
            setEditLocationModal(undefined);
          }}
          onClose={() => setEditLocationModal(undefined)}
        />
      ) : null}
    </>
  );
};

export const LocationList = observer(LocationListRaw);
