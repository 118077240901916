import * as React from "react";
import { Menu, notification, Spin } from "antd";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
  NavLink,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { request } from "../../utils";
import { VideoInfo } from "./VideoInfo";

type RouteParams = "uid";
const VideoDetailRaw: React.FC = () => {
  const { uid } = useParams<RouteParams>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [video, setVideo] = React.useState<any>(null);

  React.useEffect(() => {
    fetchVideo();
  }, [uid]);

  async function fetchVideo() {
    try {
      const videoRes = await request({
        service: "video",
        url: `/video/${uid}`,
      });
      setVideo(videoRes);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch video",
        description: errorMessage,
      });
      navigate("/admin/educational-videos");
    }
  }

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    switch (true) {
      case pathname.includes("/info"):
        _menuActiveKey = "info";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  if (!video) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  return (
    <div className="flex p-8">
      <Menu theme={"light"} selectedKeys={[menuActiveKey]}>
        <Menu.Item key={"info"}>
          <NavLink to={`info`}>Info</NavLink>
        </Menu.Item>
      </Menu>
      <Routes>
        <Route path={`info`} element={<VideoInfo video={video} />} />
        <Route index element={<Navigate to={`info`} replace />} />
      </Routes>
    </div>
  );
};

export const VideoDetail = observer(VideoDetailRaw);
