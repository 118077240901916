import { Button, Table, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { useMain } from "../../stores/main";
import { formatTimestamp } from "../../utils";
import { IUserAccount } from "./types";

interface Props {
  user: any;
  accounts: IUserAccount[];
}
const UserRawAccount: React.FC<Props> = ({ accounts }) => {
  const main = useMain();
  const [viewingItem, setViewingItem] = React.useState<any>(null);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem]);

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const columns = [
    {
      title: "Account ID",
      dataIndex: "accountUUID",
      key: "accountUUID",
      render: (text: string, record: IUserAccount) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/account/${record.accountUUID}`}
          >
            {record.accountName}
          </Link>
          <span className="text-xs">{text}</span>
        </div>
      ),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      key: "roles",
      render: (_roles: string[], record: IUserAccount) => (
        <>
          {record.roles.map((role) => {
            return <Tag key={role}>{role}</Tag>;
          })}
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: IUserAccount) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_text: string, record: any) => (
        <div className="flex items-center">
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => exportItem(record)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <PageHeader title={"Accounts"} subTitle={"Account List of User"} />
        <Table
          size={"small"}
          dataSource={accounts}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"accountUUID"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Account: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const UserAccount = observer(UserRawAccount);
