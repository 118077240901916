import { notification, Button, MenuProps, Dropdown } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { InspectImpersonateType, onInspectOrg } from "../../utils";
import { CONSOLE_STAGING } from "../../envVariables";

type RouteParams = "org";
const OrgInspectRaw: React.FC = () => {
  const { org: _org } = useParams<RouteParams>();
  const org = _org!;

  const [isLoading, setIsLoading] = React.useState(false);

  async function onInspect(type: InspectImpersonateType) {
    try {
      setIsLoading(true);
      await onInspectOrg(org, type);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch inspect org token",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const onInspectMenuClick: MenuProps["onClick"] = (e) => {
    onInspect(e.key as any);
  };

  const inspectMenuItems = [
    {
      key: "local",
      label: "Local",
    },
  ];
  if (CONSOLE_STAGING) {
    inspectMenuItems.unshift({ key: "staging", label: "Staging" });
  }

  return (
    <div className="px-8">
      <PageHeader title={org} subTitle={"Org Detail - Inspect"} />
      <div className="px-6">
        <div className="text-base text-gray-700 font-semibold">
          Inspect allows you to browse a read only version of this org and it's
          sub items in the Console Dashboard
        </div>
        <div>
          <Dropdown.Button
            disabled={isLoading}
            loading={isLoading}
            className="mt-4"
            onClick={() => onInspect("default")}
            menu={{ items: inspectMenuItems, onClick: onInspectMenuClick }}
          >
            Inspect
          </Dropdown.Button>
        </div>
      </div>
    </div>
  );
};

export const OrgInspect = observer(OrgInspectRaw);
