import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Video } from "./types";

interface Props {
  video: Video;
}
const VideoInfoRaw: React.FC<Props> = ({ video }) => {
  return (
    <div className="px-8">
      <PageHeader title={video.title} subTitle={"Video Detail - Info"} />
      <div className="px-6">
        <div className="text-base text-gray-700 font-semibold">title</div>
        <div className="mb-4 mt-1">{video.title}</div>
        <div className="text-base text-gray-700 font-semibold">Description</div>
        <div className="mb-4 mt-1">{video.description}</div>
      </div>
    </div>
  );
};

export const VideoInfo = observer(VideoInfoRaw);
