import axios, { AxiosRequestConfig } from "axios";
import { baseURL } from "./baseUrl";
import { main } from "./stores/main";
import { humanizer } from "humanize-duration";
import {
  DATA_API,
  VIDEO_API,
  EXTERNAL_SIGNUP_API,
  CONSOLE,
  CONSOLE_LOCAL,
  CONSOLE_STAGING,
} from "./envVariables";
import { merge } from "lodash";
import { getAuth } from "firebase/auth";

export async function getLocalToken(): Promise<{
  accessToken: string;
  expirationTime: number;
}> {
  return new Promise((resolve) => {
    const conn = window.indexedDB.open("firebaseLocalStorageDb");
    conn.onsuccess = function () {
      const db = conn.result;
      const objectStoreName = db.objectStoreNames[0];
      var tx = db.transaction([objectStoreName], "readonly");
      tx.onerror = function () {
        resolve({
          accessToken: "pass",
          expirationTime: new Date().getTime() * 2,
        });
      };
      var objectStore = tx.objectStore(objectStoreName);

      const getMainAppRequest = objectStore.get(
        `firebase:authUser:${main.discovery.firebase.apiKey}:main`
      );
      getMainAppRequest.onsuccess = function () {
        try {
          const { accessToken, expirationTime } =
            getMainAppRequest.result.value.stsTokenManager;
          resolve({ accessToken, expirationTime });
        } catch (_) {
          resolve({
            accessToken: "pass",
            expirationTime: new Date().getTime() * 2,
          });
        }
      };
      getMainAppRequest.onerror = function () {
        resolve({
          accessToken: "pass",
          expirationTime: new Date().getTime() * 2,
        });
      };
    };

    conn.onerror = function () {
      resolve({
        accessToken: "pass",
        expirationTime: new Date().getTime() * 2,
      });
    };
  });
}

export async function isTokenValid() {
  try {
    const { expirationTime } = await getLocalToken();
    const currentTimeInMS = new Date().getTime();
    const fifteenMinutesInMs = 60 * 60 * 1000;
    const remainingSec = Math.round((expirationTime - currentTimeInMS) / 1000);

    let isValid =
      currentTimeInMS - main.lastActivityTimestamp < fifteenMinutesInMs;
    return remainingSec > 1 && isValid;
  } catch (e) {
    return false;
  }
}

export async function doPeriodicTokenExpirationCheck() {
  const isValid = await isTokenValid();
  if (!isValid) {
    main.signOut();
  }
}

export async function getToken() {
  const shouldRefresh = await isTokenInLast55Mins();
  const firebaseToken = await getAuth(main.firebaseApp).currentUser?.getIdToken(
    shouldRefresh
  );
  return firebaseToken || "";
}

export async function isTokenInLast55Mins() {
  try {
    const { accessToken, expirationTime } = await getLocalToken();
    if (accessToken === "pass") {
      return false;
    }
    const remainingSec = Math.round(
      (expirationTime - new Date().getTime()) / 1000
    );
    const result = remainingSec < 55 * 60;
    return result;
  } catch (e) {
    return false;
  }
}

export type HTTPMethod = "delete" | "get" | "post" | "put" | "patch";
interface RequestParams {
  service?:
    | "custom"
    | "admin-api"
    | "grafana"
    | "billing-ng"
    | "data"
    | "video"
    | "external-signup";
  method?: HTTPMethod;
  url: string;
  refresh?: boolean;
  body?: any;
  cancelToken?: any;
  headers?: any;
}
export async function request<T = any>({
  service = "admin-api",
  method = "get",
  url,
  refresh = true,
  body,
  cancelToken,
  headers,
}: RequestParams): Promise<T> {
  let axiosBaseURL = baseURL;
  if (main.discovery && service !== "custom") {
    axiosBaseURL =
      service === "admin-api"
        ? baseURL
        : service === "data"
        ? DATA_API
        : service === "video"
        ? VIDEO_API
        : service === "external-signup"
        ? EXTERNAL_SIGNUP_API
        : main.discovery.endpoints[service];
  }
  try {
    let token = "";
    if (!refresh) {
      const { accessToken } = await getLocalToken();
      if (accessToken === "pass") {
        throw new Error("Could not access local token");
      }
      token = accessToken;
    } else {
      token = await getToken();
    }
    const cfg: AxiosRequestConfig = {
      maxContentLength: 128 * 1024,
      url: service === "custom" ? url : `${axiosBaseURL}${url}`,
      method,
    };
    if (main.isLoggedIn) {
      const _headers = headers || {};
      const mergedHeaders = merge(
        { Authorization: `Bearer ${token}` },
        _headers
      );
      cfg.headers = mergedHeaders;
      main.updateLastActivityTimestamp();
    }
    if (body) {
      cfg.data = body;
    }
    if (cancelToken) {
      cfg.cancelToken = cancelToken;
    }
    const res: any = await axios(cfg);
    return res.data as Promise<T>;
  } catch (e) {
    throw e;
  }
}

export function formatTimestamp(ts: string) {
  let date = new Date(ts);
  if (isNaN(date.getTime())) {
    return ts;
  }

  let opt = "age";
  switch (opt) {
    case "age":
      return (
        humanizer({
          round: true,
          units: ["y", "mo", "w", "d", "h", "m"],
          largest: 1,
        })(new Date().getTime() - date.getTime()) + " ago"
      );
    case "iso":
      return date.toUTCString();
    case "local":
      return date.toLocaleString();
    default:
      return date.toUTCString();
  }
}

export function formatTimestampToDateFormat(timestamp: string): string {
  let date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    return timestamp;
  }

  return formatDate(date);
}

export function formatDate(date: Date): string {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = getNumberWithLeadingZero(date.getDate());
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  const hours = getNumberWithLeadingZero(date.getHours());
  const minutes = getNumberWithLeadingZero(date.getMinutes());

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

export function getNumberWithLeadingZero(input: number): string {
  // The `slice(-2)` method takes the last two characters of the string, effectively
  // chopping off the leading zero if the number has two digits. Or leaving the leading
  // zero intact if the number has only one digit.
  return `0${input}`.slice(-2);
}

export function capitalizeFirstCharacterOfString(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export async function sleep(seconds: number) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), seconds * 1000);
  });
}

export function copyOf(item: any) {
  return JSON.parse(JSON.stringify(item));
}

export type InspectImpersonateType = "default" | "local" | "staging";

export interface OrgInspectRes {
  expires: number;
  key: string;
  name: string;
  org: string;
}

function getConsoleEndpoint(type: InspectImpersonateType) {
  if (type === "default") {
    return CONSOLE;
  }
  if (type === "local") {
    return CONSOLE_LOCAL;
  }
  if (type === "staging") {
    return CONSOLE_STAGING || CONSOLE;
  }
  return CONSOLE;
}

export async function onInspectOrg(org: string, type: InspectImpersonateType) {
  if (!org) {
    console.error("No org to inspect");
    return;
  }
  const res: OrgInspectRes = await request({
    url: `/admin/org/${org}/-inspect`,
    method: "post",
  });
  const hash = `type=org&expires=${res.expires}&key=${res.key}&name=${res.name}&org=${res.org}`;
  const consoleEndpoint = getConsoleEndpoint(type);
  const url = `${consoleEndpoint}/#${encodeURI(hash)}`;
  window.open(url);
}

interface UserImpersonateRes {
  name: string;
  expires: number;
  impersonatedUserEmail: string;
  key: string;
}

export async function onImpersonateUser(
  org: string,
  email: string,
  type: InspectImpersonateType
) {
  const res: UserImpersonateRes = await request({
    url: `/admin/org/${org}/user/${email}/-impersonate`,
    method: "post",
  });
  const hash = `type=user&expires=${res.expires}&key=${res.key}&name=${res.name}&impersonatedUserEmail=${res.impersonatedUserEmail}&org=${org}`;
  const consoleEndpoint = getConsoleEndpoint(type);
  const url = `${consoleEndpoint}/#${encodeURI(hash)}`;
  window.open(url);
}

export function formatCentsToCurrency(
  cents: number,
  currency: string = "USD"
): string {
  const dollars = cents / 100;
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 25,
  });
  return formatter.format(dollars);
}

export function removeDecimalZeros(value: string): string {
  if (value.endsWith(".00")) {
    return value.slice(0, -3);
  }
  return value;
}
