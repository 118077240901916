import {
  Alert,
  Button,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Table,
} from "antd";
import * as React from "react";
import { HTTPMethod, request } from "../../utils";
import { ChargeableItem } from "./types";

interface ModalDataType {
  data: Omit<ChargeableItem, "id">;
  isLoading: boolean;
  isOpen: boolean;
  currentItemId: string | null;
  preferredItemId: string | null;
}

const initialModalState: ModalDataType = {
  data: {
    name: "",
    description: "",
    consumptionTags: {},
    unit: "",
  },
  isLoading: false,
  isOpen: false,
  currentItemId: null,
  preferredItemId: null,
};

interface Action {
  type:
    | "set"
    | "reset"
    | "loading"
    | "data"
    | "currentItemId"
    | "preferredItemId"
    | "name"
    | "description"
    | "consumptionTags"
    | "unit";
  payload: any;
}

function modalReducer(state: ModalDataType, action: Action) {
  switch (action.type) {
    case "set":
      return action.payload;
    case "reset":
      return initialModalState;
    case "loading":
      return { ...state, loading: action.payload };
    case "data":
      return { ...state, data: action.payload };
    case "currentItemId":
      return { ...state, currentItemId: action.payload };
    case "preferredItemId":
      return { ...state, preferredItemId: action.payload };
    default:
      return {
        ...state,
        data: { ...state.data, [action.type]: action.payload },
      };
  }
}

export const BillingChargableItems: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [items, setItems] = React.useState<ChargeableItem[]>([]);

  const [modalData, dispatchModalAction] = React.useReducer(
    modalReducer,
    initialModalState
  );

  React.useEffect(() => {
    fetchItems();
  }, []);

  async function fetchItems() {
    try {
      setIsLoading(true);
      const items = await request({
        service: "billing-ng",
        url: "/chargeable_items",
      });
      setItems(items);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to Fetch Chargable Items",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_: any, record: ChargeableItem) => {
        return (
          <div className={`flex items-center justify-end gap-4`}>
            <Button
              size={"small"}
              onClick={() =>
                dispatchModalAction({
                  type: "set",
                  payload: {
                    data: {
                      name: record.name,
                      description: record.description,
                      consumptionTags: record.consumptionTags,
                      unit: record.unit,
                    },
                    isLoading: false,
                    isOpen: true,
                    currentItemId: record.id,
                    preferredItemId: null,
                  },
                })
              }
            >
              Edit
            </Button>
            <Popconfirm
              title={"Are you sure you want to delete this item?"}
              onConfirm={() => deleteConfirm(record.id)}
              disabled={isLoading}
            >
              <Button disabled={isLoading} size={"small"} danger>
                Delete
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  async function confirm() {
    try {
      dispatchModalAction({ type: "loading", payload: true });
      let url = "/chargeable_items";
      let method: HTTPMethod = "post";
      if (modalData.currentItemId) {
        url += `/${modalData.currentItemId}`;
        method = "put";
      }
      await request({
        service: "billing-ng",
        url: url,
        method: method,
        body: modalData.data,
      });
      onClear();
      notification.success({
        message: "Success",
      });
      fetchItems();
    } catch (e) {
      dispatchModalAction({ type: "loading", payload: false });
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to add chargable item",
        description: errorMessage,
      });
    }
  }

  async function deleteConfirm(id: string) {
    try {
      setIsLoading(true);
      await request({
        service: "billing-ng",
        url: `/chargeable_items/${id}`,
        method: "delete",
      });
      notification.success({
        message: "Success",
        description: "Deleted chargeable item",
      });
      setIsLoading(false);
      fetchItems();
    } catch (e) {
      setIsLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to delete chargable item",
        description: errorMessage,
      });
    }
  }

  function onClear() {
    dispatchModalAction({ type: "reset", payload: undefined });
  }

  return (
    <div className={`pr-8`}>
      <div className={`mb-4 flex items-center gap-4`}>
        <Button
          onClick={() =>
            dispatchModalAction({
              type: "set",
              payload: {
                data: {
                  name: "",
                  description: "",
                  consumptionTags: {},
                  unit: "",
                },
                isLoading: false,
                isOpen: true,
                preferredItemId: "",
                currentItemId: null,
              },
            })
          }
          type={"primary"}
        >
          Add Chargable Item
        </Button>
        <Button onClick={fetchItems} type={"default"}>
          Refresh
        </Button>
        <Alert
          message={
            "Changes might take time to reflect in the UI, because of database replication"
          }
        />
      </div>
      <Table
        loading={isLoading}
        bordered
        size={"small"}
        dataSource={items}
        columns={columns as any}
        pagination={false}
        rowKey={"id"}
      />
      <Modal
        open={modalData.isOpen}
        onCancel={onClear}
        onOk={confirm}
        cancelButtonProps={{ disabled: modalData.isLoading }}
        okButtonProps={{
          disabled: modalData.isLoading,
          loading: modalData.isLoading,
        }}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            {modalData.preferredItemId !== null ? (
              <>
                <Button
                  type="primary"
                  disabled={!modalData.preferredItemId}
                  onClick={() => {
                    const foundReferredItem = items.find(
                      (i) => i.id === modalData.preferredItemId
                    );
                    if (!foundReferredItem) {
                      notification.warning({
                        message: "Failed to find reference item",
                      });
                      dispatchModalAction({
                        type: "set",
                        payload: {
                          ...modalData,
                          preferredItemId: null,
                        },
                      });
                    } else {
                      const { id, ...newData } = foundReferredItem;
                      dispatchModalAction({
                        type: "set",
                        payload: {
                          ...modalData,
                          data: newData,
                          preferredItemId: null,
                        },
                      });
                    }
                  }}
                >
                  Create prefilled
                </Button>
                <Button
                  type="primary"
                  onClick={() =>
                    dispatchModalAction({
                      type: "set",
                      payload: {
                        ...modalData,
                        preferredItemId: null,
                      },
                    })
                  }
                >
                  Create new
                </Button>
              </>
            ) : (
              <OkBtn />
            )}
          </>
        )}
      >
        {modalData.preferredItemId !== null ? (
          <div className={`mb-4`}>
            <span className={`mb-1`}>Select a Chargable Item to Prefill</span>
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              value={modalData.preferredItemId}
              onChange={(value: string) =>
                dispatchModalAction({
                  type: "preferredItemId",
                  payload: value,
                })
              }
              filterOption={(
                input: string,
                option?: { label: string; value: string }
              ) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={items.map((i) => ({ label: i.name, value: i.id }))}
            />
          </div>
        ) : (
          <>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Name</span>
              <Input
                value={modalData.data.name}
                onChange={(e) =>
                  dispatchModalAction({
                    type: "name",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Description</span>
              <Input
                value={modalData.data.description}
                onChange={(e) =>
                  dispatchModalAction({
                    type: "description",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Unit</span>
              <Input
                value={modalData.data.unit}
                onChange={(e) =>
                  dispatchModalAction({
                    type: "unit",
                    payload: e.target.value,
                  })
                }
              />
            </div>
            <div className={`mb-4`}>
              <span className={`mb-1`}>Consumption Tags</span>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags Mode"
                value={Object.entries(modalData.data.consumptionTags).map(
                  ([key, value]) => key + "=" + value
                )}
                onChange={(values: string[]) => {
                  const obj: { [_: string]: string } = {};
                  for (const value of values) {
                    if (!value.includes("=")) {
                      continue;
                    }
                    const key = value.split("=")[0];
                    const val = value.split("=")[1];
                    if (!key || !val) {
                      continue;
                    }
                    obj[key] = val;
                  }
                  dispatchModalAction({
                    type: "consumptionTags",
                    payload: obj,
                  });
                }}
                options={[]}
              />
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
