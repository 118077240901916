import * as React from "react";
import { observer } from "mobx-react-lite";
import { Routes, Route } from "react-router-dom";
import { TrialDomainsDetail } from "./Pages/Detail";
import { TrialDomainsList } from "./Pages/List";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";
import { Helmet } from "react-helmet";

const TrialDomainsIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Trial Domains - Admin Panel
        </title>
      </Helmet>
      <Routes>
        <Route path={`:trialDomainName/*`} element={<TrialDomainsDetail />} />
        <Route index element={<TrialDomainsList />} />
      </Routes>
    </>
  );
};

export const TrialDomainsIndex = observer(TrialDomainsIndexRaw);
