import { IS_TEST_ENVIRONMENT } from "./envVariables";
import { Popover } from "antd";
import about from "./about.json";

export const VersionCopyright: React.FC = () => {
  if (IS_TEST_ENVIRONMENT) {
    return <span>©</span>;
  }
  return (
    <Popover
      content={
        <div className="flex flex-col">
          {Object.entries(about).map(([key, value]) => (
            <span>
              {key}: {value}
            </span>
          ))}
        </div>
      }
      trigger={["click"]}
    >
      <span>©</span>
    </Popover>
  );
};
