import * as React from "react";
import { initializeApp, getApps, deleteApp } from "firebase/app";
import {
  onAuthStateChanged,
  getAuth,
  signOut as firebaseSignOut,
  User as FirebaseUser,
  signInWithPopup,
  signInWithEmailAndPassword,
  GithubAuthProvider,
  OAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import axios from "axios";
import { request } from "../utils";
import { types, flow, onPatch, Instance } from "mobx-state-tree";
import { notification } from "antd";
import { FIREBASE_STORAGE_BUCKET, IS_PROD_ENVIRONMENT } from "../envVariables";
import { baseURL } from "../baseUrl";
import { MainUser, MainUserMobx, MainUserModel } from "./main.user";
import { DiscoveryMobx, DiscoveryModel } from "./discovery";

const STORAGE_KEY_LAST_ACTIVITY = "lastActivity";

const MainStoreModel = types
  .model("Main Store", {
    _user: types.optional(types.maybeNull(MainUserModel), null),
    _discovery: types.maybe(DiscoveryModel),
    globalActions: types.array(types.string),
    orgActions: types.array(types.string),
    isAuthChecked: types.optional(types.boolean, false),
    isAuthenticating: types.optional(types.boolean, false),
    isLoadingInitialData: types.optional(types.boolean, false),
    isServiceDown: types.optional(types.boolean, false),
    initialUrl: types.optional(types.string, "/"),
  })
  .views((self) => ({
    get discovery(): DiscoveryMobx {
      return self._discovery!;
    },
    get isLoggedIn() {
      return self._user !== null;
    },
    get user() {
      return self._user as MainUserMobx;
    },
    get firebaseApp() {
      return getApps().find((app) => app.name === "main")!;
    },
  }))
  .actions((self) => {
    const signOut = function () {
      firebaseSignOut(getAuth(self.firebaseApp));
    };
    const saveUser = function (user: FirebaseUser | null) {
      self.isAuthChecked = true;

      if (user === null) {
        self._user = null;
        self.isAuthenticating = false;
        return;
      }

      const userToSave: MainUser = {
        displayName: user.displayName || "No Name",
        email: user.email || "no email",
        photoURL: user.photoURL,
        uid: user.uid,
        globalActions: [],
      };
      self._user = MainUserModel.create(userToSave);
      self.isAuthenticating = false;
    };
    return {
      signOut,
      saveUser,
    };
  })
  .actions((self) => ({
    setIsAuthChecked(value: boolean) {
      self.isAuthChecked = value;
    },
    updateLastActivityTimestamp() {
      if (!self.isLoggedIn) {
        return;
      }
      localStorage.setItem(STORAGE_KEY_LAST_ACTIVITY, new Date().getTime().toString());
    },
  }))
  .actions((self) => {
    const fetchActions: () => Promise<void> = flow(function* () {
      try {
        // fetch global actions
        const globalActionsRes = yield request({
          service: "admin-api",
          url: "/admin/acl/-globalActions",
        });
        self.globalActions.clear();
        for (let action of globalActionsRes) {
          self.globalActions.push(action);
        }

        // fetch org actions
        const orgActionsRes = yield request({
          service: "admin-api",
          url: "/admin/acl/-actions",
        });
        self.orgActions.clear();
        for (let action of orgActionsRes) {
          self.orgActions.push(action);
        }
      } catch (e) {
        console.log("Failed to get actions");
      }
    });

    return { fetchActions };
  })
  .actions((self) => {
    const afterCreate: () => Promise<void> = flow(function* () {
      self.initialUrl = window.location.pathname.includes("/system") ? "/" : window.location.pathname;

      // fetch discovery
      try {
        const discoveryRes = yield axios.get(baseURL + "/discovery");
        self._discovery = DiscoveryModel.create({
          ...discoveryRes.data,
          endpoints: {
            ...discoveryRes.data.endpoints,
            "billing-ng": `https://billing-ng.${IS_PROD_ENVIRONMENT ? "" : "test."}cpln.io`,
          },
        });
      } catch (e) {
        console.error(e);
        self.isServiceDown = true;
        return;
      }

      // Initialize firebase
      for (const previousFirebaeApp of getApps()) {
        yield deleteApp(previousFirebaeApp);
      }

      const firebaseConfig = {
        apiKey: self.discovery.firebase.apiKey,
        authDomain: self.discovery.firebase.authDomain,
        projectId: self.discovery.firebase.projectId,
        storageBucket: FIREBASE_STORAGE_BUCKET,
      };
      initializeApp(firebaseConfig, "main");

      // Set up auth listener
      onAuthStateChanged(getAuth(self.firebaseApp), (user) => {
        self.saveUser(user);
      });
    });

    const handleLoggedIn: () => Promise<void> = flow(function* () {
      self.isLoadingInitialData = true;
      try {
        yield self.fetchActions();
        const res = yield request({ url: `/admin/acl/${self.user.email}` });
        for (const action of Object.keys(res.actions)) {
          if (res.actions[action] === true) {
            self.user.addAction(action);
          }
        }
      } catch (e) {
        self.signOut();
        self.isLoadingInitialData = false;
        throw e;
      }
      self.isLoadingInitialData = false;
    });

    const authenticate: (
      provider: "google" | "microsoft" | "github" | "email",
      email?: string,
      password?: string,
    ) => Promise<void> = flow(function* (provider, email, password) {
      self.isAuthenticating = true;
      if (provider === "email") {
        try {
          yield signInWithEmailAndPassword(getAuth(self.firebaseApp), email!, password!);
          return;
        } catch (e) {
          self.isAuthenticating = false;
          throw e;
        }
      }
      let firebaseProvider: any = null as any;
      switch (provider) {
        case "github":
          firebaseProvider = new GithubAuthProvider();
          break;
        case "microsoft":
          firebaseProvider = new OAuthProvider("microsoft.com").setCustomParameters({
            prompt: "select_account",
          });
          break;
        case "google":
        default:
          firebaseProvider = new GoogleAuthProvider().setCustomParameters({
            prompt: "select_account",
          });
          break;
      }
      try {
        yield signInWithPopup(getAuth(self.firebaseApp), firebaseProvider);
      } catch (e) {
        self.isAuthenticating = false;
        throw e;
      }
    });

    return { afterCreate, handleLoggedIn, authenticate };
  })
  .actions((self) => ({
    handleLoggedOut() {
      self.initialUrl = "/";
    },
    clearInitialUrl() {
      self.initialUrl = "/";
    },
  }))
  .views(() => ({
    get lastActivityTimestamp() {
      const valueString = localStorage.getItem(STORAGE_KEY_LAST_ACTIVITY) || "0";
      let value = Number(valueString);
      if (Number.isNaN(value)) {
        value = 0;
      }
      return value;
    },
  }));

export const main = MainStoreModel.create();
export const MainStoreContext = React.createContext(main);
export interface MainStoreMobx extends Instance<typeof MainStoreModel> {}

// Update data after successful login and clear after logout
onPatch(main, async (patch) => {
  if (patch.op === "replace" && patch.path === "/_user") {
    // Handle logout
    if (patch.value === null) {
      main.handleLoggedOut();
    } else {
      try {
        await main.handleLoggedIn();
      } catch (e) {
        notification.warning({
          message: "Failed to Login",
          description: e.message,
        });
      }
    }
  }
});

export function useMain() {
  return React.useContext(MainStoreContext);
}
