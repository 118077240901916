import * as React from "react";
import {
  Table,
  notification,
  Input,
  Button,
  Popconfirm,
  TablePaginationConfig,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { formatTimestamp, request } from "../../utils";
import { Link } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { useMain } from "../../stores/main";

type AccountSortOrder = "ascend" | "descend";

const AccountListRaw: React.FC = () => {
  const main = useMain();
  const [isLoading, setIsLoading] = React.useState(true);
  const [accounts, setAccounts] = React.useState<any[]>([]);
  const [filter, setFilter] = React.useState("");
  const [actingOn, setActingOn] = React.useState("");
  const [viewingItem, setViewingItem] = React.useState<any>(null);

  function getDefaultSortOrder(): AccountSortOrder {
    return (
      (localStorage.getItem("account-sortOrder") as AccountSortOrder) ||
      "descend"
    );
  }
  const [sortOrder, setSortOrder] = React.useState<AccountSortOrder>(
    getDefaultSortOrder()
  );
  React.useEffect(() => {
    localStorage.setItem("account-sortOrder", sortOrder);
  }, [sortOrder]);

  React.useEffect(() => {
    fetchAccounts();
  }, []);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [viewingItem, actingOn]);

  async function fetchAccounts() {
    try {
      setIsLoading(true);
      let nextLink = "/admin/account";
      let _accounts: any[] = [];
      while (nextLink) {
        const res = await request({ service: "billing-ng", url: nextLink });
        _accounts = _accounts.concat(res.items);
        nextLink = res.links.find((l: any) => l.rel === "next")?.href;
      }
      setAccounts(_accounts);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch accounts",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function exportItem(record: any) {
    main.updateLastActivityTimestamp();
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  async function onToggleEnabled(id: string, action: boolean) {
    try {
      setIsLoading(true);
      setActingOn(id);
      await request({
        service: "billing-ng",
        url: `/account/${id}/enable/${String(action)}`,
        method: "put",
      });
      setActingOn("");
      setIsLoading(false);
      await fetchAccounts();
      notification.success({
        message: "Success",
        description: "Toggled the enabled status of account",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to toggle enabled status of account",
        description: errorMessage,
      });
      setActingOn("");
      setIsLoading(false);
    }
  }

  const columns = [
    {
      title: "Account Name / Full Name / UUID",
      dataIndex: "accountName",
      key: "accountName",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`${record.id}`}
          >
            {text || record.id}
          </Link>
          {record.fullName && record.accountName !== record.fullName ? (
            <span className="text-sm">{record.fullName}</span>
          ) : null}
          <span className="text-sm truncate">{record.id}</span>
        </div>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: any) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
      sorter: (a: any, b: any) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder,
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "nameForActions",
      render: (_id: string, record: any) => (
        <div className="flex items-center">
          <Popconfirm
            title={`Are you sure you want to ${
              record.enabled === true ? "disable" : "enable"
            } this account?`}
            onConfirm={() =>
              onToggleEnabled(record.id, record.enabled === true ? false : true)
            }
            onCancel={() => {}}
            okText={record.enabled === true ? "Disable" : "Enable"}
            okButtonProps={{ danger: record.enabled === true }}
            cancelText="Cancel"
          >
            <Button
              disabled={isLoading}
              loading={actingOn === record.id}
              type={"primary"}
              danger={record.enabled === true}
              size={"small"}
            >
              {record.enabled === true ? "Disable" : "Enable"}
            </Button>
          </Popconfirm>
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => exportItem(record)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  const dataSource = accounts.filter((account) => {
    if (account.accountName?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (account.fullName.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (account.accountId.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (account.id.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    return false;
  });

  function onTableChange(__: TablePaginationConfig, _: any, sorter: any) {
    const _sortBy: undefined | AccountSortOrder = sorter.order;

    if (_sortBy) {
      setSortOrder(_sortBy);
    }
  }

  return (
    <>
      <div className="flex items-center gap-4">
        <PageHeader title={"Accounts"} backIcon={false} />
        <Input
          style={{ width: 450 }}
          value={filter}
          placeholder={"Filter"}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={dataSource}
          columns={columns as any}
          pagination={{ showSizeChanger: false }}
          onChange={onTableChange}
          rowKey={"id"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.name}
          onClose={() => setViewingItem(null)}
          title={`Account: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const AccountList = observer(AccountListRaw);
