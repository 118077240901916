import { isEqual } from "lodash";
import { makeAutoObservable } from "mobx";

export const QuotaOverrideOptions = [
  {value: "gvc", label: "gvc"},
  {value: "workloads-per-gvc", label: "workloads-per-gvc"},
  {value: "memory-per-workload", label: "memory-per-workload"},
  {value: "cpu-per-workload", label: "cpu-per-workload"},
  {value: "replicas-per-workload", label: "replicas-per-workload"},
  {value: "identities-per-gvc", label: "identities-per-gvc"},
];

export interface QuotaOverride {
  name: string;
  max: number;
}

export class OrgConfigModel {
  initialAwsPrivateLinks: string[] = [];
  initialGcpServiceConnects: string[] = [];
  initialQuotaOverrides: QuotaOverride[] = [];
  awsPrivateLinks: string[] = [];
  gcpServiceConnects: string[] = [];
  quotaOverrides: QuotaOverride[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setInitials(awsPrivateLinks: string[], gcpServiceConnects: string[], quotaOverrides: QuotaOverride[]) {
    // AWS
    this.awsPrivateLinks = [];
    this.initialAwsPrivateLinks = [];
    for (let link of awsPrivateLinks) {
      this.initialAwsPrivateLinks.push(link);
    }

    // GCP
    this.gcpServiceConnects = [];
    this.initialGcpServiceConnects = [];
    for (let link of gcpServiceConnects) {
      this.initialGcpServiceConnects.push(link);
    }

    // Quota Overrides
    this.quotaOverrides = [];
    this.initialQuotaOverrides = [];
    for (let item of quotaOverrides) {
      this.initialQuotaOverrides.push({name: item.name, max: item.max});
    }

    this.reset();
  }

  // AWS
  setAwsAt(index: number, value: string) {
    this.awsPrivateLinks[index] = value;
  }

  removeAwsAt(index: number) {
    this.awsPrivateLinks.splice(index, 1);
  }

  addAws() {
    this.awsPrivateLinks.push("");
  }

  // GCP
  setGcpAt(index: number, value: string) {
    this.gcpServiceConnects[index] = value;
  }

  removeGcpAt(index: number) {
    this.gcpServiceConnects.splice(index, 1);
  }

  addGcp() {
    this.gcpServiceConnects.push("");
  }

  // QuotaOverrides
  setQuotaOverrideNameAt(index: number, value: string) {
    this.quotaOverrides[index].name = value;
  }

  setQuotaOverrideMaxAt(index: number, value: string) {
    let safeValue = Number(value);
    if (Number.isNaN(safeValue)) {
      return;
    }
    this.quotaOverrides[index].max = safeValue;
  }

  removeQuotaOverrideAt(index: number) {
    this.quotaOverrides.splice(index, 1);
  }

  addQuotaOverride() {
    this.quotaOverrides.push({name: "", max: 0});
  }

  reset() {
    this.awsPrivateLinks = [];
    for (let link of this.initialAwsPrivateLinks) {
      this.awsPrivateLinks.push(link);
    }
    this.gcpServiceConnects = [];
    for (let link of this.initialGcpServiceConnects) {
      this.gcpServiceConnects.push(link);
    }
    this.quotaOverrides = [];
    for (let item of this.initialQuotaOverrides) {
      this.quotaOverrides.push({name: item.name, max: item.max});
    }
  }

  confirm() {
    this.initialAwsPrivateLinks = [];
    for (let link of this.awsPrivateLinks) {
      this.initialAwsPrivateLinks.push(link);
    }
    this.initialGcpServiceConnects = [];
    for (let link of this.gcpServiceConnects) {
      this.initialGcpServiceConnects.push(link);
    }
    this.initialQuotaOverrides = [];
    for (let item of this.quotaOverrides) {
      this.initialQuotaOverrides.push({name: item.name, max: item.max});
    }
  }

  get isDirty(): boolean {
    let res = false;
    if (!isEqual(this.initialAwsPrivateLinks, this.awsPrivateLinks) || !isEqual(this.initialGcpServiceConnects, this.gcpServiceConnects) ||
        !isEqual(this.initialQuotaOverrides, this.quotaOverrides)) {
      res = true;
    }
    return res;
  }

  get asObject() {
    return {
      data: {
        awsPrivateLinks: this.awsPrivateLinks,
        gcpServiceConnects: this.gcpServiceConnects,
        quotaOverrides: this.quotaOverrides
      },
    };
  }
}
