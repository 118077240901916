import * as React from "react";
import styles from "../styles/Detail.module.css";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import {
  IClusterApp,
  IClusterComponent,
  ISubmission,
} from "../utilities/types";
import { Button, Spin, Table, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { ColumnsType } from "antd/lib/table";
import {
  GenericFetchPricingCalculator,
  ViewPriceComparison,
} from "../utilities/helper";

type RouteParams = "submissionID";

const PricingCalculatorSubmissionDetailRaw: React.FC = () => {
  // Use State //
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(true);
  const [submission, setSubmission] = React.useState<ISubmission | null>(null);

  // Initialize //
  const { submissionID: _submissionID } = useParams<RouteParams>();
  const submissionID = _submissionID!;

  const columnsComponents: ColumnsType<IClusterComponent> = [
    {
      title: "Component Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CPU (Cores)",
      dataIndex: "usage_cpu",
      key: "usage_cpu",
      sorter: (a: any, b: any) => b.usage_cpu - a.usage_cpu,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Memory (MiB)",
      dataIndex: "usage_memory",
      key: "usage_memory",
      sorter: (a: any, b: any) => b.usage_memory - a.usage_memory,
      sortDirections: ["descend", "ascend", "descend"],
    },
  ];

  const columnsApps: ColumnsType<IClusterApp> = [
    {
      title: "App Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Node Name",
      dataIndex: "node_name",
      key: "node_name",
    },
    {
      title: "CPU (Cores)",
      dataIndex: "cpu_usage",
      key: "cpu_usage",
      sorter: (a: any, b: any) => b.cpu_usage - a.cpu_usage,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Memory (MiB)",
      dataIndex: "memory_usage",
      key: "memory_usage",
      sorter: (a: any, b: any) => b.memory_usage - a.memory_usage,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "K8s Expense",
      dataIndex: "competitor_monthly_price",
      key: "competitor_monthly_price",
      render: (price: number, _: IClusterApp) => <span>${price} / month</span>,
      sorter: (a: any, b: any) =>
        b.competitor_monthly_price - a.competitor_monthly_price,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Control Plane Cost",
      dataIndex: "control_plane_monthly_price",
      key: "control_plane_monthly_price",
      render: (price: number, _: IClusterApp) => <span>${price} / month</span>,
      sorter: (a: any, b: any) =>
        b.control_plane_monthly_price - a.control_plane_monthly_price,
      sortDirections: ["descend", "ascend", "descend"],
    },
    {
      title: "Cost Savings",
      dataIndex: "cost_saving",
      key: "cost_saving",
      render: (price: number, _: IClusterApp) => (
        <span>
          <b
            className={
              price >= 0 ? styles.positiveCostSaving : styles.negativeCostSaving
            }
          >
            ${price}
          </b>{" "}
          / month
        </span>
      ),
      sorter: (a: any, b: any) => b.cost_saving - a.cost_saving,
      sortDirections: ["descend", "ascend", "descend"],
    },
  ];

  // Use Effects //
  React.useEffect(() => {
    fetchSubmission(submissionID);
  }, [submissionID]);

  // Functions //
  async function fetchSubmission(id: string) {
    setIsLoading(true);

    const response: ISubmission | string = await GenericFetchPricingCalculator(
      id
    );

    if (typeof response === "string") {
      notification.warning({
        message: "Failed to fetch pricing calculator submission",
        description: response,
      });
    } else {
      setSubmission(response);
    }

    setIsLoading(false);
  }

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  if (!submission) {
    notification.warning({
      message: "Failed to fetch pricing calculator submission",
      description: "It could be possible that the submission does not exist",
    });

    navigate("/admin/pricing-calculator-submissions");
    return null;
  }

  return (
    <div>
      <div className="flex items-center">
        <PageHeader
          title={`${submission.userName} - Cluster: ${submission.clusterName}`}
        />
        <Button
          type={"primary"}
          onClick={() => ViewPriceComparison(submission.id)}
        >
          View
        </Button>
      </div>
      <div className="mb-4 ml-6">
        <h2 className={styles.subHeading}>Components</h2>
        <Table
          columns={columnsComponents}
          dataSource={submission.components}
          loading={isLoading}
          rowKey={"type"}
        />
        {submission.apps ? (
          <>
            <h2 className={styles.subHeading}>Applications</h2>
            <Table
              columns={columnsApps}
              dataSource={submission.apps}
              loading={isLoading}
              rowKey={"type"}
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export const PricingCalculatorSubmissionDetail = observer(
  PricingCalculatorSubmissionDetailRaw
);
