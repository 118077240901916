import * as React from "react";
import {
  Modal,
  Button,
  Tag,
  Menu,
  Dropdown,
  Table,
  notification,
  Input,
  TablePaginationConfig,
  MenuProps,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import {
  InspectImpersonateType,
  formatTimestamp,
  onInspectOrg,
  request,
} from "../../utils";
import { Link } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { MoreOutlined } from "@ant-design/icons";
import { SortOrder } from "../../types";
import { CONSOLE_STAGING } from "../../envVariables";

const OrgListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [orgs, setOrgs] = React.useState<any[]>([]);
  const [actingOn, setActingOn] = React.useState("");
  const [filter, setFilter] = React.useState("");
  const [viewingItem, setViewingItem] = React.useState<any>(null);

  function getDefaultSortOrder(): SortOrder {
    return (localStorage.getItem("org-sortOrder") as SortOrder) || "descend";
  }
  const [sortOrder, setSortOrder] = React.useState<SortOrder>(
    getDefaultSortOrder()
  );
  React.useEffect(() => {
    localStorage.setItem("org-sortOrder", sortOrder);
  }, [sortOrder]);

  React.useEffect(() => {
    fetchOrgs();
  }, []);

  async function fetchOrgs() {
    try {
      setIsLoading(true);
      let nextLink = "/admin/org";
      let _orgs: any[] = [];
      while (nextLink) {
        const res = await request({ url: nextLink });
        _orgs = _orgs.concat(res.items);
        nextLink = res.links?.find((l: any) => l.rel === "next")?.href;
      }
      setOrgs(_orgs);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch orgs",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function onInspect(org: string, type: InspectImpersonateType) {
    try {
      setIsLoading(true);
      setActingOn(org);
      await onInspectOrg(org, type);
      setActingOn("");
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch inspect org token",
        description: errorMessage,
      });
      setActingOn("");
      setIsLoading(false);
    }
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  async function tryActivate(record: any) {
    Modal.confirm({
      title: `Activate "${record.name}" org?`,
      content: "This will activate the org.",
      onOk: async () => {
        try {
          setIsLoading(true);
          await request({
            url: `/admin/org/${record.name}`,
            method: "patch",
            body: {
              status: {
                active: true,
              },
            },
          });
          notification.success({
            message: "Success",
            description: `Activated the org: ${record.name}`,
          });
          await onOrgChanged(record.name);
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to activate org",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
    });
  }

  async function tryDeactivate(record: any) {
    Modal.confirm({
      title: `Deactivate "${record.name}" org?`,
      content: "This will deactivate the org and make it read-only.",
      onOk: async () => {
        try {
          setIsLoading(true);
          await request({
            url: `/admin/org/${record.name}`,
            method: "patch",
            body: {
              status: {
                active: false,
              },
            },
          });
          notification.success({
            message: "Success",
            description: `Deactivated the org: ${record.name}`,
          });
          await onOrgChanged(record.name);
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to deactivate org",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
      okButtonProps: { disabled: isLoading, loading: isLoading },
      cancelButtonProps: { disabled: isLoading },
    });
  }

  async function onOrgChanged(orgName: string) {
    try {
      const orgRes = await request({ url: `/admin/org/${orgName}` });
      const orgsCopy: any[] = JSON.parse(JSON.stringify(orgs));
      const idx = orgsCopy.findIndex((o) => o.name === orgName);
      orgsCopy[idx] = orgRes;
      setOrgs(orgsCopy);
    } catch (e) {
      // error on fetching the org
    }
  }

  function getExtraActionsOnClick(record: any) {
    return function ({ key }) {
      switch (key) {
        case "activation":
          if (record.status?.active) {
            tryDeactivate(record);
          } else {
            tryActivate(record);
          }
          break;
        case "export":
          exportItem(record);
          break;

        default:
          break;
      }
    };
  }

  const onInspectMenuClick: (org: string) => MenuProps["onClick"] =
    (org) => (e) => {
      onInspect(org, e.key as any);
    };

  const inspectMenuItems = [
    {
      key: "local",
      label: "Local",
    },
  ];
  if (CONSOLE_STAGING) {
    inspectMenuItems.unshift({ key: "staging", label: "Staging" });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <div className="flex items-center gap-4">
          <div className="flex flex-col">
            <Link className="text-base text-blue-600 truncate" to={`${text}`}>
              {text}
            </Link>
            <span className="text-sm truncate">
              {record.description !== text ? record.description : ""}
            </span>
          </div>
          {!record.status?.active ? <Tag color={"red"}>Inactive</Tag> : null}
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: any) => (
        <>
          {Object.entries(tags || {}).map(([key, value]: [any, any]) => {
            return (
              <Tag key={key}>
                {key}={value}
              </Tag>
            );
          })}
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: any) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
      sorter: (a: any, b: any) =>
        new Date(a.created).getTime() - new Date(b.created).getTime(),
      sortDirections: ["descend", "ascend", "descend"],
      sortOrder,
    },
    {
      title: "Actions",
      dataIndex: "name",
      key: "nameForInspect",
      render: (name: string, record: any) => (
        <div className="flex items-center gap-4">
          <div>
            <Dropdown.Button
              disabled={isLoading}
              loading={actingOn === name}
              type={"primary"}
              size={"small"}
              onClick={() => onInspect(record.name, "default")}
              menu={{
                items: inspectMenuItems,
                onClick: onInspectMenuClick(record.name),
              }}
            >
              Inspect
            </Dropdown.Button>
          </div>
          <Button
            size={"small"}
            type={"default"}
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Dropdown
            menu={{
              items: [
                {
                  key: "activation",
                  danger: true,
                  label: record.status?.active ? "Deactivate" : "Activate",
                },
                { key: "export", label: "Export" },
              ],
              onClick: getExtraActionsOnClick(record),
            }}
            trigger={["click"]}
          >
            <MoreOutlined className="cursor-pointer text-blue-500" />
          </Dropdown>
        </div>
      ),
    },
  ];

  const dataSource = orgs.filter((org) => {
    if (org.id?.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (org.name.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    if (org.description.toLowerCase().includes(filter.toLowerCase())) {
      return true;
    }
    return false;
  });

  function onTableChange(__: TablePaginationConfig, _: any, sorter: any) {
    const _sortBy: undefined | SortOrder = sorter.order;

    if (_sortBy) {
      setSortOrder(_sortBy);
    }
  }

  return (
    <>
      <div className="flex items-center gap-4">
        <PageHeader title={"Orgs"} backIcon={false} />
        <Input
          style={{ width: 450 }}
          value={filter}
          placeholder={"Filter by name or description"}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={dataSource}
          columns={columns as any}
          pagination={{ showSizeChanger: false }}
          onChange={onTableChange}
          rowKey={"id"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.name}
          onClose={() => setViewingItem(null)}
          title={`Org: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const OrgList = observer(OrgListRaw);
