import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { AclList } from "./AclList";
import { AclDetailEdit } from "./AclDetailEdit";
import { AclDetail } from "./AclDetail";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const AclIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}ACL - Admin Panel</title>
      </Helmet>
      <Routes>
        <Route index element={<AclList />} />
        <Route path={`-add/*`} element={<AclDetailEdit isNew={true} />} />
        <Route path={`:email/*`} element={<AclDetail />} />
        <Route path={`:email/-edit/*`} element={<AclDetailEdit />} />
      </Routes>
    </>
  );
};

export const AclIndex = observer(AclIndexRaw);
