import { makeAutoObservable } from "mobx";
import {
  AccountState,
  AccountStatePageMode,
  ChargeableItem,
  Invoice,
  PageDataState,
  RatePlan,
  Timestep,
  TrustLevel,
} from "../../../Billing/types";
import { getAccountStateStartEndTimes, getBuffer } from "../utils";
import {
  endInfinityMoment,
  momentToIso,
  startInfinityMoment,
} from "../Timeline/utils";
import moment from "moment-timezone";

class AccountStateContextClass {
  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  reset() {
    this.setMode("edit");
    this.setStartMoment(getAccountStateStartEndTimes(this.timestep)[0]);
    this.setEndMoment(getAccountStateStartEndTimes(this.timestep)[1]);
  }

  mode: AccountStatePageMode = "edit";
  setMode(mode: AccountStatePageMode) {
    this.mode = mode;
  }

  pageDataState: PageDataState = "idle";
  setPageDataState(value: PageDataState) {
    this.pageDataState = value;
  }

  pageDataError: string = "";
  setPageDataError(value: string) {
    this.pageDataError = value;
  }

  isLoadingStates: boolean = false;
  setIsLoadingStates(value: boolean) {
    this.isLoadingStates = value;
  }

  isLoadingInvoices: boolean = false;
  setIsLoadingInvoices(value: boolean) {
    this.isLoadingInvoices = value;
  }

  accountStates: AccountState[] = [];
  setAccountStates(value: AccountState[]) {
    this.accountStates = value;
  }
  get prevAccountState(): AccountState | null {
    if (!this.currentAccountState) {
      return null;
    }

    const currentIndex = this.accountStates.indexOf(this.currentAccountState);
    const prevIndex = currentIndex - 1;

    if (prevIndex < 0) {
      return null;
    }

    return this.accountStates[prevIndex];
  }
  get currentAccountState(): AccountState | null {
    const now = moment();
    for (const accountState of this.accountStates) {
      const stateStart = accountState.startTime
        ? moment(accountState.startTime)
        : startInfinityMoment;
      const stateEnd = accountState.endTime
        ? moment(accountState.endTime)
        : endInfinityMoment;
      if (now.isBetween(stateStart, stateEnd)) {
        return accountState;
      }
    }
    return null;
  }
  get nextAccountState(): AccountState | null {
    if (!this.currentAccountState) {
      return null;
    }

    const currentIndex = this.accountStates.indexOf(this.currentAccountState);
    const nextIndex = currentIndex + 1;

    if (nextIndex < this.accountStates.length) {
      return this.accountStates[nextIndex];
    }

    return null;
  }

  invoices: Invoice[] = [];
  setInvoices(value: Invoice[]) {
    this.invoices = value;
  }

  startMoment: moment.Moment = moment();
  endMoment: moment.Moment = moment();
  setStartMoment(value: moment.Moment) {
    this.startMoment = value.clone();
  }
  setEndMoment(value: moment.Moment) {
    this.endMoment = value.clone();
  }
  get startISO() {
    return momentToIso(this.startMoment);
  }
  get endISO() {
    return momentToIso(this.endMoment);
  }
  get startISOBuffered() {
    return momentToIso(
      this.startMoment.clone().subtract(getBuffer(this.timestep))
    );
  }
  get endISOBuffered() {
    return momentToIso(this.endMoment.clone().add(getBuffer(this.timestep)));
  }

  timestep: Timestep = "month";
  setTimestep(value: Timestep) {
    this.timestep = value;
    this.setStartMoment(getAccountStateStartEndTimes(this.timestep)[0]);
    this.setEndMoment(getAccountStateStartEndTimes(this.timestep)[1]);
  }

  trustLevels: TrustLevel[] = [];
  setTrustLevels(value: TrustLevel[]) {
    this.trustLevels = value;
  }
  ratePlans: RatePlan[] = [];
  setRatePlans(value: RatePlan[]) {
    this.ratePlans = value;
  }
  chargables: ChargeableItem[] = [];
  setChargables(value: ChargeableItem[]) {
    this.chargables = value;
  }
}

export const AccountStateContext = new AccountStateContextClass();
