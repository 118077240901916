import axios from "axios";
import { CPLN_SECRET } from "./constants";
import {
  PRICING_SUBMISSIONS_API,
  VIEW_SUBMISSION_ENDPOINT,
} from "../../../envVariables";

/**
 * Creates an axios get request to the pricing calculator API
 * along with the header secret.
 *
 * @param id The submission ID
 * @returns The response data as the specified object in T,
 * if the request has failed a string containing the exception error is returned
 */
export async function GenericFetchPricingCalculator<T>(
  id: string = ""
): Promise<T | string> {
  try {
    let url = PRICING_SUBMISSIONS_API as string;

    if (id) {
      url += `/${id}`;
    }

    const response = await axios.get(url, {
      headers: {
        "cpln-secret": CPLN_SECRET,
      },
    });

    return response.data as T;
  } catch (e) {
    console.error(e);

    // @ts-ignore
    let errorMessage = e?.response?.data?.message;
    // @ts-ignore
    if (!errorMessage) errorMessage = e.message;

    return errorMessage;
  }
}

export function ViewPriceComparison(id: string): void {
  window.open(VIEW_SUBMISSION_ENDPOINT + "?id=" + id, "_blank");
}
