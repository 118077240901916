import * as React from "react";
import "./editor.css";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ToolbarPlugin from "./plugins/ToolbarPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";

import { createEditor, EditorState } from "lexical";
import { notification } from "antd";
import { RichEditorConfig } from "../Admin/Incidents/utils";

function Placeholder() {
  return <div className="editor-placeholder">Enter some rich text...</div>;
}

interface FormInputs {
  formValue?: string;
  formOnChange?: (value: string) => void;
  readOnly?: boolean;
}

// TODO when a broken state is given, show the broken state to the user and allow to clear it or transform it to plain text in rich text editor

export const RichTextEditor: React.FC<FormInputs> = ({
  formValue,
  formOnChange,
  readOnly = false,
}) => {
  const [isStateValidated, setIsStateValidated] = React.useState(false);
  const [initialState, setInitialState] = React.useState<
    string | undefined | null
  >(null);
  const editorStateRef = React.useRef<EditorState>(null as any);

  React.useEffect(() => {
    if (!formValue) {
      setIsStateValidated(true);
      setInitialState(undefined);
      return;
    }

    try {
      const editor = createEditor(RichEditorConfig);
      editor.parseEditorState(formValue);
      setInitialState(formValue);
    } catch (e) {
      notification.warning({
        message: "State is not valid",
        description: e.message,
      });
      setInitialState(undefined);
    }

    setIsStateValidated(true);
  }, []);

  function lexicalOnChange(editorState: EditorState) {
    editorStateRef.current = editorState;
    if (formOnChange) {
      formOnChange(JSON.stringify(editorStateRef.current));
    }
  }

  if (!isStateValidated) {
    return null;
  }

  if (initialState === null) {
    return null;
  }

  if (readOnly) {
    return (
      <div className={"rich-text-editor-parent readonly"}>
        <LexicalComposer
          initialConfig={{
            ...RichEditorConfig,
            editorState: initialState,
            editable: !readOnly,
          }}
        >
          <div className="editor-container">
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <AutoFocusPlugin />
              <CodeHighlightPlugin />
              <ListPlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
              <ListMaxIndentLevelPlugin maxDepth={7} />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
            </div>
          </div>
        </LexicalComposer>
      </div>
    );
  }

  return (
    <div className={"rich-text-editor-parent"}>
      <LexicalComposer
        initialConfig={{
          ...RichEditorConfig,
          editorState: initialState,
        }}
      >
        <div className="editor-container">
          <ToolbarPlugin />
          <div className="editor-inner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editor-input" />}
              placeholder={<Placeholder />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={lexicalOnChange} />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        </div>
      </LexicalComposer>
    </div>
  );
};
