import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { UserList } from "./UserList";
import { UserDetail } from "./UserDetail";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const UserIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}User - Admin Panel</title>
      </Helmet>
      <Routes>
        <Route path={`:uid/*`} element={<UserDetail />} />
        <Route index element={<UserList />} />
      </Routes>
    </>
  );
};

export const UserIndex = observer(UserIndexRaw);
