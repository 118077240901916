import { types, Instance } from "mobx-state-tree";

export const MainUserModel = types
  .model("App User", {
    email: types.string,
    displayName: types.string,
    photoURL: types.maybeNull(types.string),
    uid: types.string,
    globalActions: types.array(types.string),
  })
  .actions((self) => ({
    addAction(value: string) {
      if (self.globalActions.includes(value)) {
        return;
      }
      self.globalActions.push(value);
    },
  }));
export interface MainUserMobx extends Instance<typeof MainUserModel> {}
export interface MainUser {
  email: string;
  displayName: string;
  photoURL: string | null;
  uid: string;
  globalActions: string[];
}
