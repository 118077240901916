import * as React from "react";
import jsYaml from "js-yaml";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT, MARKETPLACE_SERVICE } from "../../envVariables";
import { Alert, Button } from "antd";
import { request } from "../../utils";

const MarketplaceIndexRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [repositoryVersion, setRepositoryVersion] = React.useState<string>("");
  const [marketplaceVersion, setMarketplaceVersion] = React.useState<string>("");

  const [validationResult, setValidationResult] = React.useState<any>(null);
  const [error, setError] = React.useState<string>("");

  React.useEffect(() => {
    fetchVersions();
  }, []);

  async function fetchVersions() {
    try {
      setIsLoading(true);
      const res = await request({ service: "custom", url: `${MARKETPLACE_SERVICE}/admin/version` });
      setRepositoryVersion(res.repository);
      setMarketplaceVersion(res.marketplace);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function handleSync() {
    try {
      setIsLoading(true);
      await request({ service: "custom", url: `${MARKETPLACE_SERVICE}/admin/sync` });
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      setIsLoading(false);
    }
  }

  async function handleValidate() {
    try {
      setIsLoading(true);
      const res = await request({ service: "custom", url: `${MARKETPLACE_SERVICE}/admin/validate` });
      setValidationResult(res);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      setError(errorMessage);
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div className="w-full min-h-64 flex items-center justify-center">
        <div>Loading...</div>
      </div>
    );
  }

  const validationResultProcessed = JSON.parse(JSON.stringify(validationResult));
  for (const templateKey of Object.keys(validationResultProcessed?.templates || {})) {
    const template = validationResultProcessed.templates[templateKey];
    for (const version of Object.keys(template.versions || {})) {
      delete template.versions[version].values;
      delete template.versions[version].readme;
      delete template.versions[version].files;
    }
  }

  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Marketplace - Admin Panel</title>
      </Helmet>
      <div className="p-8">
        <div className="border-b pb-4 mb-4">
          <div className="flex items-center gap-4">
            <div className="flex flex-col">
              <div className="font-semibold text-xs uppercase">Repository Ver.</div>
              <div>{repositoryVersion.slice(0, 8)}</div>
            </div>
            <div className="flex flex-col">
              <div className="font-semibold text-xs uppercase">Marketplace Ver.</div>
              <div>{marketplaceVersion.slice(0, 8)}</div>
            </div>
          </div>
          <Button className="mt-4" disabled={repositoryVersion === marketplaceVersion} onClick={() => handleSync()}>
            Sync Repository {repositoryVersion === marketplaceVersion ? " (Already Synced)" : ""}
          </Button>
        </div>
        {error ? <Alert type="error" message={error} /> : null}
        <div className="flex items-center gap-4">
          <Button onClick={() => handleValidate()}>Validate</Button>
          {validationResult ? (
            <Alert message={`Files, Values and Readme is removed from template versions for ease of readability.`} />
          ) : null}
        </div>
        {validationResult ? (
          <pre className="text-sm mt-4 border rounded p-4">{jsYaml.dump(validationResultProcessed)}</pre>
        ) : null}
      </div>
    </>
  );
};

export const MarketplaceIndex = observer(MarketplaceIndexRaw);
