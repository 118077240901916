import { notification, Spin, Button, Input, Select } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { request } from "../../utils";
import {
  OrgConfigModel,
  QuotaOverride,
  QuotaOverrideOptions,
} from "./OrgConfigModel";

interface IAntDesignSelectOption {
  value: string;
  label: string;
}

interface IOrgConfig {
  data: {
    awsPrivateLinks: string[];
    gcpServiceConnects: string[];
    quotaOverrides: QuotaOverride[];
  };
}

const config = new OrgConfigModel();

type RouteParams = "org";
const OrgConfigRaw: React.FC = () => {
  const navigate = useNavigate();
  const { org: orgName } = useParams<RouteParams>();
  const [org, setOrg] = React.useState(null as any);
  const [orgConfigError, setOrgConfigError] = React.useState("");
  const [isConfigLoading, setIsConfigLoading] = React.useState(false);

  React.useEffect(() => {
    fetchOrg();
    fetchOrgConfig();
  }, []);

  function getUnusedQuotaOverrideNames(defaultValue: string) {
    const result: IAntDesignSelectOption[] = [];
    const ignoredNames: Set<string> = new Set<string>();

    config.quotaOverrides.forEach((item) => {
      if (defaultValue === item.name) {
        return;
      }

      QuotaOverrideOptions.forEach((val) => {
        if (val.value === item.name) {
          ignoredNames.add(val.value);
        }
      });
    });

    QuotaOverrideOptions.forEach((val) => {
      if (ignoredNames.has(val.value)) {
        return;
      }
      result.push(val);
    });

    return result;
  }

  async function fetchOrg() {
    try {
      const res = await request({ url: `/admin/org/${orgName}` });
      setOrg(res);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch org",
        description: errorMessage,
      });
      navigate("/admin/org");
    }
  }

  async function fetchOrgConfig() {
    try {
      const res: IOrgConfig = await request({
        url: `/admin/org/${orgName}/-config`,
      });
      const aws = res?.data?.awsPrivateLinks || [];
      const gcp = res?.data?.gcpServiceConnects || [];
      const quotaOverrides = res?.data?.quotaOverrides || [];
      config.setInitials(aws, gcp, quotaOverrides);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch org config",
        description: errorMessage,
      });
      setOrgConfigError(e.message);
    }
  }

  async function onConfigSave() {
    try {
      setIsConfigLoading(true);
      await request({
        url: `/admin/org/${orgName}/-config`,
        method: "patch",
        body: config.asObject,
      });
      config.confirm();
      notification.success({
        message: "Updated org config",
      });
      setIsConfigLoading(false);
    } catch (e) {
      setIsConfigLoading(false);
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch org config",
        description: errorMessage,
      });
      setOrgConfigError(e.message);
    }
  }

  if (org === null) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  return (
    <div className="px-8">
      <PageHeader title={orgName} subTitle={"Org Detail - Config"} />
      <div className="px-6">
        {orgConfigError ? (
          <div>{orgConfigError}</div>
        ) : !config ? null : (
          <div>
            <div className="mb-4">
              <div className="flex items-center gap-4 mb-2">
                <span className="font-bold">AWS Private Links</span>
                <Button
                  size={"small"}
                  type={"primary"}
                  onClick={() => config.addAws()}
                >
                  Add
                </Button>
              </div>
              {config.awsPrivateLinks.length === 0 ? <div>No data</div> : null}
              {config.awsPrivateLinks.map((l, i) => (
                <div key={i} className="flex items-center gap-4 mb-2">
                  <Input
                    style={{ width: 800 }}
                    value={l}
                    onChange={(e) => config.setAwsAt(i, e.target.value)}
                  />
                  <Button danger onClick={() => config.removeAwsAt(i)}>
                    Remove
                  </Button>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <div className="flex items-center gap-4 mb-2">
                <span className="font-bold">GCP Service Connects</span>
                <Button
                  size={"small"}
                  type={"primary"}
                  onClick={() => config.addGcp()}
                >
                  Add
                </Button>
              </div>
              {config.gcpServiceConnects.length === 0 ? (
                <div>No data</div>
              ) : null}
              {config.gcpServiceConnects.map((l, i) => (
                <div key={i} className="flex items-center gap-4 mb-2">
                  <Input
                    style={{ width: 800 }}
                    value={l}
                    onChange={(e) => config.setGcpAt(i, e.target.value)}
                  />
                  <Button danger onClick={() => config.removeGcpAt(i)}>
                    Remove
                  </Button>
                </div>
              ))}
            </div>
            <div className="mb-4">
              <div className="flex items-center gap-4 mb-2">
                <span className="font-bold">Quota Overrides</span>
                <Button
                  size={"small"}
                  type={"primary"}
                  onClick={() => config.addQuotaOverride()}
                >
                  Add
                </Button>
              </div>
              {config.quotaOverrides.length === 0 ? <div>No data</div> : null}
              {config.quotaOverrides.map((l, i) => (
                <div key={i} className="flex items-center gap-4 mb-2">
                  <Select
                    defaultValue={l.name}
                    style={{ width: 392 }}
                    placeholder="Select a quota"
                    onChange={(value: string) => {
                      config.setQuotaOverrideNameAt(i, value);
                    }}
                    options={getUnusedQuotaOverrideNames(l.name)}
                  />
                  <Input
                    style={{ width: 392 }}
                    pattern="[0-9]*"
                    value={l.max}
                    onChange={(e) =>
                      config.setQuotaOverrideMaxAt(i, e.target.value)
                    }
                  />
                  <Button
                    danger
                    onClick={() => config.removeQuotaOverrideAt(i)}
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
            <div className="flex items-center gap-4">
              <Button
                disabled={!config.isDirty || isConfigLoading}
                type={"primary"}
                danger
                onClick={() => config.reset()}
              >
                Reset
              </Button>
              <Button
                disabled={!config.isDirty || isConfigLoading}
                loading={isConfigLoading}
                type={"primary"}
                onClick={() => onConfigSave()}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const OrgConfig = observer(OrgConfigRaw);
