import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";
import { CustomLogoList } from "./CustomLogoList";

const CustomLogoIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Custom Logo - Admin Panel</title>
      </Helmet>
      <Routes>
        <Route index element={<CustomLogoList />} />
      </Routes>
    </>
  );
};

export const CustomLogoIndex = observer(CustomLogoIndexRaw);
