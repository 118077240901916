import * as React from "react";
import { observer } from "mobx-react-lite";
import { ITrialDomainOrg } from "../../utilities/types";
import { Button, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { JSONModal } from "../../../../jsonModal";
import { Link } from "react-router-dom";
import { ExportItem } from "../../utilities/helper";

interface IProps {
  orgs: ITrialDomainOrg[];
}

const TrialDomainOrgsRaw: React.FC<IProps> = ({ orgs }) => {
  // Use States //
  const [viewingItem, setViewingItem] = React.useState<ITrialDomainOrg | null>(
    null
  );

  // Initialize //
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: ITrialDomainOrg) => (
        <div className="flex flex-col">
          <Link
            className="text-base text-blue-600 truncate"
            to={`/admin/org/${record.name}`}
          >
            {text}
          </Link>
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "impersonate",
      key: "impersonate",
      render: (_: string, record: ITrialDomainOrg) => (
        <div className="flex items-center">
          <Button
            size={"small"}
            type={"default"}
            onClick={() => setViewingItem(record)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => ExportItem(record, record.name)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="w-full">
        <PageHeader title={"Orgs"} subTitle={"Org list of Trial Domain"} />
        <Table
          size={"small"}
          dataSource={orgs}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          className="px-6"
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.name}
          title={`Org: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
          onClose={() => setViewingItem(null)}
        />
      ) : null}
    </>
  );
};

export const TrialDomainOrgs = observer(TrialDomainOrgsRaw);
