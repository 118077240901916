import * as React from "react";
import { observer } from "mobx-react-lite";
import { Clipboard } from "react-feather";
import { CookieReferral, Partner } from "./types";
import { Button, notification, Popconfirm } from "antd";
import { JSONModal } from "../../jsonModal";
import { request } from "../../utils";

interface Props {
  partner: Partner;
  fetchPartner: any;
}
const CookieReferralListRaw: React.FC<Props> = ({ partner, fetchPartner }) => {
  const [viewingItem, setViewingItem] = React.useState<CookieReferral | null>(
    null
  );

  async function deleteCookieReferral(referralId: string) {
    try {
      await request({
        method: "delete",
        service: "external-signup",
        url: `/cookiereferral/${referralId}`,
      });
      notification.success({
        message: "Success",
        description: "Deleted referral",
      });
      fetchPartner();
    } catch (e) {
      notification.warning({
        message: "Failed",
        description: e.message,
      });
    }
  }

  return (
    <>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`Cookie Referral: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
      <div className="flex flex-col w-full px-8">
        <div className="text-base text-gray-700 font-semibold mb-4">
          Cookie Referrals ({partner.cookieReferrals.length})
        </div>
        <div className="w-full">
          <div className="flex items-center px-4 py-2 border border-b-0 font-semibold bg-gray-200">
            <div className="w-6/12">Email & Id</div>
            <div className="w-4/12">Account Id & Token</div>
            <div className="w-2/12">Actions</div>
          </div>
          <div className="flex flex-col mb-4 mt-1">
            {partner.cookieReferrals.length === 0 ? (
              <div>No Cookie Referrals</div>
            ) : null}
            {partner.cookieReferrals.map((r, index) => (
              <div
                className={`flex items-center px-4 py-2 ${
                  index !== partner.cookieReferrals.length - 1 ? "border-b" : ""
                } ${index % 2 === 1 ? "bg-gray-50" : ""}`}
              >
                <div className="w-6/12 flex flex-col">
                  <div>{r.email}</div>
                  <div className="text-sm">{r.id}</div>
                </div>
                <div className="w-4/12 flex flex-col">
                  <div className="flex gap-1">
                    <span className="text-xs">{r.accountId}</span>
                    <span>
                      <button
                        onClick={() => {
                          navigator.clipboard.writeText(r.accountId);
                          notification.success({
                            message: "Copied to clipboard",
                          });
                        }}
                      >
                        <Clipboard className="w-4 h-4" />
                      </button>
                    </span>
                  </div>
                  <div>{r.cookie}</div>
                </div>
                <div className="w-2/12 flex gap-1">
                  <Button
                    size={"small"}
                    type={"default"}
                    onClick={() => setViewingItem(r)}
                  >
                    View
                  </Button>
                  <Popconfirm
                    title={"Are you sure?"}
                    onConfirm={() => deleteCookieReferral(r.id)}
                  >
                    <Button size={"small"} danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export const CookieReferralList = observer(CookieReferralListRaw);
