import "./index.css";
import "./tailwind_output.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { BrowserRouter } from "react-router-dom";
import { MainStoreProvider } from "./mainStoreProvider";
import { StyleProvider } from "@ant-design/cssinjs";

function executeApp() {
  const domNode = document.getElementById("root");
  const root = createRoot(domNode!);

  root.render(
    <React.StrictMode>
      <StyleProvider hashPriority="high">
        <BrowserRouter>
          <MainStoreProvider>
            <App />
          </MainStoreProvider>
        </BrowserRouter>
      </StyleProvider>
    </React.StrictMode>
  );
}

executeApp();
