import * as React from "react";
import { observer } from "mobx-react-lite";
import { JSONModal } from "../../../../jsonModal";
import { Button } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { ITrialDomain } from "../../utilities/types";
import { ExportItem } from "../../utilities/helper";

interface IProps {
  trialDomain: ITrialDomain;
}

const TrialDomainInfoRaw: React.FC<IProps> = ({ trialDomain }) => {
  // Use States //
  const [isViewing, setIsViewing] = React.useState(false);

  return (
    <>
      <div>
        <div className="flex items-center gap-2">
          <PageHeader
            title={trialDomain.domainName}
            subTitle={"Trial Domain Detail - Info"}
          />
          <Button onClick={() => setIsViewing(true)}>View</Button>
          <Button
            onClick={() => ExportItem(trialDomain, trialDomain.domainName)}
          >
            Export
          </Button>
        </div>
        <div className="px-6">
          {trialDomain != undefined ? (
            <>
              {trialDomain.creatorEmail?.length != 0 ? (
                <>
                  <div className="text-base text-gray-700 font-semibold">
                    Creator Email
                  </div>
                  <div className="mb-4 mt-1">{trialDomain?.creatorEmail}</div>
                </>
              ) : null}
              <div className="text-base text-gray-700 font-semibold">
                Account Type
              </div>
              <div className="mb-4 mt-1">{trialDomain.accountTypeName}</div>
              <div className="text-base text-gray-700 font-semibold">
                Remaining Account Quota
              </div>
              <div className="mb-4 mt-1">{trialDomain.accountsRemaining}</div>
              <div className="text-base text-gray-700 font-semibold">
                Account Count
              </div>
              <div className="mb-4 mt-1">{trialDomain.accounts.length}</div>
              <div className="text-base text-gray-700 font-semibold">
                Org Count
              </div>
              <div className="mb-4 mt-1">{trialDomain.orgs.length}</div>
            </>
          ) : null}
        </div>
      </div>
      {isViewing ? (
        <JSONModal
          filename={trialDomain.domainName}
          title={`Trial Domain: ${trialDomain.domainName}`}
          visible={!!isViewing}
          object={trialDomain}
          onClose={() => setIsViewing(false)}
        />
      ) : null}
    </>
  );
};

export const TrialDomainInfo = observer(TrialDomainInfoRaw);
