export type Timestep = "month" | "day";

export interface AccountState {
  id: string;
  startTime: string | null;
  endTime: string | null;
  paymentMethodType: PaymentMethod;
  ratePlan: RatePlan;
  trustLevel: TrustLevel;
  type: AccountStateType;
}
export interface AccountStatesResponse {
  states: AccountState[];
}
export type PreviewAccountStateAction =
  | "default"
  | "change"
  | "delete"
  | "update"
  | "create";
export interface PreviewAccountState extends AccountState {
  action: PreviewAccountStateAction;
}

export interface TrustLevel {
  id: string;
  name: string;
  description: string;
  accumulatedChargeTrigger: number; // cents
  deactivateOnFailedCharge: boolean;
}

export interface ChargeableItem {
  id: string;
  name: string;
  description: string;
  consumptionTags: { [_: string]: string };
  unit: string;
}

export interface RatePlanItem {
  id: string;
  chargeRate: number;
  chargeRateDenominator: number;
  meteringGroup: string;
  meteringThreshold: number;
  chargeableItem: ChargeableItem;
}

export interface RatePlan {
  id: string;
  name: string;
  description: string;
  currency: string;
  items: RatePlanItem[];
}

export type PaymentMethod = "credit-card" | "invoice";
export type AccountStateType = "paying" | "trial";

export interface InvoicePeriod {
  id: string;
  startTime: string;
  endTime: string;
  totalCharge: number; // cents
  exportedAt: string | null;
  createdAt: string;
  accountState: AccountState;
  items: {
    id: string;
    description: string;
    quantity: number;
    charge: number; // cents
    org: string;
    ratePlanItem: RatePlanItem;
  };
}

export interface Invoice {
  id: string;
  startTime: string;
  endTime: string;
  totalCharge: number; // cents
  createdBy: string;
  exportedAt: string | null;
  createdAt: string;
  periods: InvoicePeriod[];
}

export interface InvoicesResponse {
  invoices: Invoice[];
}

export const SUPPORTED_CURRENCIES = ["USD"];

export type StringKeyValue = {
  [_: string]: string;
};

export type PageDataState = "idle" | "fetching" | "done";

export type AccountStateChanges = {
  accountState: AccountState;
  before: AccountState;
  type: "create" | "delete" | "update";
}[];

export type AccountStatePageMode = "edit" | "plan";
