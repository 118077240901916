import * as React from "react";
import { observer } from "mobx-react-lite";
import { Tabs } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { BillingChargableItems } from "./BillingChargableItems";
import { BillingTrustLevels } from "./BillingTrustLevels";
import { BillingRatePlans } from "./BillingRatePlans";
import { v4 as uuidv4 } from "uuid";
import { BillingReports } from "./BillingReports";
import { useLocation } from "react-router-dom";
import qs from "qs";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

type TabKey =
  | "chargableItems"
  | "trustLevels"
  | "ratePlans"
  | "reports"
  | "reporting";
const BillingIndexRaw: React.FC = () => {
  const defaultActiveKey: TabKey = "chargableItems";

  const [keyId, setKeyId] = React.useState<string>(uuidv4());
  const [tabKey, setTabKey] = React.useState<TabKey>(defaultActiveKey);

  const location = useLocation();
  const { selectedTab } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  React.useEffect(() => {
    if (selectedTab) {
      setTabKey(selectedTab.toString() as TabKey);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Billing - Admin Panel
        </title>
      </Helmet>
      <div className={"pb-8"}>
        <PageHeader title={"Billing"} backIcon={false} />
        <Tabs
          key={keyId}
          className={"pl-6"}
          defaultActiveKey={defaultActiveKey}
          activeKey={tabKey}
          onChange={(key) => {
            setKeyId(uuidv4());
            setTabKey(key as any);
          }}
          items={[
            {
              label: `Chargable Items`,
              key: "chargableItems",
              children: <BillingChargableItems />,
            },
            {
              label: `Trust Levels`,
              key: "trustLevels",
              children: <BillingTrustLevels />,
            },
            {
              label: `Rate Plans`,
              key: "ratePlans",
              children: <BillingRatePlans />,
            },
            {
              label: `Reports`,
              key: "reports",
              children: <BillingReports />,
            },
          ]}
        />
      </div>
    </>
  );
};

export const BillingIndex = observer(BillingIndexRaw);
