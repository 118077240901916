import * as React from "react";
import { Modal, notification } from "antd";
import { request } from "./utils";
import { observer } from "mobx-react-lite";
import { CopyOutlined, DownloadOutlined } from "@ant-design/icons";
import { HighlightedText } from "./highlightedText";

interface Props {
  object?: any;
  kind?: string;
  link?: string;
  onClose: any;
  visible: boolean;
  title: string;
  filename: string;
}

const JSONModalRaw: React.FC<Props> = ({
  title,
  object,
  kind,
  link,
  onClose,
  visible,
  filename,
}) => {
  const [failure, setFailure] = React.useState<boolean | null>(null);
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    prepare();
  }, []);

  async function prepare() {
    if (object) {
      setData(object);
      setFailure(false);
      return;
    }

    try {
      const res = await request({ url: link! });
      setData(res);
      setFailure(false);
    } catch (e) {
      setFailure(true);
    }
  }

  async function copyToClipboard() {
    navigator.clipboard.writeText(JSON.stringify(data, null, 2));
    notification.success({
      message: "Copied to clipboard",
    });
  }

  function getHrefOfJson() {
    const file = new Blob([JSON.stringify(data, null, 2)], {
      type: "text/json",
    });
    return URL.createObjectURL(file);
  }

  if (failure === true) {
    return (
      <Modal
        title={"JSON"}
        open={true}
        onCancel={onClose}
        onOk={prepare}
        okText={"Try Again"}
      >
        <div>Failed to show item, please try again.</div>
      </Modal>
    );
  }

  if (!object && !kind && !link) {
    console.error(
      "JSONModal, at least object or kind and link couple is required"
    );
    return null;
  }

  if (data === null || failure === null) {
    return null;
  }

  return (
    <Modal
      className="modal-parent-custom"
      title={
        <div className="flex items-baseline">
          <div className="font-bold mr-4">{title}</div>
          <div
            onClick={copyToClipboard}
            className="text-blue-500 mr-4 text-sm cursor-pointer"
          >
            <span className="mr-2">Copy</span>
            <CopyOutlined />
          </div>
          <a
            href={getHrefOfJson()}
            download={`${filename}.json`}
            className="text-blue-500 text-sm"
          >
            <span className="mr-2">Download</span>
            <DownloadOutlined />
          </a>
        </div>
      }
      open={visible}
      onCancel={onClose}
      destroyOnClose
      footer={null}
      styles={{ body: { height: "70vh", padding: 0, overflow: "auto" } }}
      width={1000}
    >
      <HighlightedText
        data={data}
        language={"json"}
        formatter={(v) => JSON.stringify(v, null, 2)}
      />
    </Modal>
  );
};

export const JSONModal = observer(JSONModalRaw);
