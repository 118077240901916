import * as React from "react";
import { Info, AlertOctagon, AlertTriangle, CheckCircle } from "react-feather";

const iconStyle: React.CSSProperties = {
  width: 16,
  height: 16,
};

export const AvailableIcon: React.FC = () => {
  return <CheckCircle style={{ ...iconStyle, color: "green" }} />;
};

export const StatusAvailable: React.FC = () => {
  return (
    <div className={"flex items-center gap-1 text-sm"}>
      <AvailableIcon />
      <div>Available</div>
    </div>
  );
};

export const DisruptionIcon: React.FC = () => {
  return <AlertTriangle style={{ ...iconStyle, color: "orange" }} />;
};

export const StatusDisruption: React.FC = () => {
  return (
    <div className={"flex items-center gap-1 text-sm"}>
      <DisruptionIcon />
      <div>Disruption</div>
    </div>
  );
};

export const OutageIcon: React.FC = () => {
  return <AlertOctagon style={{ ...iconStyle, color: "red" }} />;
};

export const StatusOutage: React.FC = () => {
  return (
    <div className={"flex items-center gap-1 text-sm"}>
      <OutageIcon />
      <div>Outage</div>
    </div>
  );
};

export const MaintenanceIcon: React.FC = () => {
  return <Info style={{ ...iconStyle, color: "blue" }} />;
};

export const StatusMaintenance: React.FC = () => {
  return (
    <div className={"flex items-center gap-1 text-sm"}>
      <MaintenanceIcon />
      <div>Maintenance</div>
    </div>
  );
};

export const IconsLegend: React.FC = () => {
  return (
    <div className={"flex items-center gap-1 pr-8 text-sm"}>
      <div className={"uppercase text-gray-500 font-bold text-xs pr-2"}>
        Legend
      </div>
      <StatusAvailable />
      <div className={"w-4"} />
      <StatusDisruption />
      <div className={"w-4"} />
      <StatusOutage />
      <div className={"w-4"} />
      <StatusMaintenance />
    </div>
  );
};

export const Icons: { [_: string]: React.ElementType } = {
  available: AvailableIcon,
  disruption: DisruptionIcon,
  outage: OutageIcon,
  maintenance: MaintenanceIcon,
};

export const IconsWithLabel: { [_: string]: React.ElementType } = {
  available: StatusAvailable,
  disruption: StatusDisruption,
  outage: StatusOutage,
  maintenance: StatusMaintenance,
};
