import * as React from "react";
import { observer } from "mobx-react-lite";
import { Input, Table, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Link } from "react-router-dom";
import { ITrialDomain } from "../utilities/types";
import { ColumnsType } from "antd/lib/table";
import { FetchTrialDomains } from "../utilities/helper";

const TrialDomainsListRaw: React.FC = () => {
  // Use States //
  const [isLoading, setIsLoading] = React.useState(true);
  const [filter, setFilter] = React.useState("");
  const [domains, setDomains] = React.useState<ITrialDomain[]>([]);

  // Use Effects //
  React.useEffect(() => {
    fetchTrialDomains();
  }, []);

  // Initialize //
  const dataSource = domains.filter((domain) =>
    domain.domainName.toLowerCase().includes(filter.toLowerCase())
  );

  const columns = [
    {
      title: "Domain",
      dataIndex: "domainName",
      key: "domainName",
      render: (text: string) => (
        <Link className="text-base text-blue-600 truncate" to={`${text}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Creator Email",
      dataIndex: "creatorEmail",
      key: "creatorEmail",
    },
    {
      title: "Remaining Account Quota",
      dataIndex: "accountsRemaining",
      key: "accountsRemaining",
    },
    {
      title: "Account Count",
      dataIndex: "accounts",
      key: "accounts",
      render: (_: string, record: ITrialDomain) => (
        <span>{record.accounts.length}</span>
      ),
    },
    {
      title: "Org Count",
      dataIndex: "orgs",
      key: "orgs",
      render: (_: string, record: ITrialDomain) => (
        <span>{record.orgs.length}</span>
      ),
    },
  ];

  // Functions //
  async function fetchTrialDomains() {
    setIsLoading(true);
    const response: ITrialDomain[] | string = await FetchTrialDomains();
    if (typeof response === "string") {
      notification.warning({
        message: "Failed to fetch trial domains",
        description: response,
      });
    } else {
      setDomains(response);
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="flex items-center">
        <PageHeader title={"Trial Domains"} backIcon={false} />
        <Input
          style={{ width: 450 }}
          value={filter}
          placeholder={"Filter by name or description"}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
      <div className="px-6 pb-4">
        <Table
          key={"id"}
          rowKey={"id"}
          columns={columns as ColumnsType<ITrialDomain>}
          dataSource={dataSource}
          loading={isLoading}
          size={"small"}
          pagination={{ showSizeChanger: false }}
          bordered
        />
      </div>
    </>
  );
};

export const TrialDomainsList = observer(TrialDomainsListRaw);
