import {
  Button,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Spin,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { useMain } from "../../stores/main";
import { formatTimestamp, request } from "../../utils";
import moment from "moment-timezone";
import { AccountStateContext } from "./AccountState/state/accountState.context.store";
import { AccountStatesResponse } from "../Billing/types";
import { AccountStateCard } from "./AccountStateCard";

type RouteParams = "uuid";
const AccountInfoRaw: React.FC = () => {
  const navigate = useNavigate();
  const main = useMain();
  const { uuid } = useParams<RouteParams>();
  const [account, setAccount] = React.useState(null as any);
  const [current, setCurrent] = React.useState<number>(0);
  const [isViewing, setIsViewing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const states = [
    { label: "Prev", state: AccountStateContext.prevAccountState },
    { label: "Current", state: AccountStateContext.currentAccountState },
    { label: "Next", state: AccountStateContext.nextAccountState },
  ];

  async function fetchAccountStates() {
    const res = await request<AccountStatesResponse>({
      service: "billing-ng",
      url: `/account/${uuid}/states?from=${AccountStateContext.startISOBuffered}&to=${AccountStateContext.endISOBuffered}`,
    });
    AccountStateContext.setAccountStates(res.states || []);
  }

  React.useEffect(() => {
    fetchAccount();
    fetchAccountStates();
    fetchCurrentAmount();
  }, []);

  React.useEffect(() => {
    main.updateLastActivityTimestamp();
  }, [isViewing]);

  async function fetchAccount() {
    try {
      const res = await request({
        service: "billing-ng",
        url: `/account/${uuid}`,
      });
      setAccount(res);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch account",
        description: errorMessage,
      });
      navigate("/admin/account");
    }
  }

  async function fetchCurrentAmount() {
    try {
      const res = await request({
        service: "billing-ng",
        url: `/account/${uuid}/charges/invoices/-generate`,
        method: "post",
        body: {
          dryRun: true,
          startTime: moment().startOf("month"),
          endTime: moment().add(1, "month").startOf("month"),
        },
      });
      setCurrent(res.totalCharge / 100);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch current balance",
        description: errorMessage,
      });
    }
  }

  async function onToggleEnabled(id: string, action: boolean) {
    try {
      setIsLoading(true);
      await request({
        service: "billing-ng",
        url: `/account/${id}/enable/${String(action)}`,
        method: "put",
      });
      setIsLoading(false);
      await fetchAccount();
      notification.success({
        message: "Success",
        description: "Toggled the enabled status of account",
      });
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to toggle enabled status of account",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function exportItem() {
    main.updateLastActivityTimestamp();
    const file = new Blob([JSON.stringify(account, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute(
      "download",
      `${account.accountName || account.fullName || "No Name"}.json`
    );
    aElement.click();
  }

  // TODO remove this
  const [accountTypes, setAccountTypes] = React.useState([]);
  React.useEffect(() => {
    request({ service: "billing-ng", url: "/account_type" }).then((data) => {
      setAccountTypes(data.accountTypes.map((t: any) => t.name));
    });
  }, []);

  React.useEffect(() => {
    if (!account) {
      return;
    }
    setAccountOrgQuota(account.orgQuota || -1);
  }, [account]);

  const [isChangingOrgQuota, setIsChangingOrgQuota] = React.useState(false);
  const [accountOrgQuota, setAccountOrgQuota] = React.useState<number>(-1);
  const [isAccountOrgQuotaLoading, setIsAccountOrgQuotaLoading] =
    React.useState(false);

  // TODO try this
  async function tryChangeAccountOrgQuota() {
    if (account.orgQuota === accountOrgQuota) {
      setIsChangingOrgQuota(false);
      return;
    }

    try {
      setIsAccountOrgQuotaLoading(true);
      const body: any = {
        account: {
          ...account,
          orgQuota: accountOrgQuota,
        },
      };

      delete body.account.accountId;
      delete body.account.id;
      delete body.account.crmId;

      await request({
        method: "put",
        service: "billing-ng",
        url: `/account/${uuid}`,
        body,
      });
      setIsAccountOrgQuotaLoading(false);
      setIsChangingOrgQuota(false);
      notification.success({
        message: "Updated org quota",
        description: "It might take a few minutes for it to take effect.",
      });
      fetchAccount();
    } catch (e) {
      setIsAccountOrgQuotaLoading(false);
      notification.warning({
        message: "Failed",
        description: e.response?.data?.error || e.message,
      });
    }
  }

  if (account === null) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Spin spinning={true} />
      </div>
    );
  }

  return (
    <>
      <div className="px-8 w-full">
        <div className="flex items-center gap-2">
          <PageHeader
            title={account.accountName}
            subTitle={"Account Detail - Info"}
          />
          <Popconfirm
            title={`Are you sure you want to ${
              account.enabled === true ? "disable" : "enable"
            } this account?`}
            onVisibleChange={() => {
              main.updateLastActivityTimestamp();
            }}
            onConfirm={() => {
              onToggleEnabled(
                account.id,
                account.enabled === true ? false : true
              );
            }}
            onCancel={() => {}}
            okText={account.enabled === true ? "Disable" : "Enable"}
            okButtonProps={{ danger: account.enabled === true }}
            cancelText="Cancel"
          >
            <Button
              disabled={isLoading}
              loading={isLoading}
              type={"primary"}
              danger={account.enabled === true}
              size={"small"}
            >
              {account.enabled === true ? "Disable" : "Enable"}
            </Button>
          </Popconfirm>
          <Button size={"small"} onClick={() => setIsViewing(true)}>
            View
          </Button>
          <Button size={"small"} onClick={() => exportItem()}>
            Export
          </Button>
        </div>
        <div className="px-6">
          {current === null ? null : (
            <div className="mb-4 flex flex-col">
              <div className="text-base text-gray-700 font-semibold">
                Current Balance (for the month)
              </div>
              <div className="mt-2 flex items-center gap-2">
                <div>
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(Number(current))}
                </div>
              </div>
            </div>
          )}
          <div className="w-1/2">
            <div className={`flex gap-2 items-center`}>
              <div className="text-base text-gray-700 font-semibold">
                Org Quota
              </div>
              <Button
                size={"small"}
                type={"primary"}
                disabled={!account || !accountOrgQuota}
                onClick={() => setIsChangingOrgQuota(true)}
              >
                Change
              </Button>
            </div>
            <div className="mb-4 mt-1">{account.orgQuota}</div>
          </div>
          <div className="flex items-center">
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">Id</div>
              <div className="mb-4 mt-1">{account.id}</div>
            </div>
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Stripe Id
              </div>
              <div className="mb-4 mt-1">{account.accountId}</div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Account Name
              </div>
              <div className="mb-4 mt-1">
                {account.accountName || "Not Found"}
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Full Name
              </div>
              <div className="mb-4 mt-1">{account.fullName || "Not Found"}</div>
            </div>
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Company
              </div>
              <div className="mb-4 mt-1">
                {account.extraInfo?.company || "Not Found"}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Enabled Status
              </div>
              <div
                className={`font-semibold mb-4 mt-1 ${
                  account.enabled ? "text-green-700" : "text-red-700"
                }`}
              >
                {account.enabled ? "Enabled" : "Disabled"}
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Created
              </div>
              <div className="mb-4 mt-1">
                {formatTimestamp(account.created)}
              </div>
            </div>
            <div className="w-1/3">
              <div className="text-base text-gray-700 font-semibold">
                Last Modified
              </div>
              <div className="mb-4 mt-1">
                {formatTimestamp(account.lastModified)}
              </div>
            </div>
          </div>
          <div className="flex gap-4 w-full" style={{ overflowX: "auto" }}>
            {states.map(({ label, state }) => {
              if (!state) {
                return null;
              }
              return (
                <AccountStateCard
                  key={label}
                  label={label}
                  state={state}
                  account={account}
                />
              );
            })}
          </div>
        </div>
      </div>
      {isViewing ? (
        <JSONModal
          filename={account.accountName || account.fullName || "No Name"}
          onClose={() => setIsViewing(false)}
          title={`Account: ${
            account.accountName || account.fullName || "No Name"
          }`}
          visible={!!isViewing}
          object={account}
        />
      ) : null}
      {isChangingOrgQuota ? (
        <Modal
          open={isChangingOrgQuota}
          title={"Change Account Org Quota"}
          maskClosable={false}
          closable={false}
          onCancel={() => setIsChangingOrgQuota(false)}
          cancelButtonProps={{ disabled: isAccountOrgQuotaLoading }}
          onOk={tryChangeAccountOrgQuota}
          okButtonProps={{
            disabled: !accountOrgQuota || isAccountOrgQuotaLoading,
            loading: isAccountOrgQuotaLoading,
          }}
        >
          <InputNumber
            value={accountOrgQuota}
            // TODO fix type
            onChange={(value) => setAccountOrgQuota(value!)}
          />
        </Modal>
      ) : null}
    </>
  );
};

export const AccountInfo = observer(AccountInfoRaw);
