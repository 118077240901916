import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { ComponentDetail } from "./ComponentDetail";
import { ComponentList } from "./ComponentList";
import { IncidentEdit } from "./IncidentEdit";
import { IconsLegend } from "./icons";
import { IncidentDetail } from "./IncidentDetail";
import { EventEdit } from "./EventEdit";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const IncidentsIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Incidents - Admin Panel
        </title>
      </Helmet>
      <div className={"relative"}>
        <div className={"absolute"} style={{ right: 20, top: 20 }}>
          <IconsLegend />
        </div>
        <Routes>
          <Route
            path={`component/:component/incident/:incidentId/event/:eventId`}
            element={<EventEdit />}
          />
          <Route
            path={`component/:component/incident/:incidentId/edit`}
            element={<IncidentEdit />}
          />
          <Route
            path={`component/:component/incident/:incidentId`}
            element={<IncidentDetail />}
          />
          <Route path={`component/:component`} element={<ComponentDetail />} />
          <Route index element={<ComponentList />} />
        </Routes>
      </div>
    </>
  );
};

export const IncidentsIndex = observer(IncidentsIndexRaw);
