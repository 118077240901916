import * as React from "react";
import { Table, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { request } from "../../utils";
import { Link } from "react-router-dom";

const AclListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [admins, setAdmins] = React.useState<string[]>([]);

  React.useEffect(() => {
    fetchAdmins();
  }, []);

  async function fetchAdmins() {
    try {
      setIsLoading(true);
      const url = "/admin/acl";
      const res = await request({ url });
      setAdmins(res);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch admins",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => (
        <Link className="text-base text-blue-600 truncate" to={`${text}`}>
          {record.email}
        </Link>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center">
        <PageHeader title={"Admins"} backIcon={false} />
        <Link className="text-blue-600" to={`-add`}>
          Add Admin
        </Link>
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={admins.map((admin) => ({ email: admin }))}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"email"}
        />
      </div>
    </>
  );
};

export const AclList = observer(AclListRaw);
