import { request } from "../../utils";
import {
  CPLNLocation,
  Incident,
  IncidentComponentStatus,
  IncidentEditable,
  IncidentEvent,
  IncidentEventEditable,
} from "./types";
import qs from "qs";
import {
  dataToEvent as dataToIncidentEvent,
  dataToIncident,
  editableToEvent as editableToIncidentEvent,
  editableToIncident,
} from "./utils";

export async function fetchComponents() {
  const res = await request({
    url: `/admin/incident/-components`,
  });
  return res.items;
}

export async function updateComponentStatus(
  component: string,
  status: IncidentComponentStatus
) {
  await request({
    url: `/admin/incident/-updatecomponentstatus`,
    method: "post",
    body: { component, status },
  });
}

interface fetchIncidentsProps {
  component?: string;
  daysBack?: number;
  page?: number;
}
export async function fetchIncidents(
  query?: fetchIncidentsProps
): Promise<Incident[]> {
  let url = `/admin/incident`;
  const queryString = qs.stringify(query);
  url += "?" + queryString;

  const res = await request({ url });
  return res.items.map(dataToIncident);
}

export async function fetchIncident(incidentId: string): Promise<Incident> {
  const res = await request({ url: `/admin/incident/${incidentId}` });
  return dataToIncident(res);
}

export async function createIncident(
  incident: IncidentEditable
): Promise<Incident> {
  const res = await request({
    url: `/admin/incident`,
    method: "post",
    body: editableToIncident(incident),
  });
  return dataToIncident(res);
}

export async function replaceIncident(
  incident: IncidentEditable
): Promise<Incident> {
  const res = await request({
    url: `/admin/incident/${incident.id}`,
    method: "put",
    body: editableToIncident(incident),
  });
  return dataToIncident(res);
}

export async function deleteIncident(incidentId: string): Promise<void> {
  await request({
    url: `/admin/incident/${incidentId}`,
    method: "delete",
  });
}

export async function fetchEvents(
  incidentId: string
): Promise<IncidentEvent[]> {
  const res = await request({
    url: `/admin/incident/${incidentId}/event`,
  });
  return res.items.map(dataToIncidentEvent);
}

export async function fetchEvent(
  incidentId: string,
  eventId: string
): Promise<IncidentEvent> {
  const res = await request({
    url: `/admin/incident/${incidentId}/event/${eventId}`,
  });
  return dataToIncidentEvent(res);
}

export async function createEvent(
  incidentId: string,
  incidentEvent: IncidentEventEditable
): Promise<IncidentEvent> {
  const res = await request({
    url: `/admin/incident/${incidentId}/event`,
    method: "post",
    body: editableToIncidentEvent(incidentEvent),
  });
  return dataToIncidentEvent(res);
}

export async function replaceEvent(
  incidentId: string,
  incidentEvent: IncidentEventEditable
): Promise<IncidentEvent> {
  const res = await request({
    url: `/admin/incident/${incidentId}/event/${incidentEvent.id}`,
    method: "put",
    body: editableToIncidentEvent(incidentEvent),
  });
  return dataToIncidentEvent(res);
}

export async function deleteEvent(
  incidentId: string,
  eventId: string
): Promise<void> {
  await request({
    url: `/admin/incident/${incidentId}/event/${eventId}`,
    method: "delete",
  });
}

export async function fetchLocations(): Promise<CPLNLocation[]> {
  const res = await request({
    url: `/admin/locations/all`,
  });
  return res.items;
}
