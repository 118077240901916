import {
  notification,
  Modal,
  Table,
  Button,
  InputNumber,
  Tooltip,
  Input,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { request } from "../../utils";

interface Quota {
  name: string;
  created: string;
  lastModified: string;
  links: { rel: string; href: string }[];
  tags: { [_: string]: string };
  id: string;
  kind: "quota";
  version: number;
  description: string;
  unit: string;
  dimensions: any;
  max: number;
  current: number;
  origin: "default" | "builtin";
}

type RouteParams = "org";
const OrgQuotaRaw: React.FC = () => {
  const { org } = useParams<RouteParams>();
  const [filter, setFilter] = React.useState<string>("");
  const [quotas, setQuotas] = React.useState<Quota[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [editedQuota, setEditedQuota] = React.useState<Quota>(null as any);
  const [viewingItem, setViewingItem] = React.useState<Quota>(null as any);
  const [newMax, setNewMax] = React.useState(0);

  React.useEffect(() => {
    fetchQuotas();
  }, []);

  async function fetchQuotas() {
    try {
      setIsLoading(true);
      let url: string = `/admin/org/${org}/quota`;
      let _quotas: Quota[] = [];
      while (url) {
        const res = await request({ url });
        url = res.links.find((l: any) => l.rel === "next")?.href || "";
        _quotas = _quotas.concat(res.items);
      }
      setQuotas(_quotas);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch quotas",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function onEdit(record: any) {
    setNewMax(record.max);
    setEditedQuota(record);
  }

  async function onConfirmEdit() {
    try {
      setIsLoading(true);
      await request({
        url: `/admin/org/${org}/quota/${editedQuota.id}`,
        method: "patch",
        body: { max: newMax },
      });

      const newQuota = await request({
        url: `/admin/org/${org}/quota/${editedQuota.id}`,
      });
      const _quotas = [...quotas];
      const oldQuota = _quotas.find(
        (_quota: Quota) => _quota.id === editedQuota.id
      )!;
      const quotaIndex = _quotas.indexOf(oldQuota);
      if (quotaIndex >= 0) {
        _quotas[quotaIndex] = newQuota;
        setQuotas(_quotas);
      }
      onCancelEdit();
      notification.success({
        message: "Success",
        description: "Updated quota",
      });

      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch quotas",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  function onCancelEdit() {
    setNewMax(0);
    setEditedQuota(null as any);
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Tooltip title={text} mouseEnterDelay={1}>
            <span className="truncate">{text}</span>
          </Tooltip>
          <Tooltip title={record.description} mouseEnterDelay={1}>
            <span className="truncate">{record.description}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Dimensions",
      dataIndex: "dimensions",
      key: "dimensions",
      render: (_text: string, record: any) => {
        if (!record.dimensions) {
          return <span />;
        }
        if (Object.entries(record.dimensions).length < 1) {
          return <span />;
        }
        return (
          <div className="flex flex-col">
            {Object.entries(record.dimensions).map(
              ([key, value]: [any, any], index) => (
                <span key={index}>
                  {key.replaceAll("link", "").replaceAll("Link", "")}={value}
                </span>
              )
            )}
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: "Current / Max",
      dataIndex: "current",
      key: "current",
      render: (text: string, record: any) => (
        <div style={{ width: 150 }}>
          {text} / {record.max}
        </div>
      ),
    },
    {
      title: "Edit",
      dataIndex: "edit",
      key: "edit",
      render: (_text: string, quota: Quota) => (
        <div className="flex items-center gap-4">
          <Button size={"small"} type={"primary"} onClick={() => onEdit(quota)}>
            Edit
          </Button>
          <Button
            size={"small"}
            type={"default"}
            className="ml-4"
            onClick={() => setViewingItem(quota)}
          >
            View
          </Button>
          <Button
            size={"small"}
            type={"link"}
            className="ml-2"
            onClick={() => exportItem(quota)}
          >
            Export
          </Button>
        </div>
      ),
    },
  ];

  const filteredQuotas = quotas.filter((q) => {
    if (q.name.toLowerCase().includes(filter)) {
      return true;
    }
    if (q.description.toLowerCase().includes(filter)) {
      return true;
    }
    if (
      (Object.values(q.dimensions) as string[]).some((d) =>
        d.toLowerCase().includes(filter)
      )
    ) {
      return true;
    }
    return false;
  });

  return (
    <>
      <div className="px-8 w-full">
        <div className="flex items-center">
          <PageHeader title={org} subTitle={"Org Detail - Quotas"} />
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={"Filter by name, description, dimension values"}
            style={{ width: 450 }}
          />
        </div>
        <Table
          loading={isLoading}
          dataSource={filteredQuotas}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"id"}
        />
      </div>
      <Modal
        title={"Edit Quota"}
        open={editedQuota !== null}
        onOk={onConfirmEdit}
        onCancel={onCancelEdit}
        okText={"Save"}
        okButtonProps={{ disabled: isLoading, loading: isLoading }}
        cancelButtonProps={{ disabled: isLoading }}
      >
        <div className="font-semibold text-lg">{editedQuota?.name}</div>
        <div className="text-base mb-4">
          Description: {editedQuota?.description}
        </div>
        <div className="mb-2">
          <span className="inline-block" style={{ width: 75 }}>
            Max
          </span>
          : {editedQuota?.max}
        </div>
        <div className="mb-2">
          <span className="inline-block" style={{ width: 75 }}>
            Current
          </span>
          : {editedQuota?.current}
        </div>
        <div>
          <span className="inline-block" style={{ width: 75 }}>
            New Max
          </span>
          :
          <InputNumber
            className="ml-1"
            value={newMax}
            onChange={(newValue) => setNewMax(newValue as any)}
          />
        </div>
      </Modal>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.name}
          onClose={() => setViewingItem(null as any)}
          title={`Quota: ${viewingItem.name}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const OrgQuota = observer(OrgQuotaRaw);
