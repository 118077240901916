import * as React from "react";
import { observer } from "mobx-react-lite";
import { Route, Routes } from "react-router-dom";
import { LocationList } from "./LocationList";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

const LocationIndexRaw: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Location - Admin Panel
        </title>
      </Helmet>
      <Routes>
        <Route index element={<LocationList />} />
      </Routes>
    </>
  );
};

export const LocationIndex = observer(LocationIndexRaw);
