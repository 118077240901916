import * as React from "react";
import { Modal, notification, Table, Button, Tag, Dropdown, MenuProps, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { JSONModal } from "../../jsonModal";
import { InspectImpersonateType, formatTimestamp, onImpersonateUser, request } from "../../utils";
import { CONSOLE_STAGING } from "../../envVariables";

type RouteParams = "org";

const OrgUserRaw: React.FC = () => {
  const { org: _org } = useParams<RouteParams>();
  const org = _org!;

  const [users, setUsers] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [viewingItem, setViewingItem] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    try {
      setIsLoading(true);
      const res = await request({ url: `/admin/org/${org}/user` });
      setUsers(res.items);
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch users",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function onImpersonate(user: any, type: InspectImpersonateType) {
    Modal.confirm({
      title: "Are you sure?",
      content: `Impersonating a user has write permissions depending on user's role. Inspect the org instead if you want a read-only view.`,
      async onOk() {
        try {
          setIsLoading(true);
          await onImpersonateUser(org, user.email, type);
          setIsLoading(false);
        } catch (e) {
          let errorMessage = e?.response?.data?.message;
          if (!errorMessage) errorMessage = e.message;
          notification.warning({
            message: "Failed to fetch inspect org token",
            description: errorMessage,
          });
          setIsLoading(false);
        }
      },
      onCancel() {},
    });
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const onImpersonateMenuClick: (user: any) => MenuProps["onClick"] = (user) => (e) => {
    onImpersonate(user, e.key as any);
  };

  const impersonateMenuItems = [
    {
      key: "local",
      label: "Local",
    },
  ];
  if (CONSOLE_STAGING) {
    impersonateMenuItems.unshift({ key: "staging", label: "Staging" });
  }

  const filteredUsers = !filter
    ? users
    : users.filter(
        (user: any) => user.name.toLowerCase().includes(filter) || user.email.toLowerCase().includes(filter),
      );

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          <Link className="text-base text-blue-600 truncate" to={`/admin/user/${record.id}`}>
            {text}
          </Link>
          <span className="text-sm truncate">{record.name}</span>
        </div>
      ),
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: any) => (
        <>
          {Object.entries(tags || {}).map(([key, value]: [any, any]) => {
            return (
              <Tag key={key}>
                {key}={value}
              </Tag>
            );
          })}
        </>
      ),
      ellipsis: true,
    },
    {
      title: "Timestamps",
      dataIndex: "created",
      key: "created",
      render: (created: string, record: any) => (
        <div className="flex flex-col">
          <span>Created: {formatTimestamp(created)}</span>
          <span>Last Modified: {formatTimestamp(record.lastModified)}</span>
        </div>
      ),
    },
    {
      title: "Impersonate",
      dataIndex: "impersonate",
      key: "impersonate",
      render: (_text: string, record: any) => (
        <div className="flex items-center">
          <div>
            <Dropdown.Button
              type={"primary"}
              size={"small"}
              onClick={() => onImpersonate(record, "default")}
              menu={{
                items: impersonateMenuItems,
                onClick: onImpersonateMenuClick(record),
              }}
            >
              Impersonate
            </Dropdown.Button>
          </div>
          <Button size={"small"} type={"default"} className="ml-4" onClick={() => setViewingItem(record)}>
            View
          </Button>
          <Button size={"small"} type={"link"} className="ml-2" onClick={() => exportItem(record)}>
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="px-8 w-full">
        <div className="flex items-center gap-4">
          <PageHeader title={org} subTitle={"Org Detail - Users"} />
          <Input
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            placeholder="Search by name or email"
            style={{ width: 450 }}
          />
        </div>
        <Table
          loading={isLoading}
          dataSource={filteredUsers}
          columns={columns}
          pagination={{ showSizeChanger: false }}
          rowKey={"email"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`User: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const OrgUser = observer(OrgUserRaw);
