import { makeAutoObservable } from "mobx";
import {
  Incident,
  IncidentComponent,
  IncidentEvent,
} from "../Admin/Incidents/types";

class IncidentMobx {
  components: IncidentComponent[] = [];
  incidents: Incident[] = [];
  events: IncidentEvent[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setComponents(components: IncidentComponent[]) {
    this.components = components;
  }

  setIncidents(incidents: Incident[]) {
    this.incidents = incidents;
  }

  setIncidentEvents(events: IncidentEvent[]) {
    this.events = events;
  }

  getIncidents(component: string) {
    return this.incidents.filter((i) => i.component === component);
  }
}

export const IncidentStore = new IncidentMobx();
