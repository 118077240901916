import * as React from "react";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "./envVariables";
import { Button } from "antd";

const Error500: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>{IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Error - Admin Panel</title>
      </Helmet>
      <div>
        <div className="pt-4 pb-4 d-flex align-items-end mt-auto">
          <img
            style={{ width: 400, height: "auto" }}
            src={"/resources/logos/controlPlaneLogo.svg"}
            alt="Control Plane Logo"
          />
        </div>
        <div className={`pl-5 pr-5 pt-5 pb-5 mb-auto text-dark font-size-30`}>
          <h1 className="font-weight-bold mb-3">Something Went Wrong</h1>
          <h3>
            We are sorry. This error has been logged and will be investigated.
            Please retry later.
          </h3>
          <Button onClick={() => window.location.replace("/")}>Go Back</Button>
        </div>
        {/* <div className={`n-footer bg-white no-left-menu`} style={{ borderTop: "1px solid #e4e9f0" }}>
          <Footer />
        </div> */}
      </div>
    </>
  );
};

export default Error500;
