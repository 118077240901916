import * as React from "react";
import { Button } from "antd";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "./envVariables";
import { VersionCopyright } from "./Version";

class ServiceDown extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>
            {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}Error - Admin Panel
          </title>
        </Helmet>
        <div className={"error-container"}>
          <div className={`error-top`}>
            <h1>Server is not responding</h1>
            <p>Data service is down currently. Please try again later.</p>
            <Button onClick={() => window.location.reload()}>Refresh</Button>
          </div>
          <div className="error-bottom">
            <div>
              <VersionCopyright /> 2021 Control Plane. All rights reserved.
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ServiceDown;
