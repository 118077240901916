import { makeAutoObservable } from "mobx";
import { AccountStateContext } from "./accountState.context.store";
import {
  AccountState,
  AccountStateChanges,
  AccountStateType,
  PaymentMethod,
  PreviewAccountState,
} from "../../../Billing/types";
import {
  endInfinityMoment,
  isoToMoment,
  momentToIso,
  startInfinityMoment,
} from "../Timeline/utils";

class EditClass {
  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  reset() {
    this.setSelectedStateId(null);
    this.setStartMoment(startInfinityMoment);
    this.setEndMoment(endInfinityMoment);
    this.setTrustLevelId("");
    this.setRatePlanId("");
    this.setPaymentMethod("credit-card");
    this.setType("paying");
    this.setHasFetchedPreview(false);
    this.setStateChanges([]);
    this.setPreviewError("");
    this.setSelectionStartMoment(null);
    this.setSelectionEndMoment(null);
  }

  selectedStateId: string | null = null;
  setSelectedStateId(id: string | null | undefined) {
    this.selectedStateId = id || null;
    if (this.hasSelectedState) {
      const state = this.selectedState!;
      this.setStartMoment(isoToMoment(state.startTime, "start"));
      this.setEndMoment(isoToMoment(state.endTime, "end"));
      this.setTrustLevelId(state.trustLevel.id);
      this.setRatePlanId(state.ratePlan.id);
      this.setPaymentMethod(state.paymentMethodType);
      this.setType(state.type);
    }
  }
  get hasSelectedState() {
    return !!this.selectedStateId;
  }
  get selectedState() {
    if (!this.hasSelectedState) {
      return null;
    }
    let accountState = AccountStateContext.accountStates.find(
      (accountState) => accountState.id === this.selectedStateId
    )!;
    if (!accountState) {
      return null;
    }
    accountState = JSON.parse(JSON.stringify(accountState));
    return accountState;
  }

  selectionStartMoment: moment.Moment | null = null;
  selectionEndMoment: moment.Moment | null = null;
  setSelectionStartMoment(value: moment.Moment | null) {
    this.selectionStartMoment = value ? value.clone() : null;
    this._tryUpdatingStartEndMoments();
  }
  setSelectionEndMoment(value: moment.Moment | null) {
    this.selectionEndMoment = value ? value.clone() : null;
    this._tryUpdatingStartEndMoments();
  }

  _tryUpdatingStartEndMoments() {
    if (!this.selectionStartMoment || !this.selectionEndMoment) {
      return;
    }
    this.setStartMoment(this.selectionStartMoment);
    this.setEndMoment(this.selectionEndMoment);
  }

  startMoment: moment.Moment = startInfinityMoment.clone();
  endMoment: moment.Moment = endInfinityMoment.clone();
  setStartMoment(value: moment.Moment) {
    this.startMoment = value.clone();
  }
  setEndMoment(value: moment.Moment) {
    this.endMoment = value.clone();
  }
  get startISO() {
    return momentToIso(this.startMoment);
  }
  get endISO() {
    return momentToIso(this.endMoment);
  }

  trustLevelId: string = "";
  setTrustLevelId(value: string) {
    this.trustLevelId = value || "";
  }

  ratePlanId: string = "";
  setRatePlanId(value: string) {
    this.ratePlanId = value || "";
  }

  paymentMethod: PaymentMethod = "credit-card";
  setPaymentMethod(value: PaymentMethod) {
    this.paymentMethod = value;
  }

  type: AccountStateType = "paying";
  setType(value: AccountStateType) {
    this.type = value;
  }

  hasFetchedPreview: boolean = false;
  setHasFetchedPreview(value: boolean) {
    this.hasFetchedPreview = value;
  }

  stateChanges: AccountStateChanges = [];
  setStateChanges(value: AccountStateChanges) {
    this.stateChanges = value;
  }

  previewError: string = "";
  setPreviewError(value: string) {
    this.previewError = value;
  }

  get previewAccountStates(): PreviewAccountState[] {
    const mergedPreviewAccountStates: PreviewAccountState[] = JSON.parse(
      JSON.stringify(AccountStateContext.accountStates)
    ).map((accountState: AccountState) => ({
      ...accountState,
      action: "default",
    }));

    for (const stateChange of this.stateChanges) {
      if (stateChange.type === "create") {
        mergedPreviewAccountStates.push({
          ...stateChange.accountState,
          action: "create",
        });
        continue;
      }

      const changedStateId = stateChange.before.id;
      const changedStateIndex = mergedPreviewAccountStates.findIndex(
        (s) => s.id === changedStateId
      );

      // shouldnt happen
      if (changedStateIndex < 0) {
        console.log("ERROR");
        console.log(
          JSON.stringify({
            accountStates: AccountStateContext.accountStates,
            stateChanges: this.stateChanges,
            mode: AccountStateContext.mode,
          })
        );
        continue;
      }

      // replace on index
      if (stateChange.type === "update") {
        mergedPreviewAccountStates[changedStateIndex] = {
          ...stateChange.accountState,
          action: "update",
        };
      }

      // for delete
      mergedPreviewAccountStates[changedStateIndex].action = stateChange.type;
    }
    return mergedPreviewAccountStates;
  }

  get requestBody() {
    return {
      trustLevelId: this.trustLevelId,
      paymentMethodType: this.paymentMethod,
      ratePlanId: this.ratePlanId,
      type: this.type,
    };
  }

  get canPreview() {
    let result = true;
    if (!this.trustLevelId) result = false;
    if (!this.ratePlanId) result = false;
    if (AccountStateContext.mode === "plan") {
      if (!this.selectionStartMoment) result = false;
      if (!this.selectionEndMoment) result = false;
    }
    return result;
  }
}

export const EditStore = new EditClass();
