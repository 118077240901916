import * as React from "react";
import { Menu } from "antd";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { OrgInfo } from "./OrgInfo";
import { OrgInspect } from "./OrgInspect";
import { OrgQuota } from "./OrgQuota";
import { OrgUser } from "./OrgUser";
import { OrgLocation } from "./OrgLocation";
import { OrgConfig } from "./OrgConfig";
import { Helmet } from "react-helmet";
import { IS_TEST_ENVIRONMENT } from "../../envVariables";

type RouteParams = "org";
const OrgDetailRaw: React.FC = () => {
  const { pathname } = useLocation();
  const { org: orgName } = useParams<RouteParams>();
  const navigate = useNavigate();

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    switch (true) {
      case pathname.includes("/info"):
        _menuActiveKey = "info";
        break;
      case pathname.includes("/config"):
        _menuActiveKey = "config";
        break;
      case pathname.includes("/quota"):
        _menuActiveKey = "quota";
        break;
      case pathname.includes("/user"):
        _menuActiveKey = "user";
        break;
      case pathname.includes("/location"):
        _menuActiveKey = "location";
        break;
      case pathname.includes("/inspect"):
        _menuActiveKey = "inspect";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>
          {IS_TEST_ENVIRONMENT ? `TEST | ` : ""}
          {orgName} - Org - Admin Panel
        </title>
      </Helmet>
      <div className="flex p-8">
        <Menu
          theme={"light"}
          items={[
            { key: "info", label: "Info" },
            { key: "config", label: "Config" },
            { key: "quota", label: "Quotas" },
            { key: "user", label: "Users" },
            { key: "location", label: "Locations" },
            { key: "inspect", label: "Inspect" },
          ]}
          selectedKeys={[menuActiveKey]}
          onClick={({ key }) => navigate(key)}
        />
        <Routes>
          <Route path={`info/*`} element={<OrgInfo />} />
          <Route path={`config/*`} element={<OrgConfig />} />
          <Route path={`quota/*`} element={<OrgQuota />} />
          <Route path={`user/*`} element={<OrgUser />} />
          <Route path={`location/*`} element={<OrgLocation />} />
          <Route path={`inspect/*`} element={<OrgInspect />} />
          <Route index element={<Navigate to={`info`} replace />} />
        </Routes>
      </div>
    </>
  );
};

export const OrgDetail = observer(OrgDetailRaw);
