import * as React from "react";
import { Button, Table, notification, TablePaginationConfig, Input } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { observer } from "mobx-react-lite";
import { formatTimestamp, request } from "../../utils";
import { useDebounce } from "../../useDebounce";
import { CloseCircleOutlined, CopyOutlined } from "@ant-design/icons";
import { JSONModal } from "../../jsonModal";
import { Link } from "react-router-dom";

type UserSortColumn = "email" | "lastSignin";
type UserSortOrder = "ascend" | "descend";

const UserListRaw: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [users, setUsers] = React.useState<any[]>([]);
  const nextLinkRef = React.useRef<undefined | string>(undefined);
  const [actingOn, setActingOn] = React.useState("");
  const [action, setAction] = React.useState<"enableDisable" | "logOut" | "setClaims" | "">("");
  const [debouncedSearch, setDebouncedSearch] = useDebounce("", 100);
  const [search, setSearch] = React.useState("");
  const [viewingItem, setViewingItem] = React.useState<any>(null);

  function getDefaultSortColumn(): UserSortColumn {
    return (localStorage.getItem("user-sortColumn") as UserSortColumn) || "lastSignin";
  }
  const [sortColumn, setSortColumn] = React.useState<UserSortColumn>(getDefaultSortColumn());
  React.useEffect(() => {
    localStorage.setItem("user-sortColumn", sortColumn);
  }, [sortColumn]);

  function getDefaultSortOrder(): UserSortOrder {
    return (localStorage.getItem("user-sortOrder") as UserSortOrder) || "descend";
  }
  const [sortOrder, setSortOrder] = React.useState<UserSortOrder>(getDefaultSortOrder());
  React.useEffect(() => {
    localStorage.setItem("user-sortOrder", sortOrder);
  }, [sortOrder]);

  const [currentPage, setCurrentPage] = React.useState<number>(1);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  React.useEffect(() => {
    nextLinkRef.current = undefined;
    setDebouncedSearch(search);
  }, [search]);

  React.useEffect(() => {
    fetchUsers();
  }, [debouncedSearch]);

  React.useEffect(() => {
    setCurrentPage(1);
    fetchUsers();
  }, [sortColumn, sortOrder]);

  async function fetchUsers() {
    try {
      setIsLoading(true);
      let flush = true;
      let url = "";
      if (nextLinkRef.current) {
        url = nextLinkRef.current;
        flush = false;
      } else if (debouncedSearch) {
        url = `/admin/firebase/user?q=${debouncedSearch}`;
      } else {
        url = `/admin/firebase/user?sort_by=${sortColumn === "lastSignin" ? "lastSignInTime" : sortColumn}&sort_order=${
          sortOrder === "ascend" ? "asc" : "desc"
        }`;
      }
      const res = await request({ url });
      if (flush) {
        setUsers(res.items);
      } else {
        const _users = [...users];
        const currentUserIds = _users.map((user) => user.uid);
        const usersToAdd = res.items.filter((user: any) => !currentUserIds.includes(user.uid));
        setUsers([..._users, ...usersToAdd]);
      }
      let _nextLink = res.links.find((l: any) => l.rel === "next")?.href;
      nextLinkRef.current = _nextLink;
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to fetch users",
        description: errorMessage,
      });
      setIsLoading(false);
    }
  }

  async function onUserAction(user: any, action: "logout" | "enable" | "disable") {
    try {
      setIsLoading(true);
      setActingOn(user.uid);
      setAction(action === "logout" ? "logOut" : "enableDisable");
      let url = "";
      let method: any = "";
      let body: any = undefined;
      if (action === "logout") {
        url = `/admin/firebase/user/${user.uid}/-logout`;
        method = "post";
      } else {
        url = `/admin/firebase/user/${user.uid}`;
        method = "patch";
        body = { disabled: action === "enable" ? false : true };
      }
      await request({
        url,
        method,
        body,
      });
      let description = "";
      if (action === "logout") {
        description = `Logged out user "${user.email}" from all devices`;
      } else if (action === "enable") {
        description = `Enabled user "${user.email}"`;
      } else if (action === "disable") {
        description = `Disabled user "${user.email}"`;
      }
      const newUser = await request({
        url: `/admin/firebase/user/${user.uid}`,
      });
      const _users = [...users];
      const oldUser = _users.find((_user: any) => _user.uid === user.uid);
      const userIndex = _users.indexOf(oldUser);
      if (userIndex >= 0) {
        _users[userIndex] = newUser;
        setUsers(_users);
      }
      notification.success({
        message: "Success",
        description,
      });
      setActingOn("");
      setAction("");
      setIsLoading(false);
    } catch (e) {
      let errorMessage = e?.response?.data?.message;
      if (!errorMessage) errorMessage = e.message;
      notification.warning({
        message: "Failed to process users",
        description: errorMessage,
      });
      setActingOn("");
      setAction("");
      setIsLoading(false);
    }
  }

  function onTableChange(pagination: TablePaginationConfig, _: any, sorter: any) {
    const _sortField: UserSortColumn = sorter.field;
    const _sortBy: undefined | UserSortOrder = sorter.order;

    if (sortColumn === _sortField) {
      if (_sortBy && sortOrder !== _sortBy) {
        nextLinkRef.current = undefined;
        setSortOrder(_sortBy);
      }
    } else {
      nextLinkRef.current = undefined;
      setSortColumn(_sortField);
      setSortOrder(getDefaultSortOrder());
    }

    if (!pagination) {
      return;
    }
    if (!nextLinkRef.current) {
      return;
    }
    setCurrentPage(pagination.current!);
    const totalItemsCurrently = pagination.current! * pagination.pageSize!;
    const totalUsers = users.length;

    if (totalItemsCurrently >= totalUsers) {
      fetchUsers();
    }
  }

  async function exportItem(record: any) {
    const file = new Blob([JSON.stringify(record, null, 2)], {
      type: "text/json",
    });
    const url = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.setAttribute("href", url);
    aElement.setAttribute("download", `${record.name}.json`);
    aElement.click();
  }

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      sortOrder: sortColumn === "email" ? sortOrder : undefined,
      width: 400,
      render: (text: string, record: any) => (
        <div className="flex flex-col">
          {text ? (
            <Link className="text-base text-blue-600 truncate" to={`${record.uid}`}>
              {text}
            </Link>
          ) : (
            <span>No email</span>
          )}
          <div className="flex items-center">
            <span className="text-sm truncate">{record.uid}</span>
            <CopyOutlined
              className="ml-2"
              onClick={() => {
                navigator.clipboard.writeText(record.uid);
                notification.success({ message: "Copied user uid" });
              }}
            />
          </div>
        </div>
      ),
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
      width: 130,
      render: (_: any, record: any) => {
        const providers = record.providerData.map((data: any) => data.providerId);

        return (
          <div className="flex gap-2">
            {providers.some((p: any) => p.includes("google")) ? (
              <img style={{ width: 15, height: 15 }} src={"/resources/logos/login/google.svg"} />
            ) : null}
            {providers.some((p: any) => p.includes("github")) ? (
              <img
                style={{
                  width: 15,
                  height: 15,
                  backgroundColor: "black",
                  padding: 1,
                  borderRadius: 7,
                }}
                src={"/resources/logos/login/github.svg"}
              />
            ) : null}
            {providers.some((p: any) => p.includes("microsoft")) ? (
              <img style={{ width: 15, height: 15 }} src={"/resources/logos/login/microsoft.svg"} />
            ) : null}
            {providers.some((p: any) => p.startsWith("saml.")) ? (
              <img style={{ width: 15, height: 15 }} src={"/resources/logos/login/saml.png"} />
            ) : null}
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: "Last Sign In Time",
      dataIndex: "lastSignin",
      key: "lastSignin",
      sorter: true,
      sortOrder: sortColumn === "lastSignin" ? sortOrder : undefined,
      width: 180,
      render: (_: any, record: any) => {
        if (!record?.metadata?.lastSignInTime) {
          return null;
        }
        return (
          <div className="flex flex-col">
            <span>{formatTimestamp(record.metadata.lastSignInTime)}</span>
          </div>
        );
      },
      ellipsis: true,
    },
    {
      title: "Custom Claims",
      dataIndex: "claims",
      key: "claims",
      render: (_: any, record: any) => {
        if (!record.customClaims) {
          return <span>No custom claims</span>;
        }
        return <pre className="text-xs">{JSON.stringify(record.customClaims, null, 2)}</pre>;
      },
      ellipsis: true,
    },
    {
      title: "Actions",
      dataIndex: "name",
      key: "nameForInspect",
      width: 400,
      render: (_: string, record: any) => (
        <div className="flex items-center gap-4">
          {record.disabled ? (
            <Button
              type={"primary"}
              onClick={() => onUserAction(record, "enable")}
              size={"small"}
              loading={actingOn === record.uid && action === "enableDisable"}
              disabled={isLoading}
            >
              Enable
            </Button>
          ) : (
            <Button
              type={"primary"}
              danger
              onClick={() => onUserAction(record, "disable")}
              size={"small"}
              loading={actingOn === record.uid && action === "enableDisable"}
              disabled={isLoading}
            >
              Disable
            </Button>
          )}
          <Button
            danger
            type={"primary"}
            onClick={() => onUserAction(record, "logout")}
            size={"small"}
            loading={actingOn === record.uid && action === "logOut"}
            disabled={isLoading}
          >
            Log Out
          </Button>
          <Button size={"small"} type={"default"} onClick={() => setViewingItem(record)}>
            View
          </Button>
          <Button size={"small"} type={"link"} onClick={() => exportItem(record)}>
            Export
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex items-center gap-4">
        <PageHeader title={"Users"} backIcon={false} />
        <div style={{ width: 400 }} className="flex items-center gap-2">
          <label>Search</label>
          <Input value={search} onChange={(e) => setSearch(e.target.value)} />
          {search.length > 0 && debouncedSearch.length > 0 ? (
            <CloseCircleOutlined onClick={() => setSearch("")} className="cursor-pointer" />
          ) : null}
        </div>
      </div>
      <div className="px-4 pb-4">
        <Table
          loading={isLoading}
          bordered
          size={"small"}
          dataSource={users}
          columns={columns as any}
          sortDirections={["ascend", "descend", "ascend"]}
          onChange={onTableChange}
          pagination={{ current: currentPage, showSizeChanger: false }}
          rowKey={"email"}
        />
      </div>
      {viewingItem ? (
        <JSONModal
          filename={viewingItem.email}
          onClose={() => setViewingItem(null)}
          title={`User: ${viewingItem.email}`}
          visible={!!viewingItem}
          object={viewingItem}
        />
      ) : null}
    </>
  );
};

export const UserList = observer(UserListRaw);
