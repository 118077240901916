import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, InputNumber, notification } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { ITrialDomain } from "../../utilities/types";
import { request } from "../../../../utils";
import { useNavigate } from "react-router-dom";

interface IProps {
  trialDomain: ITrialDomain;
  updateTiralDomainRemainingAccounts: (value: number) => void;
}

const TrialDomainQuotaRaw: React.FC<IProps> = ({
  trialDomain,
  updateTiralDomainRemainingAccounts,
}) => {
  // Uses //
  const navigate = useNavigate();
  const [difference, setDifference] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // Use Effects //
  React.useEffect(() => {
    if (trialDomain.accounts.length === 0) {
      notification.error({
        message: "Trial domain accounts are empty",
        description: "Cannot reload trial domain with no accounts.",
      });
      navigate(`/admin/trial-domains/${trialDomain.domainName}`);
    }
  }, [trialDomain.accounts]);

  // Functions //
  function onInputChange(value: number | null): void {
    if (value === null) {
      return;
    }
    setDifference(value - trialDomain.accountsRemaining);
  }

  function reset() {
    setDifference(0);
  }

  async function onSave() {
    try {
      setIsLoading(true);
      await request({
        service: "billing-ng",
        url: `/trial_accounts/${trialDomain.accounts[0].id}/reload`,
        method: "post",
        body: {
          bulletCount: difference,
        },
      });

      notification.success({
        message: "Updated Trial Domain Quota",
      });
      updateTiralDomainRemainingAccounts(difference);
      setDifference(0);
    } catch (e) {
      // @ts-ignore
      let errorMessage = e?.response?.data?.message;
      // @ts-ignore
      if (!errorMessage) errorMessage = e.message;

      notification.warning({
        message: "Failed to update Trial Domain",
        description: errorMessage,
      });
    }
    setIsLoading(false);
  }

  return (
    <div>
      <PageHeader
        className="site-page-header"
        title={trialDomain.domainName}
        subTitle={"Trial Domain Detail - Quota"}
      />
      <div className="px-6">
        <div className="mb-4">
          <div className="font-bold mb-2">Accounts Remaining</div>
          <InputNumber
            value={trialDomain.accountsRemaining + difference}
            min={0}
            onChange={onInputChange}
          />
        </div>
        <div className="flex items-center gap-4">
          <Button
            disabled={difference == 0 || isLoading}
            type={"primary"}
            danger
            onClick={reset}
          >
            Reset
          </Button>
          <Button
            disabled={difference == 0 || isLoading}
            loading={isLoading}
            type={"primary"}
            onClick={onSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export const TrialDomainQuota = observer(TrialDomainQuotaRaw);
